import ErrorIcon from "@mui/icons-material/Error";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { siteNameAndYear } from "../../Helpers/Common/CommonComponent";
import ConfirmDialog from "../../Helpers/ConfirmDialog/ConfirmDialog";
import {
  acceptCWIQuoteAmountCrud,
  createCommentsByInvitedIds,
  declineTenderQuote,
  getCommentsByCarrierInvitedId,
  getInviteQuoteFromInviteCode,
  updateCarrierTenderStatusCrud
} from "../../_redux/TransportOrders/TransportOrdersCrud";
import { generalSlice } from "../../_redux/general/generalSlice";
import ChatCommentModal from "../TransportOrders/TransportOrdersTable/TenderIDModal/ChatCommentModal/ChatCommentModal";
import DriverLicenseModal from "./DriverLicenseModal/DriverLicenseModal";
import LocationDetailsForm from "./LocationDetailsForm/LocationDetailsForm";
import RejectCWIQuoteAmountModal from "./RejectCWIQuoteAmountModal/RejectCWIQuoteAmountModal";
import TenderQuoteModal from "./TenderQuoteModal/TenderQuoteModal";
import UpdateTenderStatusModal from "./UpdateTenderStatusModal/UpdateTenderStatusModal";
import CustomTooltipButton from "../../Helpers/CustomTooltipButton/CustomTooltipButton";
import CommentIcon from "@mui/icons-material/Comment";


export const status = {
  PENDING: "Pending Invite",
  PENDING_QUOTE: "Pending Quote",
  PENDING_AWARD: "Pending Award",
  PENDING_APPROVAL: "Pending Approval",
  CONFIRMED: "Confirmed",
  IN_PROCESS: "Order In Process",
  COMPLETED: "Order Completed",
  CANCELLED: "Order Cancelled",
};

export const fromTransportOrderStatus = {
  CONFIRMED: "Quote Confirmed",
  IN_PROCESS: "In Process",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
};

export const fromQuoteStatus = {
  CARRIER_ACCEPTED: "Quote Submitted",
  PENDING_APPROVAL: "Pending Approval",
  INVITED: "Invited",
};

export const tenderStatusAndAmountShow = (
  quoteAmount,
  cwiQuoteAmount,
  setShowAcceptModal,
  setShowRejectModal,
  quoteStatusKeyToValue,
  TransportOrderStatusKeyToValue,
  quoteStatus
) => {
  return (
    <Box
      sx={{
        padding: "10px",
        background: "#e5f6fd",
        textAlign: "center",
        fontSize: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      <strong>Status:</strong>{" "}
      {quoteStatus === "INVITED" ||
        quoteStatus === "PENDING_APPROVAL" ||
        quoteStatus === "CARRIER_ACCEPTED"
        ? quoteStatusKeyToValue(fromQuoteStatus)
        : TransportOrderStatusKeyToValue(fromTransportOrderStatus)}
      ,<strong>Amount:</strong> ${quoteAmount}
      {cwiQuoteAmount > 0 && (
        <>
          ,
          <span style={{ fontWeight: "600", color: "#1773d2" }}>
            CWI Suggested Amount:
          </span>
          <span style={{ marginRight: "10px", color: "#1773d2" }}>
            ${cwiQuoteAmount}
          </span>
          <Tooltip
            disableInteractive={true}
            arrow
            title="Accept CWI Quote Amount"
          >
            <IconButton
              onClick={() => {
                setShowAcceptModal(true);
              }}
              sx={{
                marginRight: "5px",
                padding: "5px",
                borderRadius: "5px",
                backgroundColor: "#19b6ae",
                "&:hover": {
                  backgroundColor: "#19b6ae",
                },
                "& g": {
                  fill: "#fff",
                },
              }}
            >
              <ThumbUpAltIcon
                sx={{
                  width: "1.6rem",
                  height: "1.6rem",
                  color: "#fff",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            disableInteractive={true}
            arrow
            title="Decline CWI Quote Amount"
          >
            <IconButton
              onClick={() => {
                setShowRejectModal(true);
              }}
              sx={{
                padding: "5px",
                borderRadius: "5px",
                backgroundColor: "#f64e60",
                "&:hover": {
                  backgroundColor: "#f64e60",
                },
                "& g": {
                  fill: "#fff",
                },
              }}
            >
              <ThumbDownAltIcon
                sx={{
                  width: "1.6rem",
                  height: "1.6rem",
                  color: "#fff",
                }}
              />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

const TenderDetailLink = ({ inviteCode, isInProcess }) => {
  const dispatch = useDispatch();
  const { actions: generalActions } = generalSlice;

  const [stopData, setStopData] = useState([]);
  const [transportOrderData, setTransportOrderData] = useState([]);
  const [carrierData, setCarrierData] = useState({});
  const [quoteStatus, setQuoteStatus] = useState("");
  const [quoteAmount, setQuoteAmount] = useState();
  const [endSolicitationTime, setEndSolicitationTime] = useState("");
  const [cwiQuoteAmount, setcwiQuoteAmount] = useState();
  const [errMessage, setErrMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeclineLoading, setIsDeclineLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [transportOrderStatus, setTransportOrderStatus] = useState("");
  const [showDriverLicenseModal, setShowDriverLicenseModal] = useState(false);
  const [trackRequirements, setTrackRequirements] = useState("");
  const [
    showUpdateTenderStatusModal,
    setShowUpdateTenderStatusModal,
  ] = useState(false);
  const [transportOrderLinkData, setTransportOrderLinkData] = useState([]);
  const [modalData, setModalData] = useState();

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [isAcceptSubmitting, setIsAcceptSubmitting] = useState(false);

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [stopDataDeliverdDate, setStopDataDeliverdDate] = useState("");

  const [isResponded, setIsResponded] = useState(false);
  const [checkCarrierId, setCheckCarrierId] = useState(null);
  const [isDisableDrag, setIsDisableDrag] = useState(false);

  const handleAcceptModalClose = () => {
    setShowAcceptModal(false);
  };

  const getData = () => {
    setIsLoading(true);
    getInviteQuoteFromInviteCode(inviteCode)
      .then((res) => {
        setTrackRequirements(
          res?.data?.getInviteQuoteFromInviteCode?.minTruckRequirements
        );
        setIsResponded(
          res?.data?.getInviteQuoteFromInviteCode?.isAlreadyResponded
        );
        setCarrierData(res?.data?.getInviteQuoteFromInviteCode?.carrier);
        setQuoteStatus(res?.data?.getInviteQuoteFromInviteCode?.quoteStatus);
        setQuoteAmount(res?.data?.getInviteQuoteFromInviteCode?.quoteAmount);
        setEndSolicitationTime(
          res?.data?.getInviteQuoteFromInviteCode?.endSolicitationTime
        );
        setcwiQuoteAmount(
          res?.data?.getInviteQuoteFromInviteCode?.cwiQuoteAmount
        );
        setTransportOrderLinkData(
          res?.data?.getInviteQuoteFromInviteCode?.transportOrder || []
        );
        const tempData =
          res?.data?.getInviteQuoteFromInviteCode?.transportOrderStops;
        const orderDelivedDate =
          res?.data?.getInviteQuoteFromInviteCode?.transportOrderStops?.[0]
            ?.shipmentDate;
        setStopDataDeliverdDate(orderDelivedDate);

        setStopData(tempData);
        let transportOrderArr = [];
        setTransportOrderStatus(
          res?.data?.getInviteQuoteFromInviteCode?.transportOrder
            ?.transportOrderStatus
        );
        transportOrderArr.push(
          res?.data?.getInviteQuoteFromInviteCode?.transportOrder
        );
        setCheckCarrierId(
          res?.data?.getInviteQuoteFromInviteCode?.transportOrder?.Carrier
        );
        setTransportOrderData(transportOrderArr);
        setModalData(
          res?.data?.getInviteQuoteFromInviteCode?.transportOrder?.tenderID
        );

        if (stopData?.length === 0) {
          setErrMessage("Invite Code is not Found");
        }
      })
      .catch((error) => {
        console.error(error);
        setErrMessage("Invite Code is not valid");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const declineData = () => {
    setIsDeclineLoading(true);
    setIsSubmitting(true);
    declineTenderQuote(inviteCode)
      .then((res) => {
        if (res?.data?.declineTenderQuote?.status === false) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't Decline Tender Quote",
              type: "danger",
            })
          );
        } else {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Success",
              message: "Tender Quote Declined Successfully",
              type: "success",
            })
          );

          getData();
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
        if (error) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't Decline Tender Quote",
              type: "danger",
            })
          );
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsDeclineLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCode]);
  useEffect(() => {
    if (isInProcess && (transportOrderLinkData?.transportOrderStatus === "CONFIRMED" || transportOrderLinkData?.transportOrderStatus === "COMPLETED")) {
      const data = {
        comment: "",
        inviteCode: inviteCode,
        status: "IN_PROCESS"
      };

      transportOrderLinkData?.transportOrderStatus === "CONFIRMED" ?
        updateCarrierTenderStatusCrud(data, null)
          .then((res) => {
            dispatch(
              generalActions.pushNewAlert({
                show: true,
                heading: "Success",
                message: "Order Status Updated Successfully",
                type: "success",
              })
            );
          }).catch((error) => {
            console.error(error);
            if (error) {
              setShowUpdateTenderStatusModal(false);
              dispatch(
                generalActions.pushNewAlert({
                  show: true,
                  heading: "Error",
                  message: error.errors[0]?.message || "Can't Update Order Status",
                  type: "danger",
                })
              );
            }
          })
        :
        dispatch(
          generalActions.pushNewAlert({
            show: true,
            heading: "Error",
            message: "You Have Alredy Responded",
            type: "danger",
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportOrderLinkData]);

  const AcceptCWIQuoteAmountData = () => {
    setIsAcceptSubmitting(true);
    acceptCWIQuoteAmountCrud(inviteCode)
      .then((res) => {
        if (res?.data?.acceptCWIQuoteAmount?.status === false) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't Accept CWI Quote Amount",
              type: "danger",
            })
          );
        } else {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Success",
              message: "CWI Quote Amount Accepted Successfully",
              type: "success",
            })
          );
          getData();
        }
      })
      .catch((error) => {
        console.error(error);
        setIsAcceptSubmitting(false);
        if (error) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't Accept CWI Quote Amount",
              type: "danger",
            })
          );
        }
      })
      .finally(() => {
        setIsAcceptSubmitting(false);
        setShowAcceptModal(false);
      });
  };


  let arr = [];

  Object.entries(status).forEach(([k, v]) => {
    arr.push({ key: k, value: v });
  });

  const statusKeyToValueFunc = (data) => {
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element?.key === data) {
        let string = element.value;
        return string;
      }
    }
  };

  useEffect(() => {
    isInProcess &&
      isResponded &&
      transportOrderStatus === "IN_PROCESS" &&
      dispatch(
        generalActions.pushNewAlert({
          show: true,
          heading: null,
          message: "You Have Alredy Responded",
          type: "danger",
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResponded]);

  useEffect(() => {
    if (transportOrderStatus !== "") {
      isInProcess &&
        isResponded === false &&
        transportOrderStatus === "IN_PROCESS" &&
        dispatch(
          generalActions.pushNewAlert({
            show: true,
            heading: null,
            message: `Invalid Operation TransportOrder Status Is ${statusKeyToValueFunc(
              transportOrderStatus
            )}`,
            type: "danger",
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportOrderStatus, isInProcess]);

  let arrQuoteStatus = [];
  let arrTransportOrderStatus = [];

  Object.entries(fromQuoteStatus).forEach(([k, v]) => {
    arrQuoteStatus.push({ key: k, value: v });
  });
  Object.entries(fromTransportOrderStatus).forEach(([k, v]) => {
    arrTransportOrderStatus.push({ key: k, value: v });
  });

  const quoteStatusKeyToValue = (data) => {
    for (let index = 0; index < arrQuoteStatus.length; index++) {
      const element = arrQuoteStatus[index];
      if (element?.value === data[`${quoteStatus}`]) {
        let string = element.value;
        return string;
      }
    }
  };

  const TransportOrderStatusKeyToValue = (data) => {
    for (let index = 0; index < arrTransportOrderStatus.length; index++) {
      const element = arrTransportOrderStatus[index];
      if (element?.value === data[`${transportOrderStatus}`]) {
        let string = element.value;
        return string;
      }
    }
  };

  const showDays = (date, pickupDate) => {
    let data = moment(date).diff(moment(pickupDate), "days");
    return data;
  };

  const [chatCommentModal, setChatCommentModal] = useState(false);
  const [chatSubmiting, setChatisSubmitting] = useState(false);
  const [allChatComment, setAllChatComment] = useState([]);

  const getAllCommentsByInviteID = (row) => {
    getCommentsByCarrierInvitedId(inviteCode).then((result) => {
      setAllChatComment(result?.data.getTenderInviteComments);
      setChatCommentModal(true);
      setIsDisableDrag(true);
    }).catch(e => console.error(e));
  };

  const sendChatComments = (e) => {
    setChatisSubmitting(true);
    createCommentsByInvitedIds(inviteCode, e).then((data) => {
      setChatisSubmitting(false);
      setChatCommentModal(false);
      setIsDisableDrag(false);
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {quoteStatus === "CARRIER_REJECTED" ? (
          <div className="h-100 d-flex flex-column flex-root">
            <div
              className="d-flex flex-row-fluid flex-row bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/error/bg1.jpg"
                )})`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <ErrorIcon fontSize="large" style={{ color: "#F64E60" }} />
                <h2
                  className="font-size-h3 text-danger text-uppercase"
                  style={{ margin: "2px 0px 0px 2px" }}
                >
                  You've declined the tender
                </h2>
              </Box>
            </div>
          </div>
        ) : quoteStatus === "TENDER_CLOSE" ? (
          <div className="h-100 d-flex flex-column flex-root">
            <div
              className="d-flex flex-row-fluid flex-row bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/error/bg1.jpg"
                )})`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <ErrorIcon fontSize="large" style={{ color: "#F64E60" }} />
                  <h2
                    className="font-size-h3 text-danger"
                    style={{ margin: "2px 0px 0px 2px" }}
                  >
                    Transport Order is Closed and No Longer Accepting
                    Solicitations
                  </h2>
                </Box>
              </Box>
            </div>
          </div>
        ) : quoteStatus === "REJECTED" ? (
          <div className="h-100 d-flex flex-column flex-root">
            <div
              className="d-flex flex-row-fluid flex-row bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/error/bg1.jpg"
                )})`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <ErrorIcon fontSize="large" style={{ color: "#F64E60" }} />
                  <h2
                    className="font-size-h3 text-danger"
                    style={{ margin: "2px 0px 0px 2px" }}
                  >
                    Coinwrap personnel Rejected your Quote Application.
                  </h2>
                </Box>
              </Box>
            </div>
          </div>
        ) : quoteStatus === "PICKUP_REJECTED" ? (
          <div className="h-100 d-flex flex-column flex-root">
            <div
              className="d-flex flex-row-fluid flex-row bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/error/bg1.jpg"
                )})`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <ErrorIcon fontSize="large" style={{ color: "#F64E60" }} />
                  <h2
                    className="font-size-h3 text-danger"
                    style={{ margin: "2px 0px 0px 2px" }}
                  >
                          Coinwrap personnel could not receive approval for your
                    Quote Application.
                  </h2>
                </Box>
              </Box>
            </div>
          </div>
        ) : stopData?.length === 0 ? (
          isLoading ? (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
                alignItems: "center",
                height: "100vh",
                width: "100%",
              }}
            >
              <Spinner animation="border" />
            </span>
          ) : (
            <div className="h-100 d-flex flex-column flex-root">
              <div
                className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/error/bg1.jpg"
                  )})`,
                }}
              >
                <h1
                  className="font-size-sm-100 font-weight-boldest text-danger mt-3"
                  style={{ fontSize: "150px" }}
                >
                  404
                </h1>
                <h3 className="font-size-h3 text-danger text-uppercase ml-7">
                  {errMessage}
                </h3>
              </div>
            </div>
          )
        ) : (
          <>
            <Box
              sx={{
                color: "#212121",
                background: "#c58080",
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                top: 0,
                position: "sticky",
                width: "100vw",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.8rem",
                  fontWeight: 600,
                }}
              >
                {/* <img
                  alt="logo"
                  src={img}
                  style={{
                    width: "120px",
                    margin: "auto",
                    background: "#fff",
                    padding: "10px",
                    border: "1px solid #c58080",
                    borderRadius: "10px",
                  }}
                /> */}
                <Box sx={{ marginLeft: "20px" }}>
                  WatchDog Logistics - Request for Transportation Quote
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ fontSize: "1.6rem", fontWeight: 600 }}>
                  Invited to: {carrierData?.carrierName}
                </Box>
                <Box sx={{ fontSize: "1.2rem", fontWeight: 400 }}>
                  {carrierData?.pocEmail}
                </Box>
              </Box>
            </Box>
            <Box>
              {(quoteStatus === "CARRIER_ACCEPTED" ||
                quoteStatus === "PENDING_APPROVAL" ||
                quoteStatus === "INVITED") && (
                  <>
                    {tenderStatusAndAmountShow(
                      quoteAmount,
                      cwiQuoteAmount,
                      setShowAcceptModal,
                      setShowRejectModal,
                      quoteStatusKeyToValue,
                      TransportOrderStatusKeyToValue,
                      quoteStatus
                    )}
                  </>
                )}
            </Box>
            <Box>
              {(transportOrderStatus === "IN_PROCESS" ||
                transportOrderStatus === "COMPLETED" ||
                transportOrderStatus === "CANCELLED" ||
                transportOrderStatus === "CONFIRMED") &&
                checkCarrierId !== null && (
                  <>
                    {tenderStatusAndAmountShow(
                      quoteAmount,
                      cwiQuoteAmount,
                      setShowAcceptModal,
                      setShowRejectModal,
                      quoteStatusKeyToValue,
                      TransportOrderStatusKeyToValue,
                      quoteStatus
                    )}
                  </>
                )}
            </Box>
            <Box
              sx={{
                maxHeight: "85vh",
                overflow: "auto",

                "@media (max-height: 948px) ": {
                  maxHeight: "85vh",
                },
                "@media (max-height: 900px) ": {
                  maxHeight: "82vh",
                },
                "@media (max-height: 820px)": {
                  maxHeight: "77vh",
                },
                "@media (max-height: 720px)": {
                  maxHeight: "75vh",
                },
                "@media (max-height: 640px)": {
                  maxHeight: "71vh",
                },
                "@media (max-height: 560px)": {
                  maxHeight: "67vh",
                },
              }}
            >
              <Box sx={{ padding: "20px 20px 0px 20px" }}>
                <TableContainer component={Paper} sx={{ marginBottom: "25px" }}>
                  <Table size="small" stickyHeader={true}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Customer Tender ID
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Order ID
                        </TableCell> */}
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Total Order Stops
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Total Order Dimension
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Total Order STC
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Total Order Weight
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        overflow: "auto",
                      }}
                    >
                      {transportOrderData.length > 0 &&
                        transportOrderData?.map((data, i) => (
                          <TableRow key={i}>
                            <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                              {data?.tenderID || "-"}
                            </TableCell>
                            {/* <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                              {data?.transportOrderID.slice(10) || "-"}
                            </TableCell> */}
                            <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                              {data?.totalOrderStops || "-"}
                            </TableCell>
                            <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                              <pre
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  maxHeight: "50px",
                                  maxWidth: "180px",
                                  fontSize: "12px",
                                  color: "rgba(0, 0, 0, 0.87)",
                                  margin: "0",
                                }}
                              >
                                {data?.totalOrderDimension || "-"}
                              </pre>
                            </TableCell>

                            <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                              {`$${new Intl.NumberFormat("en-US").format(
                                data?.totalOrderSTC
                              )}` || "-"}
                            </TableCell>

                            <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                              {new Intl.NumberFormat("en-US").format(
                                data?.totalOrderWeight
                              ) || "-"}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box sx={{ padding: "0px 20px 10px 20px" }}>
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "525px" }}
                >
                  <Table size="small" stickyHeader={true}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                            minWidth: "120px",
                          }}
                        >
                          Order No.
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                            minWidth: "120px",
                          }}
                        >
                          Stop Type
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                            minWidth: "120px",
                          }}
                        >
                          Location
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                            minWidth: "120px",
                          }}
                        >
                          Estimated Delivery Date
                        </TableCell>
                        {stopData[0]?.TransportOrderProducts?.length > 0 &&
                          stopData[0]?.TransportOrderProducts?.map((data, i) => (
                            <TableCell
                              key={i}
                              sx={{
                                fontSize: "1.2rem",
                                paddingBottom: "10px",
                                textTransform: "capitalize",
                              }}
                            >
                              {`product ${i + 1}`}
                            </TableCell>
                          ))
                        }
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                            minWidth: "120px",
                          }}
                        >
                          Stop Notes
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        overflow: "auto",
                      }}
                    >
                      {stopData?.map((data, i) => (
                        <TableRow key={data?.ID}>
                          <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                            {data?.orderNumber || "-"}
                          </TableCell>
                          <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                            {data?.stopType || "-"}
                          </TableCell>
                          <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                            <>
                              <div> {data?.LocationTransport?.Location?.locationName || "-"}</div>
                              <div>{`${data?.LocationTransport?.Location?.address || ""} ${data
                                ?.Location?.LocationTransport?.address2 || ""}`}</div>
                              <div>{`${data?.LocationTransport?.Location?.city || ""} ${data
                                ?.LocationTransport?.Location?.state || ""} ${data
                                  ?.LocationTransport?.Location?.zipCode || ""}`}</div>
                            </>
                          </TableCell>

                          <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                            <>
                              <div>
                                {moment(new Date(data?.shipmentDate)).format(
                                  "dddd"
                                )}{" "}
                              </div>
                              <div>
                                {data?.shipmentDate || "-"}{" "}
                                {data?.shipmentTime || "-"}
                                {i !== 0 && showDays(data?.shipmentDate, stopData[0]?.shipmentDate) !== 0 &&
                                  <strong>&nbsp; {showDays(data?.shipmentDate, stopData[0]?.shipmentDate) <= 1 ? `(+${showDays(data?.shipmentDate, stopData[0]?.shipmentDate)} day)` :
                                    `(+${showDays(data?.shipmentDate, stopData[0]?.shipmentDate)} days)`}</strong>
                                }
                              </div>
                            </>
                          </TableCell>
                          {data?.TransportOrderProducts.length > 0 &&
                            stopData?.[0]?.TransportOrderProducts?.map((prd, index) => {
                              const product = data?.TransportOrderProducts.find((d) => d?.productID === prd?.productID);
                              if (product) {
                                return (
                                  <TableCell
                                    key={i + index}
                                    sx={{
                                      color: "#000",
                                      fontSize: "1rem",
                                      minWidth: "120px",
                                    }}
                                  >
                                    <>
                                      {/* <div>{product?.productId || "-"}</div> */}
                                      <div>
                                        {Number(product?.productUnit) === 0 ||
                                          product?.productUnit === "" || !product?.productID
                                          ? "-"
                                          : `${product?.productID} | ${product?.Product?.name}` || "-"}
                                      </div>
                                      <div>
                                        {Number(product?.productUnit) === 0
                                          ? "-"
                                          : product?.productUnit || "-"}
                                      </div>
                                    </>
                                  </TableCell>
                                );
                              } else {
                                return <TableCell
                                  key={i + index}
                                  sx={{
                                    color: "#000",
                                    fontSize: "1rem",
                                    minWidth: "120px",
                                  }}
                                >
                                  -
                                </TableCell>;
                              }
                            })
                          }
                          <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                            {data?.LocationTransport?.transportComments || "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {
                transportOrderStatus !== "CONFIRMED" &&
                transportOrderStatus !== "IN_PROCESS" &&
                transportOrderStatus !== "COMPLETED" &&
                transportOrderStatus !== "CANCELLED" &&
                quoteStatus !== "CARRIER_ACCEPTED" &&
                quoteStatus !== "PENDING_APPROVAL" &&
                moment().format() >=
                moment(new Date(endSolicitationTime)).format() &&
                !cwiQuoteAmount && (<div className="bg-danger m-5 p-2"
                  style={{
                    color: '#fff',
                    textAlign: 'center',
                    borderRadius: 5, fontSize: 16, fontWeight: '600'
                  }}
                >
                  Solicitation time is exceeded
                </div>)}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  marginRight: "20px",
                }}
              >
                {transportOrderStatus !== "CONFIRMED" &&
                  transportOrderStatus !== "IN_PROCESS" &&
                  transportOrderStatus !== "COMPLETED" &&
                  transportOrderStatus !== "CANCELLED" &&
                  quoteStatus !== "CARRIER_ACCEPTED" &&
                  quoteStatus !== "PENDING_APPROVAL" &&
                  moment().isSameOrBefore(
                    moment(new Date(endSolicitationTime)).format()
                  ) &&
                  moment().isSameOrBefore(
                    moment(new Date(stopDataDeliverdDate))
                      .endOf("day")
                      .format()
                  ) &&
                  !cwiQuoteAmount && (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{
                          padding: "10px 20px",
                          borderRadius: "5px",
                          fontSize: "15px",
                          textTransform: "inherit",
                        }}
                        // disabled={quoteAmount && true}
                        onClick={() => setShowModal(true)}
                      >
                        Click to Quote
                      </Button>

                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          padding: "10px 20px",
                          borderRadius: "5px",
                          fontSize: "15px",
                          textTransform: "inherit",
                        }}
                        disabled={isDeclineLoading}
                        onClick={() => declineData()}
                      >
                        {isSubmitting && (
                          <CircularProgress
                            size={20}
                            thickness={3.3}
                            color="inherit"
                            className="mr-1"
                          />
                        )}

                        <span>{isSubmitting ? "Declining" : "Decline"}</span>
                      </Button>
                    </>
                  )}
                {quoteStatus !== "AWARDED" &&
                  quoteStatus === "PENDING_DRIVER_CERTIFICATE" &&
                            transportOrderStatus === "CONFIRMED" && (
                    <Button
                      variant="contained"
                      color="info"
                      sx={{
                        padding: "10px 20px",
                        borderRadius: "5px",
                        fontSize: "15px",
                        textTransform: "inherit",
                      }}
                      onClick={() => setShowDriverLicenseModal(true)}
                    >
                      Upload Driver Certificate
                    </Button>
                  )}

                {quoteStatus === "AWARDED" &&
                  transportOrderStatus !== "COMPLETED" &&
                  transportOrderStatus !== "CANCELLED" &&
                  !isInProcess && (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        padding: "10px 20px",
                        borderRadius: "5px",
                        fontSize: "15px",
                        textTransform: "inherit",
                      }}
                      onClick={() => { setShowUpdateTenderStatusModal(true); setIsDisableDrag(true); }}
                    >
                      Update Tender Status
                    </Button>
                  )}
              </Box>
              <Box sx={{ padding: "10px 20px 20px 20px" }}>
                <Box component={Paper}>
                  <LocationDetailsForm
                    stopData={stopData}
                    trackRequirements={trackRequirements}
                  />
                </Box>
              </Box>
            </Box>
            {showModal && (
              <TenderQuoteModal
                setShowModal={setShowModal}
                showModal={showModal}
                inviteCode={inviteCode}
                getData={getData}
              />
            )}
            {showDriverLicenseModal && (
              <DriverLicenseModal
                setShowDriverLicenseModal={setShowDriverLicenseModal}
                showDriverLicenseModal={showDriverLicenseModal}
                stopData={stopData}
                isLoading={isLoading}
                inviteCode={inviteCode}
                getData={getData}
              />
            )}
            {showUpdateTenderStatusModal && (
              <UpdateTenderStatusModal
                setShowUpdateTenderStatusModal={setShowUpdateTenderStatusModal}
                showUpdateTenderStatusModal={showUpdateTenderStatusModal}
                transportOrderLinkData={transportOrderLinkData}
                transportOrderStatus={transportOrderStatus}
                inviteCode={inviteCode}
                getData={getData}
                setStopData={setStopData}
                stopData={stopData}
                modalData={modalData}
                carrierDataName={carrierData?.carrierName}
                isDisableDrag={isDisableDrag}
                setIsDisableDrag={setIsDisableDrag}
              />
            )}
            <div
              style={{
                position: "absolute",
                bottom: "5.6%",
                right: 35
              }}
            >
              <CustomTooltipButton
                toolTipTitle="Comments"
                handleClick={() => {
                  getAllCommentsByInviteID();
                }}
                icon={
                  <CommentIcon sx={{ width: "2.5rem", height: "2.5rem", color: "#fff" }} />
                }
                backgroundColor={"#3699FF"}
                disabledColor={"#3699FF"}
                iconButtonStyle={{ borderRadius: "10px" }}
              />
            </div>
          </>
        )}
      </div>

      {(quoteStatus !== "CARRIER_REJECTED" ||
        quoteStatus !== "TENDER_CLOSE" ||
        quoteStatus !== "REJECTED" ||
        quoteStatus !== "PICKUP_REJECTED") &&
        stopData?.length !== 0 && (
          <Box
            sx={{
              backgroundColor: "#f4f2f6",
              borderTop: "1px solid #e0e0e0",
              color: "#212121",
              bottom: 0,
              padding: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "fixed",
              width: "100%",
            }}
          >
            <a
              href="http://www.coinwrap.com/"
              target="blank"
              rel="noopener noreferrer"
              className="text-muted  mr-2"
            >
              &copy; {siteNameAndYear}
            </a>
          </Box>
        )}

      {showAcceptModal && (
        <ConfirmDialog
          show={showAcceptModal}
          cancelHandler={handleAcceptModalClose}
          actionHandler={AcceptCWIQuoteAmountData}
          title="Accept CWI Quote Amount"
          buttonLabel="Accept"
          buttonLabelWhenSubmitting="Accepting"
          customMessage={
            <div>
              Are you sure you want to <strong>accept</strong> this CWI quote
              amount?
            </div>
          }
          btnVariant="success"
          headerColor="#19b6ae"
          setIsSubmitting={setIsAcceptSubmitting}
          isSubmitting={isAcceptSubmitting}
        />
      )}

      {showRejectModal && (
        <RejectCWIQuoteAmountModal
          setShowRejectModal={setShowRejectModal}
          showRejectModal={showRejectModal}
          getData={getData}
          inviteCode={inviteCode}
        />
      )}

      {chatCommentModal && (
        <ChatCommentModal
          chatCommentModal={chatCommentModal}
          setChatCommentModal={setChatCommentModal}
          setIsSubmitting={setChatisSubmitting}
          isSubmitting={chatSubmiting}
          allChatComment={allChatComment}
          setAllChatComment={setAllChatComment}
          sendChatComments={sendChatComments}
          carrierUserDeatil={carrierData}
          isDisableDrag={isDisableDrag}
          setIsDisableDrag={setIsDisableDrag}
        />
      )}
    </>
  );
};

export default TenderDetailLink;
