/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";

import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import "socicon/css/socicon.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import App from "./app/App";
import "./index.scss";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "react-datepicker/dist/react-datepicker.css";
import { MetronicI18nProvider } from "./_metronic/i18n";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from "./_metronic/layout";
import { NAME } from "./app/aws-exports";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

const cache = createCache({
  key: "css",
  prepend: true,
});

// Init Sentry
if (NAME !== "dev" && !window.location.host.startsWith("localhost")) {
  Sentry.init({
    dsn:
      "https://581bf3e50b214f91b2e3e4e8350a99c1@o223672.ingest.sentry.io/4504767779831808",
    integrations: [new BrowserTracing()],
    environment: NAME, // we need to pass dev, qa or prod here
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
} 

createRoot(document.getElementById("root")).render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <CacheProvider value={cache}>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App store={store} persistor={persistor} basename={PUBLIC_URL} />
            </LocalizationProvider>
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </CacheProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>
);
