import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCarriers: [],
  selectedCarriers: null,
  isLoading: true,
  searchValue: "",
  searchBy: [],
  page: 0,
  dataCount: 0,
  offset: 0,
  dataPerPage: 15,
  sortField: "carrierName",
  sortType: "ASC",
  cwiLocationsList: [],
  mintLocationsList: [],
  productsList: [],
  actionTrigger: false
};

export const CarrierSlice = createSlice({
  name: "carriers",
  initialState: initialState,
  reducers: {
    setCarriers: (state, action) => {
      state.allCarriers = action.payload;
    },
    carrierFetched: (state, action) => {
      state.selectedCarriers = action.payload;
    },
    removeSelectedCarrier: (state) => {
      state.selectedCarriers = null;
    },
    setCWILocationsList: (state, action) => {
      state.cwiLocationsList = action.payload;
    },
    setMintLocationsList: (state, action) => {
      state.mintLocationsList = action.payload;
    },
    setProductsList: (state, action) => {
      state.productsList = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = { ...state.searchValue, ...action.payload };
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSortType: (state, action) => {
      state.sortType = action.payload;
    },
    setActionTrigger: (state, action) => {
      state.actionTrigger = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        case "SET_OFFSET":
          state.offset = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
