import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import * as auth from "../app/modules/Auth/_redux/authRedux";

import { AuditLogsSlice } from "../app/modules/coinWrap/_redux/AuditLogs/AuditLogsSlice";
import { DocumentsSlice } from "../app/modules/coinWrap/_redux/Documents/DocumentsSlice";
import { AccountsSlice } from "../app/modules/coinWrap/_redux/Models/Account/AccountsSlice";
import { CarrierSlice } from "../app/modules/coinWrap/_redux/Models/Carrier/CarrierSlice";
import { CoinTypeProductsSlice } from "../app/modules/coinWrap/_redux/Models/CoinTypeProducts/CoinTypeProductsSlice";
import { CoinTypesSlice } from "../app/modules/coinWrap/_redux/Models/CoinTypes/CoinTypesSlice";
import { DivisionsSlice } from "../app/modules/coinWrap/_redux/Models/Divisions/DivisionsSlice";
import { EmployeeLocationSlice } from "../app/modules/coinWrap/_redux/Models/EmployeeLocation/EmployeeLocationSlice";
import { EmployeesSlice } from "../app/modules/coinWrap/_redux/Models/Employees/EmployeesSlice";
import { InventorySlice } from "../app/modules/coinWrap/_redux/Models/Inventory/InventorySlice";
import { LocationsSlice } from "../app/modules/coinWrap/_redux/Models/Locations/LocationsSlice";
import { OrganizationsSlice } from "../app/modules/coinWrap/_redux/Models/Organization/OrganizationsSlice";
import { ProductsSlice } from "../app/modules/coinWrap/_redux/Models/Products/ProductsSlice";
import { RegionsSlice } from "../app/modules/coinWrap/_redux/Models/Regions/RegionsSlice";
import { UnitTypesSlice } from "../app/modules/coinWrap/_redux/Models/UnitTypes/UnitTypeSlice";
import { UsersSlice } from "../app/modules/coinWrap/_redux/Models/Users/UsersSlice";
import { PermissionsSlice } from "../app/modules/coinWrap/_redux/RolesAndPermissionsModels/Permissions/PermissionsSlice";
import { RolesSlice } from "../app/modules/coinWrap/_redux/RolesAndPermissionsModels/Roles/RolesSlice";
import { RolesAndPermissionSlice } from "../app/modules/coinWrap/_redux/RolesAndPermissionsModels/RolesAndPermission/RolesAndPermissionSlice";
import { ShipmentFilesSlice } from "../app/modules/coinWrap/_redux/ShipmentFiles/ShipmentFilesSlice";
import { TenderIDAuditLogsSlice } from "../app/modules/coinWrap/_redux/TransportOrders/TenderIDAuditLogsSlice";
import { TransportOrdersSlice } from "../app/modules/coinWrap/_redux/TransportOrders/TransportOrdersSlice";
import { generalSlice } from "../app/modules/coinWrap/_redux/general/generalSlice";
import { StatusSlice } from "../app/modules/coinWrap/_redux/Orders/Status/StatusSlice";
import { LedgerSlice } from "../app/modules/coinWrap/_redux/Ledger/LedgerSlice";
import { CustomerCarriersSlice } from "../app/modules/coinWrap/_redux/Models/CustomerCarriers/CustomerCarriersSlice";
import { DepositorsSlice } from "../app/modules/coinWrap/_redux/Models/Depositors/DepositorsSlice";
import { CustomersSlice } from "../app/modules/coinWrap/_redux/Models/Customers/CustomersSlice";
import { ControlIdActionSlice } from "../app/modules/coinWrap/_redux/ControlIdActions/ControlIdActionsSlice";
import { DashboardSlice } from "../app/modules/coinWrap/_redux/Dashboard/DashboardSlice";
import { ProductionLineSlice } from "../app/modules/coinWrap/_redux/Models/ProductionLine/ProductionLineSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  general: generalSlice.reducer,
  coinTypes: CoinTypesSlice.reducer,
  coinTypeProducts: CoinTypeProductsSlice.reducer,
  carriers: CarrierSlice.reducer,
  transportOrders: TransportOrdersSlice.reducer,
  tenderIDAuditLogs: TenderIDAuditLogsSlice.reducer,
  auditLogs: AuditLogsSlice.reducer,
  users: UsersSlice.reducer,
  ShipmentFiles: ShipmentFilesSlice.reducer,
  employees: EmployeesSlice.reducer,
  employeeLocation: EmployeeLocationSlice.reducer,
  regions: RegionsSlice.reducer,
  divisions: DivisionsSlice.reducer,
  documents: DocumentsSlice.reducer,
  unitTypes: UnitTypesSlice.reducer,
  organizations: OrganizationsSlice.reducer,
  accounts: AccountsSlice.reducer,
  permissions: PermissionsSlice.reducer,
  roles: RolesSlice.reducer,
  rolesAndPermissions: RolesAndPermissionSlice.reducer,
  inventory: InventorySlice.reducer,
  products: ProductsSlice.reducer,
  locations: LocationsSlice.reducer,
  status: StatusSlice.reducer,
  ledger: LedgerSlice.reducer,
  customerCarriers: CustomerCarriersSlice.reducer,
  depositors: DepositorsSlice.reducer,
  customers: CustomersSlice.reducer,
  controlIdAction: ControlIdActionSlice.reducer,
  dashboard: DashboardSlice.reducer,
  productionLine: ProductionLineSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
