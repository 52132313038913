/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import AllAlerts from "./AllAlerts";
import BasePage from "./BasePage";
import AuthPage from "./modules/Auth/pages/AuthPage";
import Logout from "./modules/Auth/pages/Logout";
import { checkSession, getUserByToken } from "./modules/Auth/_redux/authCrud";
import { actions } from "./modules/Auth/_redux/authRedux";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import TenderDetailPage from "./pages/TenderDetailPage";
import { getCurrentUser } from "./modules/coinWrap/_redux/RolesAndPermissionsModels/RolesAndPermission/RolesAndPermissionCrud";
import { RolesAndPermissionSlice } from "./modules/coinWrap/_redux/RolesAndPermissionsModels/RolesAndPermission/RolesAndPermissionSlice";
import { getPermissionsList } from "./modules/coinWrap/_redux/RolesAndPermissionsModels/Permissions/PermissionsCrud";
import { ProcessingRoute } from "./modules/coinWrap/Helpers/Common/CommonComponent";

export function Routes() {
  const { isAuthorized, authToken } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      authToken: auth.authToken != null,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const { actions: RolesAndPermissionAction } = RolesAndPermissionSlice;
  const [loading, setloading] = useState(true);
  const [isInviteCode, setIsInviteCode] = useState(false);
  const history = useHistory();

  const sessionCheck = () => {
    return new Promise((resolve, reject) => {
      checkSession()
        .then((session) => {
          if (session) resolve(true);
          else resolve(false);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const inviteCode = params.get("inviteCode");
    if (inviteCode) {
      setIsInviteCode(true);
    }
  }, []);

  const getUserRoleData = () => {
    setloading(true);
    getCurrentUser()
      .then((res) => {
        if (res?.data?.getCurrentUser) {
          dispatch(RolesAndPermissionAction.setUserDetails(res?.data?.getCurrentUser));
          let data = JSON.parse(res?.data?.getCurrentUser?.Role?.permissions);
          dispatch(RolesAndPermissionAction.setRolesAndPermissions(data));
          getPermissionsData();
          const allRoles = Object.values(data)?.flat(2)?.filter((v) => v);
          if (allRoles.length === 1 && allRoles[0] === "VIEW_ORDER_PROCESSING") {
            history.push(ProcessingRoute);
          }
        }
        else {
          dispatch(RolesAndPermissionAction.setRolesAndPermissions({}));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const getPermissionsData = () => {
    setloading(true);
    getPermissionsList(-1)
      .then((res) => {
        dispatch(
          RolesAndPermissionAction.rolesAndPermissionsFetched(
            res?.data?.getPermissions?.Permissions
          )
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const inviteCode = params.get("inviteCode");
    (async () => {
      if (authToken) {
        sessionCheck()
          .then(async (session) => {
            if (session) {
              const user = await getUserByToken();
              let currentuser = {
                username: user.username,
                useremail: user.attributes.email,
              };
              dispatch(actions.fulfillUser(currentuser));
              setloading(false);
              !inviteCode && getUserRoleData();
              // getPermissionsData();
            }
          })
          .catch((error) => {
            console.error(error);
            history.push("/logout");
            setloading(false);
          })
          .finally(() => {
            // setloading(false);
          });
      } else setloading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
    <>
      {loading && <LayoutSplashScreen />}
      {!loading && (
        <Switch>
          {isInviteCode && (
            <Route path="/tender-detail" component={TenderDetailPage} />
          )}

          {!isAuthorized && !isInviteCode ? (
            /*Render auth page when user at `/auth` and not authorized.*/
            <Route>
              <AuthPage />
            </Route>
          ) : (
            /*Otherwise redirect to root page (`/`)*/
            <Redirect from="/auth" to="/" />
          )}
          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={Logout} />

          {!isAuthorized ? (
            /*Redirect to `/auth` when user is not authorized*/

            <Redirect to="/auth/login" />
          ) : (
            <Layout>
              <BasePage />
            </Layout>
          )}
        </Switch>
      )}
      <AllAlerts />
    </>
  );
}
