import React from "react";
import TenderDetailLink from "./TenderDetailLink";

const TenderDetailLinkRoute = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const inviteCode = params.get("inviteCode");
  const isInProcess = params.get("isInProcess");


  return <TenderDetailLink inviteCode={inviteCode} isInProcess={isInProcess} />;
};

export default TenderDetailLinkRoute;
