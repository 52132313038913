import { Box, Checkbox, FormControlLabel } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Form } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { TransportOrdersSlice } from "../../../_redux/TransportOrders/TransportOrdersSlice";
import { requirements } from "../../../Helpers/Common/CommonComponent";
import LocationBusiDays from "./LocationBusiDays/LocationBusiDays";
import "./styles.css";

const LocationDetailsForm = ({ stopData, trackRequirements }) => {
  const dispatch = useDispatch();
  const { actions } = TransportOrdersSlice;

  const { businessDaysData } = useSelector(
    (state) => ({
      businessDaysData: state.transportOrders.businessDaysData,
    }),
    shallowEqual
  );

  const [mintLocationData, setMintLocationData] = useState({});
  const [dataIndex, setDataIndex] = useState(0);
  const [parseEquipmentData, setParseEquipmentData] = useState([]);
  const [unloadRequireData, setUnloadRequireData] = useState("");
  const [dockData, setDockData] = useState(false);
  const [businessArr, setBusinessArr] = useState([]);
  const [pertinentDay, setPertinentDay] = useState([]);

  let arr = [];

  function toObject(arr) {
    var rv = {};
    for (var i = 0; i < arr.length; ++i) rv[i] = arr[i];
    dispatch(actions.setBusinessDaysData(rv));
    return rv;
  }

  useEffect(() => {
    Object.entries(businessDaysData).forEach(([k, v]) => {
      arr.push(v);
      setBusinessArr(arr);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessDaysData]);

  const handleDays = (value, idx, name) => {
    if (name === "enabled") {
      setBusinessArr(() => {
        let arrToReturn = businessArr;
        let arr1 = JSON.parse(JSON.stringify(arrToReturn));
        arr1[idx][name] = Boolean(value);
        toObject(arr1);
        return arr1;
      });
    }
  };

  const clickData = (data, index) => {
    setMintLocationData(data);
    setPertinentDay(moment(new Date(data?.shipmentDate)).format("ddd"));
    dispatch(
      actions.setBusinessDaysData(JSON.parse(data?.LocationTransport?.Location?.businessDays || "{}"))
    );
    setDataIndex(index);
  };

  useEffect(() => {
    clickData(stopData[0], 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopData]);

  const equipmentData = mintLocationData?.LocationTransport?.equipment;

  useEffect(() => {
    if (equipmentData !== undefined) {
      const data = JSON.parse(equipmentData);
      setParseEquipmentData(
        data?.map((v) => ({
          label: v,
          value: v,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintLocationData?.LocationTransport?.equipment]);

  const requirementsData = mintLocationData?.LocationTransport?.unloadRequirements;

  useEffect(() => {
    if (requirementsData !== undefined) {
      const data = requirementsData;
      setUnloadRequireData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintLocationData?.LocationTransport?.unloadRequirements]);

  const DockRequirementData = mintLocationData?.LocationTransport?.dockRequirements;

  useEffect(() => {
    if (DockRequirementData !== undefined) {
      const data = DockRequirementData;
      setDockData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintLocationData?.LocationTransport?.dockRequirements]);

  const uniqueMintCodes = stopData.map((data) => data?.locationCode);
  const filteredData = stopData.filter(
    ({ locationCode }, index) => !uniqueMintCodes.includes(locationCode, index + 1)
  );

  return (
    <>
      <Box
        sx={{
          padding: "20px",
          fontSize: "1.5rem",
          fontWeight: 600,
        }}
      >
        Location Details
      </Box>
      <Box
        sx={{
          padding: "0px 20px 20px 20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "1px",
        }}
      >
        {filteredData?.map((data, index) => (
          <ButtonToolbar key={index}>
            <ButtonGroup className="me-2" aria-label="First group">
              <Button
                className={
                  dataIndex === index ? "button-group" : "btn-light text-dark"
                }
                style={{
                   minWidth: "80px",
                  flex: 1,
                  borderRadius: "0px",
                }}
                onClick={() => clickData(data, index)}
              >
                {data.locationCode}
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ))}
      </Box>

      <Box
        sx={{
          padding: "0px 20px 20px 20px",
        }}
      >
        <>
          <Form.Row>
            <Col md={8} sm={12}>
              <Form.Row>
                <Col sm={12} md={12}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: 600 }}>
                      Equipment On Site
                    </Form.Label>
                    <Select
                      menuPlacement="auto"
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 1301 }),
                      }}
                      value={parseEquipmentData || []}
                      classNamePrefix="reactselect-select"
                      isSearchable={true}
                      isMulti
                      placeholder="Select equipment"
                      noOptionsMessage={() => "No equipment Found"}
                      isDisabled={true}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col sm={12} md={12}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: 600 }}>
                      Load/Unload Requirements
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="unloadRequirements"
                      value={unloadRequireData}
                      disabled={true}
                    >
                      <option
                        value={""}
                        key={""}
                        style={{ fontStyle: "italic" }}
                      >
                        Select requirement
                      </option>
                      {Object.keys(requirements)
                        .sort()
                        .map((k) => (
                          <option value={k} key={k}>
                            {requirements[k]}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col sm={12}>
                  <FormControlLabel
                    value="dockRequirements"
                    sx={{
                      fontWeight: 600,
                      color: "#3f4254 !important",
                      flexDirection: "row",
                    }}
                    disabled={true}
                    control={
                      <Checkbox
                        checked={dockData}
                        disabled={true}
                        color="primary"
                        name="dockRequirements"
                      />
                    }
                    label="Dock Requirements"
                  />
                </Col>
              </Form.Row>

              {dockData && (
                <>
                  <Form.Row>
                    <Col sm={12} md={4} lg={4}>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 600 }}>
                          Dock Height
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="dockHeight"
                          value={
                            mintLocationData?.LocationTransport?.dockHeight || 0
                          }
                          disabled={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={4} lg={4}>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 600 }}>
                          Dock Length
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="dockLength"
                          value={
                            mintLocationData?.LocationTransport?.dockLength || 0
                          }
                          disabled={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={4} lg={4}>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 600 }}>
                          Dock Width
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="dockWidth"
                          value={mintLocationData?.LocationTransport?.dockWidth || 0}
                          disabled={true}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col sm={12} md={12}>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 600 }}>
                          Dock Notes
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          type="text"
                          name="dockNotes"
                          value={
                            mintLocationData?.LocationTransport?.dockNotes || ""
                          }
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </>
              )}

              <Form.Row>
                <Col sm={12} md={12}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: 600 }}>
                      Location Transport Comments
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      type="text"
                      name="transportComments"
                      value={
                        mintLocationData?.LocationTransport?.transportComments || ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </Col>

            <Col md={4} sm={12}>
              <Form.Row>
                <Col sm={12} md={12}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: 600 }}>
                      Minimum Truck Requirements
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      type="text"
                      name="minTruckRequirements"
                      value={trackRequirements}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col sm={12} md={12}>
                  <Form.Group>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Form.Label style={{ fontWeight: 600 }}>
                        Location Business Days/Hours
                      </Form.Label>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          marginTop: "10px",
                          width: "100%",
                          padding: "10px",
                          border: "1px solid #ddd",
                        }}
                      >
                        <LocationBusiDays
                          businessArr={businessArr}
                          isDisabled={true}
                          handleDays={handleDays}
                          pertinentDay={pertinentDay}
                        />
                      </Box>
                    </Box>
                  </Form.Group>
                </Col>
              </Form.Row>
            </Col>
          </Form.Row>
        </>
      </Box>
    </>
  );
};

export default LocationDetailsForm;
