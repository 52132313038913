import {
  accountsRoute, ALL_MODEL_LABEL,
  allModelLabelData, coinTypesRoute,
  consolidateRoutes,
  CreateRoute, customerCarriersRoute,
  customersRoute, InventoryRoute, ledgerRoute, MAIN_LABEL, organizationsRoute,
  permissionsRoute, ProcessingRoute, productionLineRoute, productionRoute, productsRoute,
  rolesRoute, StatusRoute, unitTypeRoute,
} from "../../../../../app/modules/coinWrap/Helpers/Common/CommonComponent";

const dashboardItem = {
  label: "Dashboard",
  FWIconClassName: "fas fa-layer-group",
  linkTo: "/dashboard",
  checkIfLicenseExist: true,
  licenseCheckField: null,
  sectionLabel: "NO_LABEL",
  permissionType: MAIN_LABEL.TRANSPORT_ORDERS,
};

const MintItems = [
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-dolly",
    linkTo: "/transport-orders",
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Transport Orders",
    permissionType: MAIN_LABEL.TRANSPORT_ORDERS,
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "far fa-copy",
    linkTo: "/models",
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Models",
    permissionType: ALL_MODEL_LABEL.MODELS,
    items: [
      {
        FWIconClassName: "far fa-circle",
        linkTo: "/models/locations",
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Locations",
        permissionType: MAIN_LABEL.LOCATIONS,
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: coinTypesRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Product Types",
        permissionType: MAIN_LABEL.PRODUCT_TYPES,
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: productsRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Products",
        permissionType: MAIN_LABEL.PRODUCTS
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: "/models/coinTypeProducts",
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Coin Type Products",
        permissionType: MAIN_LABEL.COIN_TYPE_PRODUCTS,
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: "/models/carrier",
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Carriers",
        permissionType: MAIN_LABEL.CARRIERS,
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: unitTypeRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Product Unit Types",
        permissionType: MAIN_LABEL.PRODUCT_UNIT_TYPES,
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: "/models/users",
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Users",
        permissionType: MAIN_LABEL.USERS,
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: customersRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Customers",
        permissionType: MAIN_LABEL.CUSTOMERS,
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: customerCarriersRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Customer Carriers",
        permissionType: MAIN_LABEL.CUSTOMER_CARRIERS,
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: productionLineRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Production Line",
        permissionType: MAIN_LABEL.PRODUCTION_LINE,
      },
      {
        sectionLabel: "NO_LABEL",
        FWIconClassName: "fas fa-users",
        linkTo: "/models",
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Employees",
        permissionType: ALL_MODEL_LABEL.EMPLOYEES,
        items: [
          {
            FWIconClassName: "far fa-circle",
            linkTo: "/models/employees",
            checkIfLicenseExist: true,
            licenseCheckField: null,
            label: "Employees",
            permissionType: MAIN_LABEL.EMPLOYEES,
          },
          {
            FWIconClassName: "far fa-circle",
            linkTo: "/models/regions",
            checkIfLicenseExist: true,
            licenseCheckField: null,
            label: "Regions",
            permissionType: MAIN_LABEL.REGIONS,
          },
          {
            FWIconClassName: "far fa-circle",
            linkTo: "/models/divisions",
            checkIfLicenseExist: true,
            licenseCheckField: null,
            label: "Divisions",
            permissionType: MAIN_LABEL.DIVISIONS,
          },
        ],
      },
      {
        sectionLabel: "NO_LABEL",
        FWIconClassName: "fas fa-building",
        linkTo: "/models",
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Inventory Models",
        permissionType: ALL_MODEL_LABEL.INVENTORY,
        items: [
          {
            FWIconClassName: "far fa-circle",
            linkTo: organizationsRoute,
            checkIfLicenseExist: true,
            licenseCheckField: null,
            label: "Organizations",
            permissionType: MAIN_LABEL.ORGANIZATIONS,
          },
          {
            FWIconClassName: "far fa-circle",
            linkTo: accountsRoute,
            checkIfLicenseExist: true,
            licenseCheckField: null,
            label: "Accounts",
            permissionType: MAIN_LABEL.ACCOUNTS,
          },
        ],
      },
    ],
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-warehouse",
    linkTo: InventoryRoute,
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Inventory",
    permissionType: MAIN_LABEL.INVENTORY,
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-wallet",
    linkTo: ledgerRoute,
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Ledger",
    permissionType: MAIN_LABEL.INVENTORY,
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-shipping-fast",
    linkTo: "/orders",
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Orders",
    permissionType: ALL_MODEL_LABEL.ORDERS,
    items: [
      {
        FWIconClassName: "far fa-circle",
        linkTo: CreateRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Create",
        permissionType: MAIN_LABEL.CREATE
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: StatusRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Status",
        permissionType: MAIN_LABEL.STATUS
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: ProcessingRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Processing",
        permissionType: MAIN_LABEL.PROCESSING,
      },
    ],
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-industry",
    linkTo: productionRoute,
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Production",
    permissionType: MAIN_LABEL.CONTROL_ID_MGMT,
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-tasks",
    linkTo: consolidateRoutes,
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: allModelLabelData["Consolidate Control ID"],
    permissionType: MAIN_LABEL.CONTROL_ID_MGMT,
    // items: [
    // {
    //   FWIconClassName: "far fa-circle",
    //   linkTo: conversionStatusRoutes,
    //   checkIfLicenseExist: true,
    //   licenseCheckField: null,
    //   label: "Status",
    //   permissionType: MAIN_LABEL.STATUS
    // },
    // {
    //   FWIconClassName: "far fa-circle",
    //   linkTo: conversionProductionRoutes,
    //   checkIfLicenseExist: true,
    //   licenseCheckField: null,
    //   label: "Production",
    //   permissionType: MAIN_LABEL.STATUS
    // },
    // {
    //   FWIconClassName: "far fa-circle",
    //   linkTo: consolidateRoutes,
    //   checkIfLicenseExist: true,
    //   licenseCheckField: null,
    //   label: "Consolidate",
    //   permissionType: MAIN_LABEL.STATUS,
    // },
    // ],
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-clipboard-list",
    linkTo: "/shipment-files",
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Shipment Files",
    permissionType: MAIN_LABEL.SHIPMENT_FILES,
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-file",
    linkTo: "/documents",
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Documents",
    permissionType: MAIN_LABEL.DOCUMNETS,
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-user-cog",
    linkTo: "/roles_permissions",
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Roles and Permissions",
    permissionType: ALL_MODEL_LABEL.ROLES_AND_PERMISSIONS,
    items: [
      {
        FWIconClassName: "far fa-circle",
        linkTo: permissionsRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Permissions",
        permissionType: MAIN_LABEL.PERMISSIONS,
      },
      {
        FWIconClassName: "far fa-circle",
        linkTo: rolesRoute,
        checkIfLicenseExist: true,
        licenseCheckField: null,
        label: "Roles",
        permissionType: MAIN_LABEL.ROLES,
      },
    ],
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-cog",
    linkTo: "/settings",
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Settings",
    permissionType: MAIN_LABEL.SETTINGS,
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-clipboard-check",
    linkTo: "/audit-logs",
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: "Audit Logs",
    permissionType: MAIN_LABEL.AUDIT_LOGS,
  },
  {
    sectionLabel: "NO_LABEL",
    FWIconClassName: "fas fa-clipboard-list",
    linkTo: "/control-id-history",
    checkIfLicenseExist: true,
    licenseCheckField: null,
    label: allModelLabelData["Control ID Actions"],
    permissionType: MAIN_LABEL.CONTROL_ID_HISTORY,
  },
];

export const allMenuItems = [dashboardItem, ...MintItems];

export const arrayToObjectArray = (arr) => {
  let ob = {};

  arr.forEach((a) => {
    ob = {
      ...ob,
      [a.sectionLabel]: {
        ...ob[a.sectionLabel],
        label: a.sectionLabel,
        items: [...(ob[a.sectionLabel]?.items || []), a],
      },
    };
  });

  return ob;
};

export const getFilteredByLicensePermissionSearchKey = (arr = []) => {
  return arr;
};
