import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { siteNameAndYear } from "../../../../app/modules/coinWrap/Helpers/Common/CommonComponent";

export function Footer() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <a
            href="http://www.coinwrap.com/"
            target="blank"
            rel="noopener noreferrer"
            className="text-muted  mr-2"
          >
            &copy; {siteNameAndYear}
            {/* - {new Date().getFullYear()} */}
          </a>
        </div>
      </div>
    </div>
  );
}
