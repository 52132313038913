import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FileCopyOutlined from "@mui/icons-material/FileCopyOutlined";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import DialogCloseTitle from "../../../Helpers/Dialog/DialogTitle";
import TableCellExpandList from "../../../Helpers/Table/TableCellExpandList";
import BootstrapButton from "../../../Helpers/UI/Button/BootstrapButton";
import { driverFormsUploadedCrud } from "../../../_redux/TransportOrders/TransportOrdersCrud";
import { generalSlice } from "../../../_redux/general/generalSlice";

const DriverLicenseModal = ({
  setShowDriverLicenseModal,
  showDriverLicenseModal,
  stopData,
  isLoading,
  inviteCode,
  getData,
}) => {
  const dispatch = useDispatch();
  const { actions: generalActions } = generalSlice;

  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);

  function checkMint(mints) {
    return true;
  }

  useEffect(() => {
    setData(() => {
      return stopData?.filter(checkMint)?.map((data) => ({
        ...data,
        isSentAlready: false,
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setShowDriverLicenseModal(false);
  };

  const copyTextFunction = (copyText) => {
    navigator.clipboard.writeText(copyText);
    dispatch(
      generalActions.pushNewAlert({
        show: true,
        heading: "Success",
        message: "Email Copy To Clipboard",
        type: "success",
      }))   
  };

  const handleInputChange = (value) => {
    setData((prev) => {
      const arrayToReturn = JSON.parse(JSON.stringify(prev));
      arrayToReturn[0].isSentAlready = value;
      return arrayToReturn;
    });
  };

  const uploadDriverData = () => {
    setIsUploadLoading(true);
    setIsSubmitting(true);
    driverFormsUploadedCrud(inviteCode)
      .then((res) => {
        if (res?.data?.driverFormsUploaded?.status === false) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't submit",
              type: "danger",
            })
          );
        } else {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Success",
              message: "Submitted Successfully",
              type: "success",
            })
          );
          setShowDriverLicenseModal(false);
          getData();
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
        if (error) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't submit",
              type: "danger",
            })
          );
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsUploadLoading(false);
      });
  };

  const url = process.env.REACT_APP_DRIVER_CLEARANCE_URL;
  return (
    <>
      <Dialog
        open={showDriverLicenseModal}
        fullWidth={true}
        disableEscapeKeyDown={true}
        maxWidth="md"
        style={{
          zIndex: 200,
        }}
      >
        <DialogCloseTitle
          onClose={handleClose}
          style={{
            fontSize: "1.5rem",
            fontWeight: 600,
            color: (theme) => theme.palette.primary.main,
            gap: "8px",
          }}
        >
          <div>Download and Send Driver Clearance Form</div>
        </DialogCloseTitle>
        <DialogContent dividers>
          <Row>
            <Col sm={12}>
              <Box
                sx={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px dashed gray",
                  margin: "10px",
                  flexDirection: "column",
                }}
              >
                <CloudDownloadIcon style={{ fontSize: "2.5rem" }} />
                <Box>
                  <a
                    href={url}
                    style={{
                      color: "#3699FF",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: 500,
                      marginTop: "10px",
                      fontSize: "14px",
                    }}
                  >
                    Download Driver Clearance Sample Form
                  </a>
                </Box>
              </Box>
            </Col>
          </Row>

          <DialogCloseTitle
            style={{
              fontSize: "1.5rem",
              fontWeight: 600,
              color: (theme) => theme.palette.primary.main,
              gap: "8px",
              paddingLeft: "0px",
            }}
          >
            <div>Send driver clearance form by email to required locations</div>
          </DialogCloseTitle>
          {data?.length === 0 ? (
            isLoading && (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <Spinner animation="border" />
              </span>
            )
          ) : (
            <Box sx={{ paddingTop: "10px" }}>
              {data && (
                <>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Address
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Send Form to Email
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "1.2rem",
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                            textAlign: "center",
                          }}
                        >
                          Mark as Sent
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& > *:nth-of-type(even)": {
                          backgroundColor: "#aaaaaa40",
                        },
                        overflow: "auto",
                      }}
                    >
                      <TableRow key={data?.[0]?.ID}>
                        <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                          {data?.[0]?.stopType || "-"}
                        </TableCell>
                        <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                          <>
                            <div>
                              {" "}
                                {data?.[0]?.LocationTransport?.Location?.locationCode || "-"}
                            </div>
                              <div>{`${data?.[0]?.LocationTransport?.Location?.address ||
                                ""} ${data?.[0]?.LocationTransport?.Location?.address2 ||
                              ""}`}</div>
                              <div>{`${data?.[0]?.LocationTransport?.Location?.city ||
                                ""} ${data?.[0]?.LocationTransport?.Location?.state ||
                                ""} ${data?.[0]?.LocationTransport?.Location?.zipCode ||
                              ""}`}</div>
                          </>
                        </TableCell>
                          <TableCell sx={{ color: "#000", fontSize: "1rem" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <div>
                              <Tooltip
                                disableInteractive={true}
                                arrow
                                title="Click here to copy Email"
                              >
                                <IconButton
                                  style={{
                                    padding: "6px",
                                    border: "1px solid #666",
                                  }}
                                  onClick={() =>
                                    copyTextFunction(
                                      data?.[0]?.LocationTransport?.bidApprovalEmails
                                    )
                                  }
                                >
                                  <FileCopyOutlined fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div>
                              <TableCellExpandList
                                list={
                                    data?.[0]?.LocationTransport?.bidApprovalEmails?.split(
                                    ","
                                  ) || "-"
                                }
                                minItemsToShow={3}
                                renderItem={(emailArr) => (
                                  <div key={emailArr}>
                                    {emailArr?.length === 0 ? "-" : emailArr}
                                  </div>
                                )}
                                containerStyles={{
                                  maxHeight: "150px",
                                  overflow: "auto",
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={data?.[0]?.isSentAlready}
                            onChange={(e) =>
                              handleInputChange(e.target.checked)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </>
              )}
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <BootstrapButton
            variant="primary"
            type="submit"
            label="Submit"
            isSubmitting={isSubmitting}
            disabled={isUploadLoading || data?.[0]?.isSentAlready === false}
            labelWhenSubmitting="Submitting"
            onClick={() => uploadDriverData()}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DriverLicenseModal;
