import { Paper, Skeleton } from "@mui/material";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import React from "react";
import { Bar } from "react-chartjs-2";
import { shallowEqual, useSelector } from "react-redux";


Chart.register(CategoryScale);

const TransportStatusCount = ({
  onClickCard,
  transportOrderStatusCountData
}) => {

  const {
    isLoading,
    chartDateRange,
    transportOrderStatusDateWiseArr
  } = useSelector(
    (state) => ({
      isLoading: state.dashboard.isLoading,
      chartDateRange: state.dashboard.chartDateRange,
      transportOrderStatusDateWiseArr: state.dashboard.transportOrderStatusDateWiseArr,
    }),
    shallowEqual
  );

  return (
    <>
      <Paper style={{ height: "100%", cursor: 'pointer' }} onClick={onClickCard}>
        <div className="mt-3" style={{ display: "grid", gridTemplateColumns: "repeat(8,1fr)", gridTemplateRows: "40px 90px", cursor: 'ponter' }}>
          {!isLoading ? (
            <>
          <div
                className="ml-3 mt-2"
            style={{
              alignContent: "center",
              gridColumn: "span 8",
              gridRow: "1/2",
              fontSize: "1.5rem",
              fontWeight: "550",
              color: "#4c535a"
            }}
          >
                Transport Orders by Status
              </div>
              {transportOrderStatusCountData?.map((v, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      gridColumn: "span 1",
                      gridRow: "2/3",
                      fontSize: "1.1rem",
                      color: "#a5a5a5",
                      textAlign: "center",
                      alignContent: "center"
                    }}
                  >
                    {v?.status}
                    <div style={{ fontSize: "1.7rem", fontWeight: 550, color: "rgb(80 75 75 / 87%)" }}>
                      {v?.count}
                    </div>
                  </div>
                );
              })}
            </>
          ) :
            <>
              <Skeleton style={{ width: "250px", gridRow: "1/2" }} />
              {transportOrderStatusCountData?.map((v, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      gridColumn: "span 1",
                      gridRow: "2/3",
                      fontSize: "1.1rem",
                      color: "#a5a5a5",
                      textAlign: "center",
                      alignContent: "center"
                    }}
                  >
                    <Skeleton style={{ width: "100px", gridRow: "1/2" }} />
                    <div style={{ fontSize: "1.7rem", fontWeight: 550, color: "rgb(80 75 75 / 87%)" }}>
                      <Skeleton style={{ width: "50px", gridRow: "1/2" }} />
                    </div>
                  </div>
                );
              })}
            </>
          }
        </div>
      </Paper>

      <Paper style={{ height: "100%", cursor: 'pointer' }} onClick={onClickCard}>
        <div className="mt-3" style={{ display: "grid", gridTemplateColumns: "repeat(8,1fr)", gridTemplateRows: "40px repeat(4,70px)", cursor: 'poniter' }}>
          <>
            {!isLoading ? (
              <>
                <div
                  className="ml-3 mt-2"
                  style={{
                    alignContent: "center",
                    gridColumn: "span 8",
                    gridRow: "1/2",
                    fontSize: "1.5rem",
                    fontWeight: "550",
                    color: "#4c535a"
                  }}
                >
                  Transport Orders by Date
                </div>
                <div className="mt-1" style={{ gridRow: "2/6", gridColumn: "span 8" }}>
                  <Bar
                    style={{ height: '100%' }}
                    data={{
                      labels: chartDateRange,
                      display: false,
                      datasets: [
                        {
                          label: "Pending Invite",
                          data: transportOrderStatusDateWiseArr?.[0],
                          stack: 'Stack 0',
                          backgroundColor: ["#a1d9f7"],
                        },
                        {
                          label: "Pending Quote",
                          data: transportOrderStatusDateWiseArr?.[1],
                          backgroundColor: ["#e9c2ff"],
                          stack: 'Stack 0',
                        },
                        {
                          label: "Pending Award",
                          data: transportOrderStatusDateWiseArr?.[2],
                          backgroundColor: ["#fbe89d"],
                          stack: 'Stack 0',
                        },
                        {
                          label: "Pending Approval",
                          data: transportOrderStatusDateWiseArr?.[3],
                          backgroundColor: ["#a192c8"], 
                          stack: 'Stack 0',
                        },
                        {
                          label: "Confirmed",
                          data: transportOrderStatusDateWiseArr?.[4],
                          backgroundColor: ["#c7f9cc"],
                          stack: 'Stack 0',
                        },
                        {
                          label: "Order In Process",
                          data: transportOrderStatusDateWiseArr?.[5],
                          backgroundColor: ["#6fb1d8"],
                          stack: 'Stack 0',
                        },
                        {
                          label: "Order Completed",
                          data: transportOrderStatusDateWiseArr?.[6],
                          backgroundColor: ["#53ca97"],
                          stack: 'Stack 0',
                        },
                        {
                          label: "Order Cancelled",
                          data: transportOrderStatusDateWiseArr?.[7],
                          backgroundColor: ["#f58f96"],
                          stack: 'Stack 0',
                        },
                      ]
                    }}
                    options={{
                      maintainAspectRatio: false,
                      barThickness: chartDateRange?.length < 10 ? 80 : 20,
                      plugins: {
                        title: {
                          display: false,
                          text: "Transport Order Status Date Wise Data "
                        },
                        legend: {
                          display: false
                        }
                      },
                      scales: {
                        x: {
                          afterBuildTicks: 73,
                          grid: {
                            display: false,
                          }
                        },
                        y: {
                          display: false,
                          grid: {
                            display: false
                          }
                        }
                      }
                    }}
                  />
                </div>
              </>
            )
              :
              <>
                <Skeleton animation="wave" style={{ gridRow: "1/2", width: "250px" }} />
                <div className="mt-1" style={{ gridRow: "2/6", gridColumn: "span 8" }}>
                  <Skeleton height="280px" animation="wave" />
                </div>
              </>
            }
          </>
        </div>
      </Paper>
    </>
  );
};

export default TransportStatusCount;
