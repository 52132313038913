import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomAlert from "./modules/coinWrap/Helpers/Alert/CustomAlert";
import { generalSlice } from "./modules/coinWrap/_redux/general/generalSlice";

const AllAlerts = (props) => {
  const dispatch = useDispatch();
  const { actions } = generalSlice;

  const { allAlerts } = useSelector(
    (state) => ({
      allAlerts: state.general.allAlerts,
    }),
    shallowEqual
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 5,
        transform: "translateX(50%)",
        right: "50%",
        gap: "5px",
        zIndex: 1301,
        maxWidth: "800px",
      }}
    >
      {allAlerts.map((alert, i, arr) => (
        <CustomAlert
          {...alert}
          key={alert.id}
          isAbsolute={false}
          setShow={(id) => {
            dispatch(actions.deleteAlert(alert.id));
          }}
        />
      ))}
    </div>
  );
};

export default AllAlerts;
