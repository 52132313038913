import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allShipmentFile: [],
  selectedShipmentFile: null,
  isLoading: true,
  searchValue: "",
  searchBy: [],
  page: 0,
  dataCount: 0,
  offset: 0,
  dataPerPage: 15,
  sortField: "updatedOn",
  sortType: "DESC",
};

export const ShipmentFilesSlice = createSlice({
  name: "ShipmentFiles",
  initialState: initialState,
  reducers: {
    setShipmentFiles: (state, action) => {
      state.allShipmentFile = action.payload;
    },
    shipmentFileFetched: (state, action) => {
      state.selectedShipmentFile = action.payload;
    },
    removeSelectedShipmentFile: (state) => {
      state.selectedShipmentFile = null;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = { ...state.searchValue, ...action.payload };
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSortType: (state, action) => {
      state.sortType = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        case "SET_OFFSET":
          state.offset = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
