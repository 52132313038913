import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allPermissions: [],
  selectedPermission: null,
  isLoading: true,
  searchValue: "",
  searchBy: [],
  page: 0,
  dataCount: 0,
  offset: 0,
  dataPerPage: 15,
  sortField: "name",
  sortType: "ASC",
  actionTrigger: false,
};

export const PermissionsSlice = createSlice({
  name: "permissions",
  initialState: initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.allPermissions = action.payload;
    },
    PermissionFetched: (state, action) => {
      state.selectedPermission = action.payload;
    },
    removeSelectedPermission: (state) => {
      state.selectedPermission = null;
    },
    setSearchValue: (state, action) => {
      state.searchValue = { ...state.searchValue, ...action.payload };
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSortType: (state, action) => {
      state.sortType = action.payload;
    },
    setActionTrigger: (state, action) => {
      state.actionTrigger = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        case "SET_OFFSET":
          state.offset = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
