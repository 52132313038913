import React, { createContext } from "react";
import AuditLogs from "./AuditLogs";
import { shallowEqual, useSelector } from "react-redux";
import { MAIN_LABEL, auditLogsRoute } from "../../Helpers/Common/CommonComponent";
import { ErrorPage1 } from "../../../ErrorsExamples/ErrorPage1";
import { Route, Switch } from "react-router-dom";

export const AuditLogsContext = createContext(null);

const AuditLogsRoute = () => {
  const { RolesAndPermissions } = useSelector(
    (state) => ({
      RolesAndPermissions: state.rolesAndPermissions.allRolesAndPermissions,
    }),
    shallowEqual
  );
  const auditLogsEvents = {};

  return (
    <AuditLogsContext.Provider value={auditLogsEvents}>
      {RolesAndPermissions[MAIN_LABEL.AUDIT_LOGS] &&
      RolesAndPermissions[MAIN_LABEL.AUDIT_LOGS].length ? (
        <Switch>
          <Route path={`${auditLogsRoute}/*`} component={ErrorPage1} />
          <Route path={`${auditLogsRoute}`} component={AuditLogs} />
        </Switch>
      ) : (
        <ErrorPage1 />
      )}
    </AuditLogsContext.Provider>
  );
};

export default AuditLogsRoute;
