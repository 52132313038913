/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import bgImg from "../../../../assets/AuthBG.png";
import Logo from "../../../../assets/coinwrap_logo.png";
import { ContentRoute } from "../../../../_metronic/layout";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { siteNameAndYear, version } from "../../coinWrap/Helpers/Common/CommonComponent";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";

export function AuthPage() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-3.jpg")})`,
              flexDirection: "column",
              // alignItems: "center",
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-column-fluid w-100 flex-column justify-content-center align-items-center bgi-size-cover bgi-no-repeat p-4 p-lg-5">
              {/* start:: Aside header */}
              <img
                src={Logo}
                style={{
                  width: "120px",
                  margin: "0 auto",
                  marginBottom: "15px",
                }}
                alt="Login"
              />
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />

                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>

            <div className="d-flex flex-column-auto flex-column flex-sm-row justify-content-center align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {siteNameAndYear}
              </div>
            </div>
            <div
              className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2"
              style={{ textAlign: "center" }}
            >
              {version}
            </div>
          </div>

          {!isMobile && (
            <img
              style={{
                objectFit: "contain",
                padding: "30px",
                opacity: 0.8,
              }}
              src={bgImg}
              className="d-flex flex-row-fluid flex-column justify-content-between"
              alt="Auth Page Bg"
            />
          )}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
export default AuthPage;
