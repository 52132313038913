export const getAuditLog = `query getAuditLogs ($offset:Int,$limit:Int,$searchValue:String,$searchBy:[String],$filter:AWSJSON,$startDate:AWSDateTime,$endDate:AWSDateTime){
  getAuditLogs(offset:$offset, limit:$limit,searchValue:$searchValue,searchBy:$searchBy,filter:$filter,startDate:$startDate,endDate:$endDate
    ) {
    count
    AuditLogs {
      ID
      action
      createdBy
      createdOn
      device
      filter1
      filter2
      filter3
      logLevel
      request
      response
      textDesc
      version
    }
  }
}
`;

export const getAuditLogEventListQuery = `query getActions {
  getActions {
    actions
  }
}
`;
