import moment from "moment";

export const version = "v111.0";

export const equipments = [
  "Pallet Jack",
  "Shrink Wrap",
  "Fork Lift",
  "Manual Pallet Jack",
  "Electronic Pallet Jack",
  "Unknown",
].sort();

export const requirements = {
  ONSITE_EMPLOYEES_LOAD_UNLOAD: "Onsite Employees Load/Unload",
  CARRIER_LOAD_UNLOAD: "Carrier Load/Unload",
  CARRIER_LOAD_UNLOAD_AND_MOVE_INSIDE: "Carrier Load/Unload & Move Inside",
  DRIVER_LOAD_UNLOAD_TO_FROM_DOCK: "Driver Load/Unload to/from Dock",
  DRIVER_LOAD_UNLOAD_TO_FROM_TAILGATE: "Driver Load/Unload to/from Tailgate",
  DRIVER_LOAD_UNLOAD_TO_FROM_TRANSFER_ROOM: "Driver Load/Unload to/from Transfer Room",
  UNKNOWN: "Unknown",
};

export const MintLocationSearchKeys = [//##Delete---
  "address",
  "city",
  "mintCode",
  "mintName",
  "state",
  "zipCode",
];

export const CoinTypeSearchKeys = [
  "ID",
  // "productValue",
  "denomination",
  "description",
  "productCategory",
  "name",
  "productCategory"
];

export const denominationObj = {//%%COIN_TYPE_model
  PENNY: "Penny",
  NICKEL: "Nickel",
  DIME: "Dime",
  QUARTER: "Quarter",
  HALF_DOLLAR: "Half Dollar",
  DOLLAR: "Dollar",
  IKE: "IKE",
  MIXED: "Mixed"
};

export const allDenomination = [//%%COIN_TYPE_model
  { label: "Penny", value: "Penny", productCategory: "COIN", isChecked: false },
  { label: "Nickel", value: "Nickel", productCategory: "COIN", isChecked: false },
  { label: "Dime", value: "Dime", productCategory: "COIN", isChecked: false },
  { label: "Quarter", value: "Quarter", productCategory: "COIN", isChecked: false },
  { label: "Half Dollar", value: "Half Dollar", productCategory: "COIN", isChecked: false },
  { label: "Dollar", value: "Dollar", productCategory: "COIN", isChecked: false },
  { label: "IKE", value: "IKE", productCategory: "COIN", isChecked: false },
  { label: "Mixed", value: "Mixed", productCategory: "COIN", isChecked: false },
  { label: "$1", value: "$1", productCategory: "CASH", isChecked: false },
  { label: "$2", value: "$2", productCategory: "CASH", isChecked: false },
  { label: "$5", value: "$5", productCategory: "CASH", isChecked: false },
  { label: "$10", value: "$10", productCategory: "CASH", isChecked: false },
  { label: "$20", value: "$20", productCategory: "CASH", isChecked: false },
  { label: "$50", value: "$50", productCategory: "CASH", isChecked: false },
  { label: "$100", value: "$100", productCategory: "CASH", isChecked: false },
];

export const inventoryTypeLabels = {//%%COIN_TYPE_model
  BULK: "Bulk",
  MINT: "Mint",
};
export const allInventoryType = [
  { label: "Bulk", value: "Bulk" },
  { label: "Mint", value: "Mint" }
];

export const CarrierSearchKeys = [
  "address",
  "carrierID",
  "carrierName",
  "pocEmail",
  "pocName",
  "city",
];

export const NonCoinMaterialSearchKeys = [
  "itemID",
  "description",
  "count",
  "class",
  "weight",
  "pcsPerPallet",
];

export const UserSearchKeys = ["ID", "email", "firstName", "lastName"];
export const ShipmentFilesSearchKeys = ["filePath", "shipmentFileID"];

export const EmployeesSearchKeys = ["employeeId", "firstName", "lastName"];
export const RegionsSearchKeys = ["name"];
export const DivisionsSearchKeys = ["name"];
export const unitTypeSearchKeys = ["unitType"];
export const OrganizationsSearchKeys = ["name", "ID", "pocName"];
export const AccountsSearchKeys = ["name", "organization", "ID"];
export const PermissionsSearchKeys = ["name", "Code"];
export const RolesSearchKeys = ["name"];
export const CustomerCarriersSearchKeys = ["customerCarrierName"];
export const DepositorSearchKeys = ["depositorName"];
export const CustomersSearchKeys = ["customerName", "billingId"];
export const ProductionLineSearchKeys = ["name", "locationCode"];

export const SuffixLabel = {
  Mr: "Mr.",
  MISS: "Miss.",
  MRS: "Mrs.",
};

export const crewChiefStatusLabel = {
  1: "1",
  2: "2",
  3: "3",
};

export const employmentType = {
  "Full-Time": "FULLTIME",
  "Part-Time": "PARTTIME",
  Seasonal: "SEASONAL",
};

export const paymentType = {
  Salary: "SALARY",
  Hourly: "Hourly",
};

export const timeZoneLabel = {
  "Atlantic Standard/Daylight Time": "Atlantic Standard/Daylight Time",
  "Eastern Standard/DaylightTime": "Eastern Standard/DaylightTime",
  "Central Standard/Daylight Time": "Central Standard/Daylight Time",
  "Alaska Standard/Daylight Time": "Alaska Standard/Daylight Time",
  "Hawaii-Aleutian Standard/Daylight Time":
    "Hawaii-Aleutian Standard/Daylight Time",
  "Mountain Standard/Daylight Time	": "Mountain Standard/Daylight Time	",
  "Pacific Standard/Daylight Time": "Pacific Standard/Daylight Time",
  "Eastern Standard Time": "Eastern Standard Time",
};

export const timeZone = [
  { label: "Atlantic Standard/Daylight Time", value: "Canada/Atlantic" },
  { label: "Alaska Standard/Daylight Time", value: "US/Alaska" },
  { label: "Central Standard/Daylight Time", value: "America/Chicago" },
  { label: "Chamorro Standard Time", value: "CHST" },
  { label: "Eastern Standard/DaylightTime", value: "America/New_York" },
  { label: "Hawaii-Aleutian Standard/Daylight Time", value: "US/Hawaii" },
  { label: "Mountain Daylight Time", value: "America/Denver" },
  { label: "Mountain Standard Time", value: "America/Phoenix" },
  { label: "Pacific Standard/Daylight Time", value: "America/Los_Angeles" },
  { label: "UTC", value: "UTC" }];

export const homeLocationLabel = {
  California: "CALIFORNIA",
  "New York": "NEW YORK",
  Florida: "FlORIDA",
};

export const locationManagerList = [
  "locationManager11",
  "locationManager22",
  "locationManager33",
];

export const EmployeeLocationSearchKeys = ["ID", "name", "internalId", "city"];

export const timmingLabels = {
  1: "1",
  1.25: "1.25",
  1.5: "1.5",
  1.75: "1.75",
  2: "2",
};

export const RegionLabels = {
  "Bigelow - South East": "Bigelow - South East",
  Corporate: "Corporate",
  "Siegwarth - North Central": "Siegwarth - North Central",
};

export const StateLabels = {
  Alabama: "Alabama",
  Alaska: "Alaska",
  Arizona: "Arizona",
  Arkansas: "Arkansas",
  California: "California",
  Colorado: "Colorado",
  Connecticut: "Connecticut",
  Delaware: "Delaware",
  Florida: "Florida",
  Georgia: "Georgia",
  Hawaii: "Hawaii",
  Idaho: "Idaho",
  Illinois: "Illinois",
  Indiana: "Indiana",
  Iowa: "Iowa",
  Kansas: "Kansas",
  Kentucky: "Kentucky",
  Louisiana: "Louisiana",
  Maine: "Maine",
  Maryland: "Maryland",
  Massachusetts: "Massachusetts",
  Michigan: "Michigan",
  Minnesota: "Minnesota",
  Mississippi: "Mississippi",
  Missouri: "Missouri",
  Montana: "Montana",
  Nebraska: "Nebraska",
  Nevada: "Nevada",
  Ohio: "Ohio",
  Oklahoma: "Oklahoma",
  Oregon: "Oregon",
  Pennsylvania: "Pennsylvania",
  Tennessee: "Tennessee",
  Texas: "Texas",
  Utah: "Utah",
  Vermont: "Vermont",
  Virginia: "Virginia",
  Washington: "Washington",
  Wisconsin: "Wisconsin",
  Wyoming: "Wyoming",
  "New Hampshire": "New Hampshire",
  "New Jersey": "New Jersey",
  "New Mexico": "New Mexico",
  "New York": "New York",
  "North Carolina": "North Carolina",
  "North Dakota": "North Dakota",
  "Rhode Island": "Rhode Island",
  "South Carolina": "South Carolina",
  "South Dakota": "South Dakota",
  "West Virginia": "West Virginia",
};

export const TimeZoneLabels = {
  "Alaska Standard/Daylight Time": "Alaska Standard/Daylight Time",
  "Eastern Standard Time": "Eastern Standard Time",
  "Eastern Standard/DaylightTime": "Eastern Standard/DaylightTime",
};

export const auditLogsSearchKeys = [
  "action",
  "logLevel",
  "request",
  "response",
  "filter1",
  "filter2",
  "filter3",
];

export const flagsForSettings = {
  TRANSPORT_CONTROL_SETTINGS: "TRANSPORT_CONTROL_SETTINGS",
};

export const TransportOrderSearchKeys = [
  "tenderID",
  "transportOrderID",
  "carrierName",
  "locationCode",
  "orderNumber"
];

export const LocationSearchKeys = [
  "locationCode",
  "locationNumber",
  "locationName",
  "address",
  "city",
  "state",
  "zipCode",
];

export const transportOrderStatusObj = {
  PENDING: "Pending Invite",
  PENDING_QUOTE: "Pending Quote",
  PENDING_AWARD: "Pending Award",
  PENDING_APPROVAL: "Pending Approval",
  CONFIRMED: "Confirmed",
  IN_PROCESS: "Order In Process",
  COMPLETED: "Order Completed",
  CANCELLED: "Order Cancelled",
};

export const mintProductTypeArr = ["CIRCULATED", "NUMI", "CTYD"];

export const businessDaysObj = {
  0: {
    max: 1020,
    min: 540,
    enabled: false,
  },
  1: {
    max: 1020,
    min: 540,
    enabled: false,
  },
  2: {
    max: 1020,
    min: 540,
    enabled: false,
  },
  3: {
    max: 1020,
    min: 540,
    enabled: false,
  },
  4: {
    max: 1020,
    min: 540,
    enabled: false,
  },
  5: {
    max: 1020,
    min: 540,
    enabled: false,
  },
  6: {
    max: 1020,
    min: 540,
    enabled: false,
  },
};
export const allCategoryLabel = {
  Coin: "COIN",
  Material: "MATERIAL",
  Equipment: "EQUIPMENT",
};
export const allCategoryData = [
  { label: "Coin", value: "COIN" },
  { label: "Material", value: "MATERIAL" },
  { label: "Equipment", value: "EQUIPMENT" },
  { label: "Cash", value: "CASH" },
];

export const allTypes = [
  { label: "CIRCULATED", value: "CIRCULATED", productCategory: "COIN", isChecked: false },
  { label: "COLLECTABLE", value: "COLLECTABLE", productCategory: "COIN", isChecked: false },
  { label: "CTYD", value: "CTYD", productCategory: "COIN", isChecked: false },
  { label: "MINT", value: "MINT", productCategory: "COIN", isChecked: false },
  { label: "NUMI", value: "NUMI", productCategory: "COIN", isChecked: false },
  { label: "MATERIAL", value: "MATERIAL", productCategory: "MATERIAL", isChecked: false },
  { label: "EQUIPMENT", value: "EQUIPMENT", productCategory: "EQUIPMENT", isChecked: false }
];

export const checkIsCoinOrCashCategory = (value) => {
  return value === "COIN" || value === "CASH";
};

export const productTypeArr = {
  "Mint Facility": "MINT FACILITIES",
  // Mint: "MINT",
  Circulated: "CIRCULATED",
  NUMI: "NUMI",
  CTYD: "CTYD",
  Material: "MATERIAL",
  Equipment: "EQUIPMENT",
};

export const allLocationTypes = [
  { label: "Employee", value: "Employee" },
  { label: "Inventory", value: "Inventory" },
  { label: "Satellite", value: "Satellite" },
  { label: "Standalone", value: "Standalone" },
  { label: "Standard", value: "Standard" },
  { label: "Transport", value: "Transport" },
];

export const createOrderTypes = [
  { label: "IN", value: "IN", },
  { label: "OUT", value: "OUT", isdisabled: false },
  { label: "IN/OUT", value: "IN/OUT", isdisabled: false },
  { label: "OUT/IN", value: "OUT/IN", isdisabled: false }
];

export const inventoryOrderStatusType = {
  PENDING_APPROVAL: "Pending Approval",
  PENDING_TRANSPORT_COORDINATION: "Pending Transport Coordination",
  PENDING_TRANSPORT_APPROVAL: "Pending Transport Approval",
  PENDING_RELEASE: "Pending Release",
  PENDING_RECEIVE: "Pending Receive",
  PENDING_VERIFY: "Pending Verify",
  PENDING_SORT: "Pending Sort",
  PENDING_MINE: "Pending Mine",
  PENDING_CONVERSION: "Pending Conversion",
  IN_CONVERSION: "In Conversion",
  PENDING_RECONCILE: "Pending Reconcile",
  RECONCILED: "Reconciled",
  PENDING_WEIGHT_TO_INVENTORY: "Pending Weight To Inventory",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled"
};

export const productionLineType = {
  MINING: "Mining",
  SORTING: "Sorting",
  WRAPPING: "Wrapping",
};
export const currentLineStatusProductionLine = {
  IDLE: "Idle",
  RUNNING: "Running",
  STOPPED: "Stopped",
};

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const productSearchKeys = ["productID", "name", "type", "count", "productCategory", "productState", "productType", "currency", "unitType"];
export const ControlIdActionsSearchKeys = ["productTrackingID", "eventType", "associatedProductTrackingIDs"];

export const permissionCodes = [
  "CREATE_LOCATION",
  "VIEW_LOCATION",
  "EDIT_LOCATION",
  "DELETE_LOCATION",
  // "CREATE_COIN_TYPES",//
  // "VIEW_COIN_TYPES",//
  // "EDIT_COIN_TYPES",//
  // "DELETE_COIN_TYPES",//
  // "CREATE_COIN_TYPE_PRODUCTS",//
  // "VIEW_COIN_TYPE_PRODUCTS",//
  // "EDIT_COIN_TYPE_PRODUCTS",//
  // "DELETE_COIN_TYPE_PRODUCTS",//
  "CREATE_CARRIERS",
  "VIEW_CARRIERS",
  "EDIT_CARRIERS",
  "DELETE_CARRIERS",
  // "CREATE_MATERIALS",
  // "VIEW_MATERIALS",
  // "EDIT_MATERIALS",
  // "DELETE_MATERIALS",
  "CREATE_COIN_UNIT_TYPES",
  "VIEW_COIN_UNIT_TYPES",
  "EDIT_COIN_UNIT_TYPES",
  "DELETE_COIN_UNIT_TYPES",
  "CREATE_USERS",
  "VIEW_USERS",
  "EDIT_USERS",
  "DELETE_USERS",
  "CREATE_EMPLOYEES",
  "VIEW_EMPLOYEES",
  "EDIT_EMPLOYEES",
  "DELETE_EMPLOYEES",
  "CREATE_EMPLOYEE_LOCATIONS",
  "VIEW_EMPLOYEE_LOCATIONS",
  "EDIT_EMPLOYEE_LOCATIONS",
  "DELETE_EMPLOYEE_LOCATIONS",
  "CREATE_REGIONS",
  "VIEW_REGIONS",
  "EDIT_REGIONS",
  "DELETE_REGIONS",
  "CREATE_DIVISIONS",
  "VIEW_DIVISIONS",
  "EDIT_DIVISIONS",
  "DELETE_DIVISIONS",
  "CREATE_ORGANIZATIONS",
  "VIEW_ORGANIZATIONS",
  "EDIT_ORGANIZATIONS",
  "DELETE_ORGANIZATIONS",
  "CREATE_ACCOUNTS",
  "VIEW_ACCOUNTS",
  "EDIT_ACCOUNTS",
  "DELETE_ACCOUNTS",
  "VIEW_INVENTORY",
  "CREATE_PRODUCTS",
  "VIEW_PRODUCTS",
  "EDIT_PRODUCTS",
  "DELETE_PRODUCTS",
  "CREATE_PRODUCT_TYPES",
  "VIEW_PRODUCT_TYPES",
  "EDIT_PRODUCT_TYPES",
  "DELETE_PRODUCT_TYPES",
  "CREATE_PRODUCT_UNIT_TYPES",
  "VIEW_PRODUCT_UNIT_TYPES",
  "EDIT_PRODUCT_UNIT_TYPES",
  "DELETE_PRODUCT_UNIT_TYPES",
  "EDIT_INVENTORY",
  "APPROVE_ORDER",
  "RECEIVE_ORDER",
  "RELEASE_ORDER",
  "CANCEL_ORDER",
  "VIEW_ORDER_STATUS",
  "ADMIN_RESET_PASSWORD",
  "VIEW_ORDER_PROCESSING",
  "CREATE_ORDER",
  "EDIT_TRANSPORT_ORDERS",
  "CHANGE_QUOTE_AND_BID_AMOUNT",
  "CREATE_CUSTOMER_CARRIERS",
  "VIEW_CUSTOMER_CARRIERS",
  "EDIT_CUSTOMER_CARRIERS",
  "DELETE_CUSTOMER_CARRIERS",
  "CREATE_DEPOSITORS",
  "VIEW_DEPOSITORS",
  "EDIT_DEPOSITORS",
  "DELETE_DEPOSITORS",
  "CREATE_CUSTOMERS",
  "VIEW_CUSTOMERS",
  "EDIT_CUSTOMERS",
  "DELETE_CUSTOMERS",
  "VIEW_ORDER_UPLOADED_FILES",
  "PRINT_BOL",
  "GENERATE_BOL",
  "DELETE_ORDER",
  "VIEW_CONTROL_ID_ACTIONS",
  "PRINT_WEIGHT_SHEET",
  "PRINT_WEIGHT_SHEET_ORDER_PROCESSING"
];

//add new model value
export const MAIN_LABEL = {
  LOCATIONS: "Locations",
  // COIN_TYPES: "Coin Types",//
  PRODUCT_TYPES: "Product Types",
  PRODUCTS: "Products",
  // COIN_TYPE_PRODUCTS: "Coin Type Products",//
  CARRIERS: "Carriers",
  // MATERIALS: "Materials",//
  COIN_UNIT_TYPES: "Coin Unit Types",//
  PRODUCT_UNIT_TYPES: "Product Unit Types",
  USERS: "Users",
  EMPLOYEES: "Employees",
  EMPLOYEE_LOCATIONS: "Employee Locations",
  REGIONS: "Regions",
  DIVISIONS: "Divisions",
  ORGANIZATIONS: "Organizations",
  ACCOUNTS: "Accounts",
  TRANSPORT_ORDERS: "Transport Orders",
  DOCUMNETS: "Documents",
  SHIPMENT_FILES: "Shipment Files",
  AUDIT_LOGS: "Audit Logs",
  PERMISSIONS: "Permissions",
  ROLES: "Roles",
  SETTINGS: "Settings",
  INVENTORY: "Inventory",
  CREATE: "Order Create",
  STATUS: "Order Status",
  PROCESSING: "Order Processing",   //add permission----orderProcessing
  CONVERSIONSTATUS: "Conversion Status",   //add permission----orderProcessing
  CONVERSIONPRODUCTION: "Conversion Production",   //add permission----orderProcessing
  CONSOLIDATE: "Consolidate",   //add permission----orderProcessing
  CUSTOMER_CARRIERS: "Customer Carriers",
  // DEPOSITORS: "Depositors",
  CUSTOMERS: "Customers",
  PRODUCTION_LINE: "Production Line",
  CONTROL_ID_HISTORY: "Control ID History",
  CONTROL_ID_MGMT: "Control ID Mgmt",
};

//add new model add permissons type here
export const MODEL_INNER_EMPLOYEE_LABEL = [
  "Employees",
  "Employee Locations",
  "Regions",
  "Divisions",
];
export const MODEL_INNER_INVENTORY_LABEL = [MAIN_LABEL?.ACCOUNTS, MAIN_LABEL?.ORGANIZATIONS];
export const ROLES_AND_PERMISSIONS_LABEL = ["Permissions", "Roles"];
export const ORDERS_LABEL = [MAIN_LABEL.CREATE, MAIN_LABEL.STATUS, MAIN_LABEL.PROCESSING]; //--add permission----orderProcessing
export const MODEL_LABEL = [
  "Locations",
  "Product Types",
  "Products",
  "Carriers",
  "Coin Unit Types",
  "Users",
  "Customers",
  "Customer Carriers",
  "Production Line",
  ...MODEL_INNER_EMPLOYEE_LABEL,
  ...MODEL_INNER_INVENTORY_LABEL,
];
//add new model TYPE
export const ALL_MODEL_LABEL = {
  MODELS: "Models",
  EMPLOYEES: "Employees",
  INVENTORY: "Inventory",
  ORDERS: "Orders",
  CONVERSION: "Conversion",
  ROLES_AND_PERMISSIONS: "Roles and Permissions",
  MODEL_INNER_ORDRES_MODELS: "Orders Models",

};

export const siteNameAndYear = `CoinWrap ${moment().year()}`;
export const orderType = {
  IN: "IN",
  OUT: "OUT"
};
export const processingOrderProductCount = {
  availableUnits: "availableUnits",
  approvedUnits: "approvedUnits",
  rejectedUnits: "rejectedUnits"
};

export const weightUnit = {
  KG: "kg",
  LBS: "lbs"
};

export const orderMethod = {
  HAND_CARRY: "HAND_CARRY",
  CUSTOMER_TRANSPORT: "CUSTOMER_TRANSPORT",
  CWI_TRANSPORT: "CWI_TRANSPORT"
};


export const controlIdActionLogAllEvent = {
  ADDED: "Added",
  CONTROLID_SPLITTED: "Control ID Splitted",
  CONTROLID_RENAMED: "Control ID Renamed",
  CONTROLID_COMBINED: "Control ID Combined",
  CONTROLID_UPDATED: "Control ID Updated",
  DELETE_CONTROL_ID: "Delete Control ID",
  ORDER_DELETED: "Order Deleted",
  PRODUCT_CHANGED: "Product Changed",
  REJECTED_RECEIVED: "Rejected Received",
  REJECTED_RELEASED: "Rejected Released",
  RELEASED: "Released",
  TRANSFER_UNITS: "Transfer Units",
  UPDATE_ORDER_RECEIVED: "Update Order Received",
  UPDATE_RECEIVED: "Update Received",
  UPDATE_RELEASED: "Update Released",
  PRODUCT_WRAPPED: "Product Wrapped",
  CONTROLID_CREATED_FROM_WRAP: "Control ID Created From Wrap",
};

export const productionStatusCountData = {
  WRAP: "Wrap",
  IN_PROCESS_WRAP: "In Process Wrap",
  COMPLETED_WRAP: "Completed Wrap",
  SORT_VERIDY_ROLL_CRACK: "Sort/Verify/Roll Crack",
  IN_PROCESS_SORT_VERIFY_ROLL_CRACK: "In Process Sort/Verify/Roll Crack",
  COMPLETED_SORT_VERIFY_ROLL_CRACK: "Completed Sort/Verify/Roll Crack",
  MINE: "Mine",
  IN_PROCESS_MINE: "In Process Mine",
  COMPLETED_MINE: "Completed Mine",
  VERIFY: "Verify",
  AUDIT: "Audit",
  NUMI: "NUMI",
};

export const allModelLabelData = {
  "Control ID Actions": "Control ID History",
  "Consolidate Control ID": "Control ID Mgmt"
};

export const transportOrdersRoute = "/transport-orders";
export const auditLogsRoute = "/audit-logs";
export const shipmentFilesRoute = "/shipment-files";
export const documentsRoute = "/documents";
export const locationsRoute = "/models/locations";
export const mintLocationsRoute = "/models/locations";//##deleteMintLocation
export const coinTypesRoute = "/models/productType";
export const productsRoute = "/models/products";
export const coinTypesProductsRoute = "/models/coinTypeProducts";
export const carriersRoute = "/models/carrier";
export const nonCoinMaterialsRoute = "/models/nonCoinMaterials";
export const unitTypeRoute = "/models/productUnitTypes";
export const usersRoute = "/models/users";
export const divisionsRoute = "/models/divisions";
export const regionsRoute = "/models/regions";
export const employeesRoute = "/models/employees";
export const employeeLocationsRoute = "/models/employeeLocation";
export const organizationsRoute = "/models/organizations";
export const customerCarriersRoute = "/models/customercarriers";
export const depositorsRoute = "/models/depositors";
export const customersRoute = "/models/customers";
export const accountsRoute = "/models/accounts";
export const permissionsRoute = "/roles_permissions/permissions";
export const rolesRoute = "/roles_permissions/roles";
export const InventoryRoute = "/inventory";
export const CreateRoute = "/orders/create";
export const StatusRoute = "/orders/status";
export const ProcessingRoute = "/orders/processing";
export const ledgerRoute = "/ledger";
export const controlIDActionRoute = "/control-id-history";
// export const conversionStatusRoutes = "/conversion/status";
// export const conversionProductionRoutes = "/conversion/production";
export const consolidateRoutes = "/control-id-mgmt";
export const productionLineRoute = "/models/production-line";
export const productionRoute = "/production";
export const wrapRoute = "/production/wrap";
