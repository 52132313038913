import { API } from "aws-amplify";
import { getOrdersHistoryQuery } from "../../../../Queries/dashboardQuery";

export const getDashBoardData = ({ startDate, endDate, transportOrder, inventoryOrder, filter }) => {
   return API.graphql({
      query: getOrdersHistoryQuery,
      variables: {
         startDate: startDate,
         endDate: endDate,
         transportOrder,
         inventoryOrder,
         filter
      }
   });
};
