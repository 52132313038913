import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allProducts: [],
    selectedProducts: null,
    isLoading: true,
    searchValue: "",
    searchBy: [],
    page: 0,
    dataCount: 0,
    offset: 0,
    dataPerPage: 15,
    sortField: "name",
    sortType: "ASC",
    productTypeList: [],
    actionTrigger: false,
};

export const ProductsSlice = createSlice({
    name: "products",
    initialState: initialState,
    reducers: {
        setProducts: (state, action) => {
            state.allProducts = action.payload;
        },
        productFetched: (state, action) => {
            state.selectedProducts = action.payload;
        },
        removeSelectedProduct: (state) => {
            state.selectedProducts = null;
        },
        setProductTypeListList: (state, action) => {
            state.productTypeList = action.payload;
        },
        setSearchValue: (state, action) => {
            state.searchValue = { ...state.searchValue, ...action.payload };
        },
        setSearchBy: (state, action) => {
            state.searchBy = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setSortField: (state, action) => {
            state.sortField = action.payload;
        },
        setSortType: (state, action) => {
            state.sortType = action.payload;
        },
        setActionTrigger: (state, action) => {
            state.actionTrigger = action.payload;
        },
        setPageConfigData: (state, action) => {
            switch (action.payload.type) {
                case "SET_PAGE":
                    state.page = action.payload.data;
                    break;
                case "SET_DATA_COUNT":
                    state.dataCount = action.payload.data;
                    break;
                case "SET_IS_LOADING":
                    state.isLoading = action.payload.data;
                    break;
                case "SET_DATA_PER_PAGE":
                    state.dataPerPage = action.payload.data;
                    break;
                case "SET_OFFSET":
                    state.offset = action.payload.data;
                    break;
                default:
                    break;
            }
        },
    },
});
