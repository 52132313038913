import { Paper, Skeleton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import DateRangePickerCustom from '../../../../app/modules/coinWrap/Helpers/DateRangePicker/DateRangePickerCustom';

const DashboardOrderStatusCount = ({
   startDate,
   endDate,
   setTimeRange,
   orderStatusCountData,
   isLoading,
   onClickCard
}) => {

   const label =
      startDate && endDate
         ? startDate.format("MMMM D, YYYY") +
         " - " +
         endDate.format("MMMM D, YYYY")
         : "-";


   const handleCallback = (start, end) => {
      setTimeRange({ startDate: start, endDate: end });
   };

   const handleCancel = (event, data) => {
      data.setStartDate(moment().startOf("day"));
      data.setEndDate(moment().endOf("day"));
      setTimeRange({
         startDate: moment().startOf("day"),
         endDate: moment().endOf("day"),
      });
   };

   return (
      <>

         <div className='dashboard_date_picker mb-3'>
            <div style={{
               fontSize: "1.5rem",
               fontWeight: 550,
               alignContent: "end"
            }}>
               Orders
            </div>
            <div className='ml-4' >
               <DateRangePickerCustom
                  startDate={startDate?.toDate()}
                  endDate={endDate?.toDate()}
                  handleCallback={handleCallback}
                  handleCancel={handleCancel}
                  cancelLabel="Reset To Today"
                  cancelButtonClasses="btn-outline-danger"
                  render={
                     <div
                        id="reportrange"
                        style={{
                           background: "#fff",
                           cursor: "pointer",
                           padding: "8px 10px",
                           border: "1px solid #ccc",
                           width: "100%",
                           borderRadius: "4px",
                        }}
                     >
                        <i className="fa fa-calendar"></i>&nbsp;
                        <span>{label}</span> <i className="fa fa-caret-down"></i>
                     </div>
                  }
               />
            </div>
         </div>
         {!isLoading ?
            <>
               {orderStatusCountData?.length > 0 && orderStatusCountData?.map((v, i) => {
                  return (
                     <div className='order_Action_Count' key={i} onClick={onClickCard}>
                        <Paper
                           sx={{
                              padding: "1.5rem 1.5rem",
                              borderRadius: "0.2rem",
                              height: "100%",
                              border: "none",
                              cursor: 'pointer'
                           }}
                        >
                           <Stack justifyContent={"space-between"} style={{ height: "100%" }}>
                              <Typography
                                 sx={{
                                    color: "#a5a5a5",
                                    textAlign: "start",
                                    fontSize: "1.2rem"
                                 }}
                              >
                                 {v?.status}
                              </Typography>

                              <Stack className='mb-2' direction={"row"} spacing={"2rem"} style={{ fontSize: "1.8rem", fontWeight: 550, color: "rgb(80 75 75 / 87%)" }}>
                                 <div>
                                    <img src={v?.icon} alt="React Logo" height={25} />
                                 </div>
                                 <div className='mr-10'>{v?.count}</div>
                              </Stack>
                           </Stack>
                        </Paper>
                     </div >
                  );
               })}
            </>
            :
            <>
               {orderStatusCountData?.map((v, i) => {
                  return (
                     <div className='order_Action_Count' key={i}>
                        <Paper
                           sx={{
                              padding: "2.3rem 0.8rem",
                              borderRadius: "0.2rem",
                              height: "100%",
                              border: "none"
                           }}
                        >
                           <Stack justifyContent={"space-between"} style={{ height: "100%" }}>
                              <Skeleton animation="wave" width={"105px"} height={"18px"} />
                              <Stack direction={"row"} spacing={"1rem"} style={{ fontSize: "1.8rem", fontWeight: 550, color: "rgb(80 75 75 / 87%)" }}>
                                 <Skeleton animation="wave" width={"25px"} />
                                 <Skeleton animation="wave" width={"55px"} />
                              </Stack>
                           </Stack>
                        </Paper>
                     </div >
                  );
               })}
            </>
         }
      </>
   );
};

export default DashboardOrderStatusCount;
