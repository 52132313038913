import { Auth } from "aws-amplify";
import { actions } from "../app/modules/Auth/_redux/authRedux";
import { generalSlice } from "../app/modules/coinWrap/_redux/general/generalSlice";
import history from "./history";

const refreshUserSession = (store) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        var idTokenExpire = session.getIdToken().getExpiration();
        var refreshToken = session.getRefreshToken();
        var currentTimeSeconds = Math.round(+new Date() / 1000);

        if ((idTokenExpire - ((120 * 60))) < currentTimeSeconds) {
          Auth.currentAuthenticatedUser()
            .then((res) => {
              res.refreshSession(refreshToken, (err, data) => {
                if (err) {
                  console.error(err);
                  history.push("/logout");
                  window.location.reload();
                  reject();
                } else {
                  store.dispatch(
                    actions.refreshLogin(data?.accessToken?.jwtToken)
                  );
                }
              });
            })
            .catch((error) => {
              console.error(error);
              reject();
            });
        } else {
          let oldToken = store.getState("auth").auth.authToken;
          if (session.accessToken.jwtToken !== oldToken) {
            store.dispatch(actions.refreshLogin(session.accessToken.jwtToken));
          }
        }
      })
      .catch((error) => {
        console.error(error);
        history.push("/logout");
        window.location.reload();
        reject();
      });
  });
};

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `${authToken}`;
      }

      return config;
    },
    (err) => {
      console.error(err.response);
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      refreshUserSession(store);
      return response;
    },
    (error) => {
      let errorMessage = "",
        api = "";
      const errObject = error.response;
      const errRequest = errObject?.request;
      if (
        (error.response?.status === 401 ||
          error.response?.statusText === "Unauthorized") &&
        window.location.pathname !== "/auth/login"
      ) {
        refreshUserSession(store).catch(() => {
          history.push("/logout");
          window.location.reload();
        });
      }

      if (errRequest) {
        const endPoint = errRequest.responseURL?.split("api");

        if (errRequest.statusText) errorMessage += " " + errRequest.statusText;

        if (errRequest.status)
          errorMessage += "(Error Code:" + errRequest.status + ")";

        if (errObject.config.method)
          api +=
            errObject.config.method?.toUpperCase() +
            " api" +
            endPoint[1] +
            " failed";

        store.dispatch(
          generalSlice.actions.pushNewAlert({
            show: true,
            heading: "Error",
            errMessage: errorMessage,
            errDescription: errObject?.data?.error,
            message: api,
            type: "danger",
          })
        );
      }

      return Promise.reject(error);
    }
  );
}
