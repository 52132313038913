import { Dialog, DialogActions, DialogContent, useTheme } from "@mui/material";
import { Storage } from "aws-amplify";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import DialogCloseTitle from "../../../Helpers/Dialog/DialogTitle";
import BootstrapButton from "../../../Helpers/UI/Button/BootstrapButton";
import {
  createS3UploadUrlCrud,
  updateCarrierTenderStatusCrud,
} from "../../../_redux/TransportOrders/TransportOrdersCrud";
import { generalSlice } from "../../../_redux/general/generalSlice";
import CustomDraggableDialog from "../../../Helpers/CustomDraggableDialog/CustomDraggableDialog";

export const status = {
  IN_PROCESS: "Order In Process",
  COMPLETED: "Order Completed",
  CANCELLED: "Order Cancelled",
};

const schema = yup.object({
  status: yup
    .string()
    .trim()
    .required("Tender status is required"),
  comment: yup.string().trim(),
});

Storage.configure({ level: "public" });

const UpdateTenderStatusModal = ({
  setShowUpdateTenderStatusModal,
  showUpdateTenderStatusModal,
  transportOrderLinkData,
  transportOrderStatus,
  inviteCode,
  getData,
  stopData,
  setStopData,
  modalData,
  carrierDataName,
  isDisableDrag,
  setIsDisableDrag
}) => {
  // const tenderID =
  //   transportOrderLinkData && transportOrderLinkData?.tenderID;

  // const transportOrderID =
  //   transportOrderLinkData && transportOrderLinkData?.transportOrderID;
  const { actions: generalActions } = generalSlice;
  const dispatch = useDispatch();
  const theme = useTheme();

  const init = {
    status: status[transportOrderLinkData?.transportOrderStatus] || "",
    comment: transportOrderLinkData?.comments || "",
  };
  const [initValue, setInitValue] = useState(init);
  useEffect(() => {
    for (let i = 0; i < stopData.length; i++) {
      setInitValue((prev) => {
        const tempData = { ...prev };
        const property = stopData[i]?.stopType;
        if (property) {
          tempData[property] = "";
          tempData[`${property}files`] = {};
        }
        return tempData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopData]);


  const handleClose = (resetForm) => {
    setShowUpdateTenderStatusModal(false);
    setIsDisableDrag(false);
    resetForm();
  };

  let arr = [];

  Object.entries(status).forEach(([k, v]) => {
    arr.push({ key: k, value: v });
  });

  const statusKeyToValueFunc = (data) => {
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element?.value === data) {
        let string = data;
        let status = string.split(data).join(element?.key);
        return status;
      }
    }
  };

  const handleFileUpload = async (values, data) => {
    let list = [...stopData];
    const uploadTimes = list.filter((item) => item.files);
    let array = [];
    let resultArr = [];
    let result;

    let fileUploadData = [];
    for (let index = 0; index < uploadTimes.length; index++) {
      let exe = uploadTimes[index].file.split(".").pop();
      array[
        index
      ] = `public/bol_or_gbl/${modalData}_${uploadTimes[index]["stopType"]}.${exe}`;

      fileUploadData.push({
        fileName: uploadTimes[index]?.LocationTransport?.pickupOnlyLocation
          ? "GBL"
          : "BOL",
        filePath: `bol_or_gbl/${uploadTimes[index]["fileName"]}.${exe}`,
        fileComment: values.comment,
      });
    }

    createS3UploadUrlCrud(array).then(async (res) => {
      for (let index = 0; index < uploadTimes.length; index++) {
        result = await fetch(res?.data?.createS3UploadUrl[index]?.uploadUrl, {
          method: "PUT",
          body: values[`${uploadTimes[index]["stopType"]}files`],
        });

        resultArr[index] = result.ok;

        if (
          resultArr.every((itm) => itm === true) &&
          uploadTimes.length - 1 === index
        ) {
          updateCarrierTenderStatusCrud({ ...data, TransportOrderFileInput: fileUploadData }, true)
            .then((res) => {
              if (res?.data?.updateCarrierTenderStatus?.status === false) {
                dispatch(
                  generalActions.pushNewAlert({
                    show: true,
                    heading: "Error",
                    message: "Can't Update Order Status api",
                    type: "danger",
                  })
                );
              } else {
                dispatch(
                  generalActions.pushNewAlert({
                    show: true,
                    heading: "Success",
                    message: "Order Status Updated Successfully",
                    type: "success",
                  })
                );
                getData();
              }
            })
            .catch((error) => {
              console.error(error);
              if (error) {
                dispatch(
                  generalActions.pushNewAlert({
                    show: true,
                    heading: "Error",
                    message: "Can't Update Order Status",
                    type: "danger",
                  })
                );
              }
            }).finally(() => {
              setShowUpdateTenderStatusModal(false);
            });
        }
        if (resultArr.includes(false) && uploadTimes.length - 1 === index) {
          setShowUpdateTenderStatusModal(false);
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't Upload File",
              type: "danger",
            })
          );
        }
      }
    });
  };

  const updateTenderStatusData = (data, values) => {
    if (data.status === "COMPLETED") {
      handleFileUpload(values, data);
    } else {
      return updateCarrierTenderStatusCrud(data)
        .then((res) => {
          if (res?.data?.updateCarrierTenderStatus?.status === false) {
            dispatch(
              generalActions.pushNewAlert({
                show: true,
                heading: "Error",
                message: "Can't Update Order Status",
                type: "danger",
              })
            );
          } else {
            dispatch(
              generalActions.pushNewAlert({
                show: true,
                heading: "Success",
                message: "Order Status Updated Successfully",
                type: "success",
              })
            );
            getData();
          }
        })
        .catch((error) => {
          console.error(error);
          setShowUpdateTenderStatusModal(false);
          if (error) {
            dispatch(
              generalActions.pushNewAlert({
                show: true,
                heading: "Error",
                message: "Can't Update Order Status",
                type: "danger",
              })
            );
          }
        });
    }
  };

  // const allEqual = (arr) =>
  //   arr.find((v) => {
  //     return v === true;
  //   });

  // const loop = () => {
  //   let list = [...stopData];

  //   if (
  //     allEqual(
  //       list?.map((item) => item.file === "" || item.file === undefined)
  //     ) === true
  //   ) {
  //     return true;
  //   }
  // };

  const uploadFile = (e, index, stopType) => {
    setStopData((prev) => {
      const tempData = JSON.parse(JSON.stringify(prev));
      tempData[index].file = e.target.files[0]?.name;
      tempData[index].files = e.target.files[0];
      tempData[index].fileName = `${modalData}_${stopType}`;
      return tempData;
    });
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initValue}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        // setSubmitting(true);
        updateTenderStatusData(
          {
            inviteCode: inviteCode,
            status: statusKeyToValueFunc(values?.status),
            comment: values.comment,
          },
          values
        )
          .then(() => {
            handleClose(resetForm);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        isSubmitting,
        resetForm,
        setFieldValue,
        isValid,
        errors,
        touched,
      }) => (
        <CustomDraggableDialog show={showUpdateTenderStatusModal} onHide={() => handleClose(resetForm)}
          isDraggable={!isDisableDrag}
          draggableChildren={
            <Dialog
              open={showUpdateTenderStatusModal}
              scroll={"paper"}
              maxWidth="sm"
              fullWidth={true}
              hideBackdrop={true}
            >
              <Form onSubmit={handleSubmit} noValidate>
                <DialogCloseTitle
                  onClose={() => {
                    handleClose(resetForm);
                  }}
                  isCloseButtonDisabled={isSubmitting}
                >
                  <div
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Update Order Status
                  </div>
                </DialogCloseTitle>
                <DialogContent dividers>
                  <Form.Row>
                    <Col sm={12} md={6}>
                      <Form.Group className="required">
                        <Form.Label style={{ fontWeight: 600 }}>
                          Order Status
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          disabled={isSubmitting}
                          isInvalid={Boolean(errors.status)}
                        >
                          <option
                            value={""}
                            key={""}
                            style={{ fontStyle: "italic" }}
                          >
                            Select order status
                          </option>
                          {Object.entries(status).map(([k, v]) => (
                            <option
                              value={v}
                              key={k}
                              disabled={
                                transportOrderStatus === "IN_PROCESS" &&
                                v === status.IN_PROCESS
                              }
                            >
                              {v}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.status}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  {values?.status === "Order Completed" &&
                    stopData?.map((data, i) => (
                      <Form.Row key={i}>
                        <Col sm={12} md={12}>
                          <Form.Group className={i === 0 ? "required" : null}>
                            <Form.Label style={{ fontWeight: 600 }}>
                              {data?.LocationTransport?.pickupOnlyLocation
                                ? `GBL Upload for ${data?.stopType}`
                                : `BOL Upload for ${data?.stopType}`}
                            </Form.Label>

                            <Form.File
                              key={i}
                              type="file"
                              id="custom-file"
                              name={data?.stopType}
                              disabled={isSubmitting}
                              label={
                                values[`${data.stopType}`]
                                  ? values[`${data.stopType}`]
                                  : "Select file"
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  data?.stopType,
                                  e?.currentTarget?.files[0]?.name
                                );
                                setFieldValue(
                                  `${data?.stopType}files`,
                                  e?.target?.files?.[0]
                                );
                                uploadFile(e, i, data?.stopType, values);
                              }}
                              custom
                            />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                    ))}
                  <Form.Row>
                    <Col sm={12} md={12}>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 600 }}>Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          type="text"
                          name="comment"
                          value={values.comment || ""}
                          onChange={handleChange}
                          disabled={isSubmitting}
                          isInvalid={errors.comment}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.comment}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  {(values.status === "Order Completed" || values.status === "Order In Process") &&
                    !moment().isSameOrAfter(stopData?.[0]?.shipmentDate) &&
                    <div style={{ marginTop: "3px" }}>
                      <span
                        style={{
                          color: "#dc3545",
                          fontWeight: "400",
                          fontSize: "1rem",
                        }}
                      >
                        Can't change order status to <strong>{values.status}</strong> because pickup date is in the future
                      </span>
                    </div>}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      handleClose(resetForm);
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <BootstrapButton
                    variant="success"
                    type="submit"
                    label="Update"
                    labelWhenSubmitting="Updating"
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit}
                    disabled={
                      values.status === "CONFIRMED" ||
                      errors.status ||
                      (values.status === "Order Completed" &&
                        stopData?.[0]?.fileName === undefined) ||
                      (!moment().isSameOrAfter(stopData?.[0]?.shipmentDate) && (values.status === "Order Completed" || values.status === "Order In Process"))
                    }
                  />
                </DialogActions>
              </Form>
            </Dialog>
          } />
      )}
    </Formik>
  );
};

export default UpdateTenderStatusModal;
