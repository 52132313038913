import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAccounts: [],
  selectedAccount: null,
  isLoading: true,
  searchValue: "",
  searchBy: [],
  page: 0,
  dataCount: 0,
  offset: 0,
  dataPerPage: 15,
  sortField: "name",
  sortType: "ASC",
  actionTrigger: false,
};

export const AccountsSlice = createSlice({
  name: "accounts",
  initialState: initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.allAccounts = action.payload;
    },
    accountFetched: (state, action) => {
      state.selectedAccount = action.payload;
    },
    removeSelectedAccount: (state) => {
      state.selectedAccount = null;
    },
    setSearchValue: (state, action) => {
      state.searchValue = { ...state.searchValue, ...action.payload };
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSortType: (state, action) => {
      state.sortType = action.payload;
    },
    setActionTrigger: (state, action) => {
      state.actionTrigger = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        case "SET_OFFSET":
          state.offset = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
