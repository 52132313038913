import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allRolesAndPermissions: [],
  selectedRolesAndPermissions: null,
  isLoading: true,
  userDetails: undefined
};

export const RolesAndPermissionSlice = createSlice({
  name: "rolesAndPermissions",
  initialState: initialState,
  reducers: {
    setRolesAndPermissions: (state, action) => {
      state.allRolesAndPermissions = action.payload;
    },
    rolesAndPermissionsFetched: (state, action) => {
      state.selectedRolesAndPermissions = action.payload;
    },
    removeSelectedRolesAndPermissions: (state) => {
      state.selectedRolesAndPermissions = null;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    }
  },
});
