import { Storage } from "aws-amplify";
import { isArray, isEqual, isPlainObject, reduce } from "lodash";
import moment from "moment/moment";
import { useEffect, useRef } from "react";

export function isIPAddress(ipaddress) {
  const ipAdd = ipaddress?.split(":")[0] || "";
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipAdd
    )
  ) {
    return true;
  }
  return false;
}

export const getGlobalSearchFilteredData = (allData = [], filter) => {
  if (filter)
    return allData.filter((data) =>
      filter.keys.some((key) => {
        if (data[key]) {
          if (typeof data[key] === "string")
            return data[key]
              ?.toUpperCase()
              .includes(filter.keyword.toUpperCase());
          else if (Array.isArray(data[key])) {
            return data[key].some((d) =>
              d.toUpperCase().includes(filter.keyword.toUpperCase())
            );
          }
        }

        return false;
      })
    );

  return allData;
};

export function getTimeFromMins(mins) {
  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError(
      "Valid input should be greater than or equal to 0 and less than 1440."
    );
  }
  var h = (mins / 60) | 0,
    m = mins % 60 | 0;
  return moment
    .utc()
    .hours(h)
    .minutes(m)
    .format("hh:mm A");
}

export function cleanObject(obj) {
  const cloneOb = JSON.parse(JSON.stringify(obj || {}));
  for (let propName in cloneOb) {
    if (
      cloneOb[propName] === null ||
      cloneOb[propName] === undefined ||
      cloneOb[propName] === "" ||
      cloneOb[propName] === "|" ||
      (typeof cloneOb[propName] == "object" &&
        Object.keys(cloneOb[propName]).length === 0)
    ) {
      delete cloneOb[propName];
    }

    if (typeof cloneOb[propName] == "string" && cloneOb[propName] !== "") {
      cloneOb[propName] = cloneOb[propName].trim();
    }
  }
  return cloneOb || {};
}

export const getLodashDiff = (obj1, obj2) => {
  const obj1Copy = JSON.parse(JSON.stringify(obj1 || {}));
  return reduce(
    obj1Copy,
    function (result, value, key) {
      if (isPlainObject(value)) {
        result[key] = getLodashDiff(value, obj2[key]);
      } else if (
        isArray(value) &&
        !isEqual([...value].sort(), [...obj2[key]].sort())
      ) {
        result[key] = value;
      } else if (!isArray(value) && !isEqual(value, obj2[key])) {
        if (value === "") {
          result[key] = null;
        } else result[key] = value;
      }
      return result;
    },
    {}
  );
};

export const getCurrentDateTime = (
  dateOb,
  withSeconds = false,
  withTime = true
) => {
  if (withTime)
    return `${(dateOb.getMonth() + 1).toString().padStart(2, "0")}/${dateOb
      .getDate()
      .toString()
      .padStart(2, "0")}/${dateOb.getFullYear()}  ${formatAMPM(
        dateOb,
        withSeconds
      )}`;
  else
    return `${(dateOb.getMonth() + 1).toString().padStart(2, "0")}/${dateOb
      .getDate()
      .toString()
      .padStart(2, "0")}/${dateOb.getFullYear()}`;
};

export const getCurrentDateTimeNew = (
  dateOb,
  withSeconds = false,
  withTime = true
) => {
  if (withTime)
    return `${(dateOb.getMonth() + 1).toString().padStart(2, "0")}/${dateOb
      .getDate()
      .toString()
      .padStart(2, "0")}/${dateOb.getFullYear()}  ${formatAMPM(
        dateOb,
        withSeconds
      )}`;
  else
    return `${(dateOb.getMonth() + 1).toString().padStart(2, "0")}/${dateOb
      .getDate()
      .toString()
      .padStart(2, "0")}/${dateOb.getFullYear()}`;
};

export const formatAMPM = (dateOb, withSeconds) => {
  let hours = dateOb.getHours();
  let minutes = dateOb.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes.toString().padStart(2, "0");

  if (withSeconds) {
    const seconds = dateOb
      .getSeconds()
      .toString()
      .padStart(2, "0");
    return hours + ":" + minutes + ":" + seconds + " " + ampm;
  }

  return hours + ":" + minutes + " " + ampm;
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function textToClipboard(text) {
  let dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
}

export const downloadFileFromS3 = async (fileKey, fileName, dispatch, generalActions) => {
  try {
    const result = await Storage.get(`${fileKey}`, {
      download: true,
    });
    downloadBlob(result.Body, fileName);
  } catch (error) {
    let ErrorMsg = error === "NoSuchKey: NoSuchKey" ? "No File Exist" : error;
    dispatch && dispatch(
      generalActions.pushNewAlert({
        show: true,
        heading: "Error",
        message: `Can't Download File: ${ErrorMsg}`,
        type: "danger",
      })
    );
    console.error(error);
  }
};

export function dollarSignValue(value) {
  if (isNaN(value)) {
    return new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(0);;
  }
  return new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(Number(value));
}

export function formattedDateTime(date) {
  return moment(new Date(date)).format("MM/DD/YYYY HH:mm:ss");
}
export function formattedDate(date) {
  return moment(new Date(date)).format("MM/DD/YYYY");
}

const LBS_TO_KG_CONVERSIONVALUE = 0.453592;

export function kGtoLBSConversion(value) {
  if (value) return (Number(value) * LBS_TO_KG_CONVERSIONVALUE);
}
export function LBStoKGConversion(value) {
  if (value) return (Number(value) / LBS_TO_KG_CONVERSIONVALUE);
}

export function decimalToFixedValue(value, digit = 3) {
  return Number(Number(value).toFixed(digit));
}

export const convertValues = (value, conversionFunction) => {
  if (value && value !== "") {
    return {
      convertValue: isNaN(conversionFunction(value?.convertValue)) ? "" : conversionFunction(value?.convertValue),
      displayValue: isNaN(Number(Number(conversionFunction(value?.convertValue)).toFixed(3))) ? "" : Number(Number(conversionFunction(value?.convertValue)).toFixed(3))
    };
  }
  else {
    return {
      convertValue: "",
      displayValue: ""
    };
  }
};

export const dateAndTimeWithTimeZone = (date) => {
  return moment(date).format('yyyy-MM-DDTHH:mm:ssZ');
}

export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = e => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          // behavior: "smooth"
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

export const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const blobToPrintPdf = function (eventBlob, isTabletMode) {
  var blob = new Blob([eventBlob], { type: 'application/pdf' });
  var blobURL = URL.createObjectURL(blob);
  const ua = navigator.userAgent || navigator.vendor || window.opera;

  if (-1 !== ua.toLowerCase().indexOf('crios') || -1 !== ua.toLowerCase().indexOf('fxios') ||
    /mobile/i.test(ua) || /tablet/i.test(ua) || /iPad/i.test(ua) ||
    (/safari/.test(ua) && !/chrome/.test(ua) && !/crios/.test(ua)) || isTabletMode
  ) {
    window.open(blobURL, '_blank');
  } else {
  let iframe = document.createElement('iframe');
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = blobURL;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
  }
};

export const handleBlobOpenInNewTab = (eventBlob) => {
  var blob = new Blob([eventBlob], { type: 'application/pdf' });
  var blobURL = URL.createObjectURL(blob);
  window.open(blobURL, '_blank');

};
