import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   allLedgerData: [],
   isLoading: true,
   searchValue: "",
   page: 0,
   dataCount: 0,
   offset: 0,
   dataPerPage: 15,
   sortField: "orderID",
   sortType: "ASC",
   startDate: null,
   endDate: null,
   locationFilterData: [],
   organizationFilterData: [],
   accountFilterData: [],
   productFilterData: [],
   orderFilterData: [],
   customerFilterData: [],
   actionTrigger: false,
};

export const LedgerSlice = createSlice({
   name: "ledger",
   initialState: initialState,
   reducers: {
      setLedgerData: (state, action) => {
         state.allLedgerData = action.payload;
      },
      setSearchValue: (state, action) => {
         state.searchValue = { ...state.searchValue, ...action.payload };
      },
      setLoading: (state, action) => {
         state.isLoading = action.payload;
      },
      setSortField: (state, action) => {
         state.sortField = action.payload;
      },
      setSortType: (state, action) => {
         state.sortType = action.payload;
      },
      setTimeRange: (state, action) => {
         state.startDate = action.payload.startDate;
         state.endDate = action.payload.endDate;
      },
      setActionTrigger: (state, action) => {
         state.actionTrigger = action.payload;
      },
      setLocationFilterData: (state, action) => {
         state.locationFilterData = action.payload;
      },
      setOrganizationFilterData: (state, action) => {
         state.organizationFilterData = action.payload;
      },
      setAccountFilterData: (state, action) => {
         state.accountFilterData = action.payload;
      },
      setProductFilterData: (state, action) => {
         state.productFilterData = action.payload;
      },
      setOrderFilterData: (state, action) => {
         state.orderFilterData = action.payload;
      },
      setCustomerFilterData: (state, action) => {
         state.customerFilterData = action.payload;
      },
      setPageConfigData: (state, action) => {
         switch (action.payload.type) {
            case "SET_PAGE":
               state.page = action.payload.data;
               break;
            case "SET_DATA_COUNT":
               state.dataCount = action.payload.data;
               break;
            case "SET_IS_LOADING":
               state.isLoading = action.payload.data;
               break;
            case "SET_DATA_PER_PAGE":
               state.dataPerPage = action.payload.data;
               break;
            case "SET_OFFSET":
               state.offset = action.payload.data;
               break;
            default:
               break;
         }
      },
   },
});
