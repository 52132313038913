import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  allAuditLogs: [],
  selectedAuditLogs: null,
  isLoading: true,
  page: 0,
  dataCount: 0,
  dataPerPage: 5,
  offset: 0,
  searchValue: "",
  searchBy: [],
  apiActionsList: [],
  filter: [],
  startDate: moment().startOf("day"),
  endDate: moment().endOf("day"),
};

export const TenderIDAuditLogsSlice = createSlice({
  name: "tenderIDAuditLogs",
  initialState: initialState,
  reducers: {
    setAuditLogs: (state, action) => {
      state.allAuditLogs = action.payload;
    },
    auditLogFetched: (state, action) => {
      state.selectedAuditLogs = action.payload;
    },
    setAPIOptions: (state, action) => {
      state.apiActionsList = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = { ...state.searchValue, ...action.payload };
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
    setTimeRange: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        case "SET_OFFSET":
          state.offset = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
