import { IconButton, Tooltip } from "@mui/material";
import React from 'react';


const CustomTooltipButton = ({ toolTipTitle,
   disabled = false,
   hidden = false,
   handleClick,
   icon,
   backgroundColor = "#5bc0de",
   disabledColor,
   iconButtonStyle
}) => {
   return (
      <Tooltip
         disableInteractive={true}
         arrow
         title={!disabled && toolTipTitle}
         placement="bottom"
      >
         <span>
            <IconButton
               onClick={() => handleClick()}
               variant="contained"
               disabled={disabled}
               hidden={hidden}
               sx={{
                  padding: "5px",
                  margin: "0.2rem",
                  borderRadius: "5px",
                  backgroundColor: { backgroundColor },
                  ":disabled": {
                     backgroundColor: disabledColor ? disabledColor : '#dddddd'
                  },
                  "&:hover": {
                     backgroundColor: { backgroundColor },
                  },
                  "& g": {
                     fill: "#fff",
                  },
                  ...iconButtonStyle
               }}
            >
               {icon}
            </IconButton>
         </span>
      </Tooltip>
   );
};

export default CustomTooltipButton;
