import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export function QuickUser() {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  return (
    <div
      id="kt_quick_user"
      // className="offcanvas offcanvas-right offcanvas p-10"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          User
          {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
        </h3>
        {/* <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a> */}
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="d-flex flex-column">
            <a
              href="/"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user ? user?.username : ""}
            </a>

            <div className="navi mt-2">
              <a href="/" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <i className="fas fa-envelope svg-icon-lg svg-icon-primary"></i>
                    <span className="svg-icon-lg svg-icon-primary"></span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user ? user?.username : ""}
                  </span>
                </span>
              </a>
            </div>

            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
