import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allInventory: [],
    selectedInventory: null,
    allSectionData: [],
    // selectedInventory: null,
    allLastTableData: [],
    // selectedInventory: null,
    isLoading: true,
    searchValue: "",
    searchBy: [],
    page: 0,
    dataCount: 0,
    offset: 0,
    dataPerPage: 15,
    sortField: "name", //##change
    sortType: "ASC",
    startDate: null,
    endDate: null,
    sectionLabel:"locationID",
    locationFilterData:[],
    organizationFilterData:[],
    accountFilterData:[],
    productFilterData: [],
    lastTableActionTrigger: false
};

export const InventorySlice = createSlice({
    name: "inventory",
    initialState: initialState,
    reducers: {
        setInventory: (state, action) => {
            state.allInventory = action.payload;
        },
        inventoryFetched: (state, action) => {
            state.selectedInventory = action.payload;
        },
        removeSelectedInventory: (state) => {
            state.selectedInventory = null;
        },
        setSectionData: (state, action) => {
            state.allSectionData = action.payload;
        },
        setLastTableData: (state, action) => {
            state.allLastTableData = action.payload;
        },
        setSearchValue: (state, action) => {
            state.searchValue = { ...state.searchValue, ...action.payload };
        },
        setSearchBy: (state, action) => {
            state.searchBy = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setSortField: (state, action) => {
            state.sortField = action.payload;
        },
        setSortType: (state, action) => {
            state.sortType = action.payload;
        },
        setTimeRange: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setSectionLabel: (state, action) => {
            state.sectionLabel = action.payload;
        },
        setLocationFilterData: (state, action) => {
            state.locationFilterData = action.payload;
        },
        setOrganizationFilterData: (state, action) => {
            state.organizationFilterData = action.payload;
        },
        setAccountFilterData: (state, action) => {
            state.accountFilterData = action.payload;
        },
        setProductFilterData: (state, action) => {
            state.productFilterData = action.payload;
        },
        setLastTableActionTrigger: (state, action) => {
            state.lastTableActionTrigger = action.payload;
        },
        setPageConfigData: (state, action) => {
            switch (action.payload.type) {
                case "SET_PAGE":
                    state.page = action.payload.data;
                    break;
                case "SET_DATA_COUNT":
                    state.dataCount = action.payload.data;
                    break;
                case "SET_IS_LOADING":
                    state.isLoading = action.payload.data;
                    break;
                case "SET_DATA_PER_PAGE":
                    state.dataPerPage = action.payload.data;
                    break;
                case "SET_OFFSET":
                    state.offset = action.payload.data;
                    break;
                default:
                    break;
            }
        },
    },
});
