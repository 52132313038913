import { useFormik } from "formik";
import React, { useState } from "react";
import { InputGroup } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { changePassword, login } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;

  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const [isFirstLogin, setIsFirstLogin] = useState(false);

  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
    ),
    password: Yup.string()
      .min(8, "Password length must be atleast 8 characters")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,

    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      enableLoading();
      setEmail(values.email);
      login({ username: values.email, password: values.password })
        .then(async (res) => {
          let user = res;
          setUser(user);
          resetForm();
          setShowPass(false);
          if (user["challengeName"] === "NEW_PASSWORD_REQUIRED") {
            setIsFirstLogin(true);
          } else {
            setIsFirstLogin(false);
            let userDetails = JSON.parse(JSON.stringify(user));
            props.requestUser(userDetails);
            props.login(res.signInUserSession.accessToken.jwtToken);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: err.message,
            })
          );
          console.error("err", err.message);
        })
        .finally(() => {
          disableLoading();
          setSubmitting(false);
        });
    },
  });

  const NewPasswordForm = () => {
    const newinitialValues = {
      email: "",
      new_Password: "",
      confirm_password: "",
    };

    const NewPasswordSchema = Yup.object().shape({
      email: Yup.string().email("Please enter a valid email address."),
      new_Password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number"
        )
        // .matches(/[A-Z]/, 'At least one uppercase letter is required')
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        ),
      confirm_password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        )
        .oneOf([Yup.ref("new_Password"), null], "Passwords must match"),
    });
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const newFormik = useFormik({
      initialValues: newinitialValues,
      validationSchema: NewPasswordSchema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
        enableLoading();
        values.email = email;

        changePassword(user, values.new_Password)
          .then((res) => {
            if (res) {
              setIsFirstLogin(false);
            }
          })
          .catch((error) => {
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: error.message,
              })
            );
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      },
    });
    const getNewInputClasses = (fieldname) => {
      if (newFormik.touched[fieldname] && newFormik.errors[fieldname]) {
        return "is-invalid";
      }

      if (newFormik.touched[fieldname] && !newFormik.errors[fieldname]) {
        return "is-valid";
      }

      return "";
    };

    return (
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Reset Password !</h3>
        </div>
        <div
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          autoComplete="off"
        >
          {newFormik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {newFormik.status}
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Email"
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getNewInputClasses(
                "email"
              )}`}
              value={email}
              disabled={true}
            />
          </div>

          <div className="form-group fv-plugins-icon-container">
            <InputGroup>
              <input
                type={showNewPass ? "text" : "password"}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "new_Password"
                )}`}
                name="new_Password"
                {...newFormik.getFieldProps("new_Password")}
                placeholder="New Password"
                autoComplete="new-password"
              />
              <InputGroup.Append>
                <InputGroup.Text
                  onClick={() => setShowNewPass((prev) => !prev)}
                  style={{ backgroundColor: "#f3f6f9" }}
                >
                  <i
                    className={showNewPass ? "fas fa-eye-slash" : "fas fa-eye"}
                  ></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {newFormik.touched.new_Password && newFormik.errors.new_Password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {newFormik.errors.new_Password}
                </div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <InputGroup>
              <input
                type={showConfirmPass ? "text" : "password"}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirm_password"
                )}`}
                name="confirm_password"
                {...newFormik.getFieldProps("confirm_password")}
                placeholder="Confirm Password"
                autoComplete="confirm_password"
              />
              <InputGroup.Append>
                <InputGroup.Text
                  onClick={() => setShowConfirmPass((prev) => !prev)}
                  style={{ backgroundColor: "#f3f6f9" }}
                >
                  <i
                    className={
                      showConfirmPass ? "fas fa-eye-slash" : "fas fa-eye"
                    }
                  ></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {newFormik.touched.confirm_password &&
              newFormik.errors.confirm_password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {newFormik.errors.confirm_password}
                </div>
              </div>
            ) : null}
          </div>

          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={newFormik.isSubmitting}
              onClick={newFormik.handleSubmit}
            >
              <span>Submit</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isFirstLogin && <NewPasswordForm />}
      {!isFirstLogin && (
        <div className="login-form login-signin" id="kt_login_signin_form">
          <div className="text-center mb-10">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>

            <p
              style={{
                fontWeight: "500",
                color: "#b5b5c3",
              }}
            >
              Enter your details to login to your account
            </p>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div
                  className="fv-plugins-message-container"
                  style={{ marginTop: "5px" }}
                >
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <InputGroup>
                <input
                  placeholder="Password"
                  type={showPass ? "text" : "password"}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                  autoComplete="password"
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    onClick={() => setShowPass((prev) => !prev)}
                    style={{ backgroundColor: "#f3f6f9" }}
                  >
                    <i
                      className={showPass ? "fas fa-eye-slash" : "fas fa-eye"}
                    ></i>
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              {formik.touched.password && formik.errors.password ? (
                <div
                  className="fv-plugins-message-container"
                  style={{ marginTop: "5px" }}
                >
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="w-100 text-center">
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Sign In</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>

              <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                <Link
                  to="/auth/forgot-password"
                  className="text-dark-50 text-hover-primary my-3 mr-2"
                  id="kt_login_forgot"
                >
                  <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                </Link>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
