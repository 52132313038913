import { Divider, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import EnhancedTableToolbar from "../../Helpers/EnhancedTableToolbar/EnhancedTableToolbar";
import {
  getAuditLogEventList,
  getAuditLogsList,
} from "../../_redux/AuditLogs/AuditLogsCrud";
import { AuditLogsSlice } from "../../_redux/AuditLogs/AuditLogsSlice";
import AuditLogsFilters from "./AuditLogsFilters/AuditLogsFilters";
import AuditLogsTable from "./AuditLogsTable/AuditLogsTable";
import { auditLogsSearchKeys } from "../../Helpers/Common/CommonComponent";

const AuditLogs = () => {
  const dispatch = useDispatch();
  const { actions } = AuditLogsSlice;
  const [isFirstLoad, setFirstLoad] = useState(true);
  const {
    allAuditLogs,
    isLoading,
    page,
    dataCount,
    dataPerPage,
    offset,
    searchValue,
    searchBy,
    startDate,
    endDate,
    filter,
    logLevel,
  } = useSelector(
    (state) => ({
      allAuditLogs: state.auditLogs.allAuditLogs,
      isLoading: state.auditLogs.isLoading,
      page: state.auditLogs.page,
      dataCount: state.auditLogs.dataCount,
      dataPerPage: state.auditLogs.dataPerPage,
      offset: state.auditLogs.offset,
      searchValue: state.auditLogs.searchValue,
      searchBy: state.auditLogs.searchBy,
      startDate: state.auditLogs.startDate,
      endDate: state.auditLogs.endDate,
      filter: state.auditLogs.filter,
      logLevel: state.auditLogs.logLevel,
    }),
    shallowEqual
  );
  const [state, setState] = useState({ expanded: [] });

  useEffect(() => {
    setFirstLoad(true);
    dispatch(actions.setAuditLogs([]));

    dispatch(
      actions.setPageConfigData({
        type: "SET_DATA_PER_PAGE",
        data: 15,
      })
    );

    dispatch(
      actions.setPageConfigData({
        type: "SET_OFFSET",
        data: 0,
      })
    );
    getAuditLogsDataOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuditLogsDataOnLoad = async () => {
    await dispatch(actions.setLoading(true));
    await getAuditLogsList(
      0,
      15,
      searchValue.search.keyword,
      auditLogsSearchKeys,
      JSON.stringify(""),
      startDate?.toDate(),
      endDate?.toDate()
    )
      .then(async (res) => {
        await dispatch(
          actions.setAuditLogs(
            JSON.parse(JSON.stringify(res?.data?.getAuditLogs?.AuditLogs))
          )
        );

        await dispatch(
          actions.setPageConfigData({
            type: "SET_DATA_COUNT",
            data: res?.data?.getAuditLogs?.count,
          })
        );
        setFirstLoad(false);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        dispatch(actions.setLoading(false));
      });
  };

  useEffect(() => {
    if (!isFirstLoad) refreshHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, offset, dataPerPage, filter, startDate, endDate, logLevel]);

  const refreshHandler = () => {
    getAuditLogsData();
  };
  const filterData = {};
  if (filter?.length > 0) {
    filterData.action = filter?.map((data) => data?.value);
  }
  if (logLevel && logLevel !== null) {
    filterData.logLevel = [logLevel];
  }
  const getAuditLogsData = async () => {
    dispatch(actions.setLoading(true));
    await getAuditLogsList(
      offset,
      dataPerPage,
      searchValue.search.keyword,
      searchBy,
      JSON.stringify(filterData),
      // filter?.length > 0
      //   ? JSON.stringify({
      //       action: filter?.map((data) => data?.value),
      //     })
      //   : JSON.stringify(""),
      startDate,
      endDate
    )
      .then(async (res) => {
        setState({ expanded: [] });
        dispatch(
          actions.setAuditLogs(
            JSON.parse(JSON.stringify(res?.data?.getAuditLogs?.AuditLogs))
          )
        );

        dispatch(
          actions.setPageConfigData({
            type: "SET_DATA_COUNT",
            data: res?.data?.getAuditLogs?.count,
          })
        );
        setFirstLoad(false);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        dispatch(actions.setLoading(false));
      });
  };

  const APIActionListData = () => {
    getAuditLogEventList().then(({ data }) => {
      dispatch(
        actions.setAPIOptions(
          data?.getActions?.actions?.sort()?.map((val) => ({
            value: val,
            label: val,
          }))
        )
      );
    });
  };

  useEffect(() => {
    APIActionListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <EnhancedTableToolbar
        title="Audit Logs"
        showAdd={false}
        circularLoader={
          isLoading && (
            <span style={{ marginLeft: "5px" }}>
              <Spinner animation="border" />
            </span>
          )
        }
        refreshHandler={() => {
          getAuditLogsData();
          APIActionListData();
        }}
        showSearch={true}
        searchPlaceHolder="Search by Tender ID or Action"
        filter={searchValue}
        searchConfig={{
          searchKeys: dispatch(actions.setSearchBy(auditLogsSearchKeys)),
          filterValue: searchValue?.search?.keyword || "",
          setSearchConfig: (data) => {
            dispatch(actions.setSearchValue(data));
            dispatch(
              actions.setPageConfigData({
                type: "SET_OFFSET",
                data: 0,
              })
            );
          },
        }}
        showbackBtn={true}
        setReload={() => {
          const dataFilter = {
            search: {
              keys: auditLogsSearchKeys,
              keyword: "",
            },
          };
          dispatch(actions.setSearchValue(dataFilter));
          dispatch(
            actions.setPageConfigData({
              type: "SET_OFFSET",
              data: 0,
            })
          );
        }}
      />
      <Divider />
      <AuditLogsFilters />
      <Divider />

      <AuditLogsTable
        allAuditLogs={allAuditLogs}
        page={page}
        dataCount={dataCount}
        dataPerPage={dataPerPage}
        offset={offset}
        state={state}
        setState={setState}
      />
    </Paper>
  );
};

export default AuditLogs;
