import { Dialog } from '@mui/material';
import React, { useEffect } from 'react';
import Draggable from 'react-draggable';

const CustomDraggableDialog = ({ draggableChildren, show, onHide, isDraggable = false }) => {
   useEffect(() => {
      const closeOnEscapePressed = (e) => {
         if (e.key === "Escape") {
            onHide();
         }
      };
      window.addEventListener("keyup", closeOnEscapePressed);
      return () =>
         window.removeEventListener("keyup", closeOnEscapePressed);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Dialog open={show} scroll={"paper"} maxWidth="lg" fullWidth={true}>
         <Draggable
            handle={'[class*="MuiDialog-root"]'}
            cancel={'[class*="MuiDialogContent-root"]'}
            disabled={isDraggable}
         >
            {draggableChildren}
         </Draggable>
      </Dialog>
   );
};

export default CustomDraggableDialog;
