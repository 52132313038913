import { Button, Dialog, DialogContent, useTheme } from "@mui/material";
import React, { useState } from "react";
import DialogCloseTitle from "../Dialog/DialogTitle";
import CustomDraggableDialog from "../CustomDraggableDialog/CustomDraggableDialog";

const TableCellExpandList = ({
  list = [],
  minItemsToShow,
  renderItem,
  containerStyles,
  buttonStyle,
  dialogView,
  label
}) => {
  const [isExpanded, setisExpanded] = useState(false);
  const theme = useTheme();

  return (
    <>
      <div style={containerStyles}>
        {list.length > 0 ? list
          .slice(
            0,
            isExpanded
              ? dialogView
                ? minItemsToShow
                : list.length
              : minItemsToShow
          )
          .map((item, i) => renderItem(item, i)) : "-"}
        {dialogView && (
          <CustomDraggableDialog
            show={isExpanded}
            onHide={() => setisExpanded(false)}
            draggableChildren={
              <Dialog
                open={isExpanded}
                scroll={"paper"}
                maxWidth="sm"
                PaperProps={{
                  style: {
                    maxWidth: "500px",
                    maxHeight: "700px",
                    zIndex: "90"
                  },
                }}
                fullWidth={true}
                hideBackdrop={true}
              >
                <DialogCloseTitle onClose={() => setisExpanded((prev) => !prev)}>
                  <div
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    {label}
                  </div>
                </DialogCloseTitle>
                <DialogContent dividers>
                  {list?.map((permissionsList, i) => {
                    return (
                      <div
                        key={i}
                        style={{ margin: "5px 0px 0px 5px" }}
                      >
                        {permissionsList?.length === 0 ? "-" : permissionsList}
                      </div>
                    );
                  })}
                </DialogContent>
              </Dialog>
            }
          />
        )}
      </div>

      {list?.length > minItemsToShow && (
        <Button
          onClick={() => setisExpanded((prev) => !prev)}
          style={{
            width: buttonStyle ? buttonStyle : "100%",
            textTransform: "lowercase",
            color: "#444",
            background: isExpanded
              ? "transparent"
              : `linear-gradient(0deg, rgb(193 193 193) 0%, rgba(255,255,255,0) 100%)`,
          }}
        >
          {/* {isExapnded ? <ExpandLessIcon /> : <ExpandMoreIcon />}{" "} */}
          {isExpanded ? "show less" : "show more"}{" "}
        </Button>
      )}
    </>
  );
};

export default TableCellExpandList;
