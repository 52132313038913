import React, { useEffect, useRef } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const DateRangePickerCustom = ({
  startDate,
  endDate,
  timezoneOffset,
  handleCallback,
  handleCancel,
  render,
  initEmpty = false,
  cancelLabel = "Cancel",
  cancelButtonClasses = "reset-btn",
  id,
  isReload,
  setIsReload,
  maxDate,
}) => {
  const dateRanger = useRef();

  useEffect(() => {
    if (isReload) {
      dateRanger.current.setStartDate(moment());
      dateRanger.current.setEndDate(moment());
      setIsReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);
  const onShow = (event, picker) => {
    if (moment().format("DD-MM-YYYY") === moment(picker.endDate).format("DD-MM-YYYY"))
      document.getElementsByClassName("applyBtn")[0].disabled = true;
  }


  return (
    <DateRangePicker
      id={id}
      ref={dateRanger}
      initialSettings={{
        ...(initEmpty
          ? {
            autoUpdateInput: false,
          }
          : {
            startDate: startDate ? startDate : moment().toDate(),
            endDate: endDate,
            timezoneOffset: timezoneOffset,
          }),
        locale: {
          format: "M/DD hh:mm A",
          cancelLabel: cancelLabel,
        },
        maxDate: maxDate
          ? maxDate
          : moment()
            .endOf("day")
            .toDate(),
        timePicker: true,
        cancelButtonClasses: cancelButtonClasses,
        ranges: {
          Today: [
            moment()
              .startOf("day")
              .toDate(),
            moment()
              .endOf("day")
              .toDate(),
          ],
          Yesterday: [
            moment()
              .subtract(1, "days")
              .startOf("day")
              .toDate(),
            moment()
              .subtract(1, "days")
              .endOf("day")
              .toDate(),
          ],
          "Last 7 Days": [
            moment()
              .subtract(6, "days")
              .startOf("day")
              .toDate(),
            moment().toDate(),
          ],
          "Last 30 Days": [
            moment()
              .subtract(29, "days")
              .endOf("day")
              .toDate(),
            moment().toDate(),
          ],
          "This Month": [
            moment()
              .startOf("month")
              .toDate(),
            moment()
              .endOf("month")
              .toDate(),
          ],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month")
              .toDate(),
            moment()
              .subtract(1, "month")
              .endOf("month")
              .toDate(),
          ],
          "Next Month": [
            moment()
              .add(1, "months")
              .startOf("month")
              .toDate(),
            moment()
              .add(1, "months")
              .endOf("month")
              .toDate(),
          ],
        },
      }}
      onCallback={handleCallback}
      // onApply={handleApply}
      onCancel={handleCancel}
      onShow={onShow}
    >
      {render}
    </DateRangePicker>
  );
};

export default DateRangePickerCustom;
