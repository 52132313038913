import { useTheme } from '@emotion/react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CustomDraggableDialog from '../../../../../Helpers/CustomDraggableDialog/CustomDraggableDialog';
import DialogCloseTitle from '../../../../../Helpers/Dialog/DialogTitle';
import { closeModal } from '../../../../../Helpers/Dialog/dialogUtils';
import BootstrapButton from '../../../../../Helpers/UI/Button/BootstrapButton';
const ChatCommentModal = ({
   chatCommentModal,
   setChatCommentModal,
   setIsSubmitting,
   isSubmitting,
   allChatComment,
   setAllChatComment,
   sendChatComments,
   carrierUserDeatil,
   carrierName = "",
   isDisableDrag = true,
   setIsDisableDrag
}) => {
   const { userDetails } = useSelector((state) => state.rolesAndPermissions);
   const theme = useTheme();
   const [comment, setComment] = useState("");
   const onHide = () => {
      setChatCommentModal(false);
      setIsDisableDrag(false);
   };

   const scrollDiv = useRef(null);
   useEffect(() => {
      setTimeout(() => {
         if (scrollDiv && scrollDiv.current)
            scrollDiv.current.scrollIntoView({ behavior: "smooth" });
      }, 1000);
   }, [allChatComment]);
   return (
      <CustomDraggableDialog show={chatCommentModal} onHide={onHide}
         isDraggable={!isDisableDrag}
         draggableChildren={
            <Dialog open={chatCommentModal} scroll={"paper"} maxWidth="sm" fullWidth={true} hideBackdrop={true}>
               <DialogCloseTitle
                  onClose={closeModal({ onHide })}
                  isCloseButtonDisabled={isSubmitting}
               >
                  <div
                     style={{
                        fontSize: "1.5rem",
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                     }}
                  >
                     Comments
                  </div>
               </DialogCloseTitle>
               <DialogContent dividers>
                  <div style={{ margin: 10, maxHeight: "50vh", overflowY: "scroll" }}>
                     {allChatComment?.length ?
                        <>{carrierUserDeatil
                           ? allChatComment?.map((chat, i) => (
                              <React.Fragment key={i}>
                                 <div style={{
                                    display: 'flex', flexDirection: 'column', alignItems: chat?.user ? 'flex-start' : 'flex-end'
                                    , width: '100%'
                                 }}>
                                    {!chat?.user ?
                                       <div style={{ maxWidth: "60%", marginBottom: 10 }} >
                                          <div className='text-right'>
                                             {carrierUserDeatil?.pocName}
                                          </div>
                                          <div style={{ padding: 10, backgroundColor: "#e9e9e9", color: "black", borderRadius: '10px 0px 10px 10px' }}>
                                             {chat?.comment}
                                          </div>
                                       </div>
                                       : <div style={{ maxWidth: "60%", marginBottom: 10 }}>
                                          <div>
                                             CWI user
                                          </div>
                                          <div style={{ padding: 10, backgroundColor: "#3699FF", color: "white", borderRadius: '0 10px 10px 10px' }}>
                                             {chat?.comment}
                                          </div>
                                       </div>
                                    }
                                 </div>
                              </React.Fragment>
                           ))
                           :
                           allChatComment?.map((chat, i) => (
                              <React.Fragment key={i}>
                                 <div style={{
                                    display: 'flex', flexDirection: 'column', alignItems: chat?.user === userDetails?.ID ? 'flex-end' : 'flex-start', width: '100%'
                                 }}>
                                    {chat.user ?
                                       <div style={{ maxWidth: "60%", marginBottom: 10 }} >
                                          <div className='text-left'>
                                             {chat?.User ? <>
                                                {chat?.User?.firstName}&nbsp;{chat?.User?.lastName}
                                             </> : <>
                                                {carrierName}
                                             </>
                                             }
                                          </div>
                                          <div style={{ padding: 10, backgroundColor: "#e9e9e9", color: "black", borderRadius: chat?.user === userDetails?.ID ? '10px 0px 10px 10px' : '0 10px 10px 10px' }}>
                                             {chat?.comment}
                                          </div>
                                       </div>
                                       : <div style={{ maxWidth: "60%", marginBottom: 10 }}>
                                          <div>
                                             {chat?.User ? <>
                                                {chat?.User?.firstName}&nbsp;{chat?.User?.lastName}
                                             </> : <>
                                                {carrierName}
                                             </>
                                             }
                                          </div>
                                          <div style={{ padding: 10, backgroundColor: "#3699FF", color: "white", borderRadius: '0 10px 10px 10px' }}>
                                             {chat?.comment}
                                          </div>
                                       </div>
                                    }
                                 </div>
                              </React.Fragment>
                           ))

                        } </> : <div
                           style={{

                              padding: 15,
                              margin: "5px 5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: 14,
                              fontWeight: 500,
                              color: 'grey',
                           }}
                        >
                           No Comments Available
                        </div>
                     }
                     <div ref={scrollDiv} />
                  </div>

                  <Form.Row>
                     <Col sm={12} md={12}>
                        <Form.Group>
                           <Form.Label style={{ fontWeight: 600 }}>
                              Add Comment
                           </Form.Label>
                           <Form.Control
                              as='textarea'
                              rows={3}
                              type="description"
                              name="comment"
                              disabled={isSubmitting}
                              value={comment}
                              onChange={(e) => {
                                 setComment(e.target.value);
                              }}
                           />
                        </Form.Group>
                     </Col>
                  </Form.Row>
               </DialogContent>
               <DialogActions>
                  <BootstrapButton
                     variant="success"
                     type="submit"
                     label={"Send   "}
                     labelWhenSubmitting="Sending"
                     disabled={comment.trim() === ""}
                     isSubmitting={isSubmitting}
                     onClick={() => {
                        sendChatComments(comment);
                     }}
                  />
               </DialogActions>
            </Dialog >
         } />
   );
};

export default ChatCommentModal;
