import { API } from "aws-amplify";
import {
  createPermissionQuery,
  getPermissionsQuery,
  updatePermissionQuery,
} from "../../../../../Queries/RolesAndPermissionsQuery/permissionsQuery";
import { PermissionsSearchKeys } from "../../../Helpers/Common/CommonComponent";

export const getPermissionsList = (
  limit = 15,
  offset = 0,
  code = null,
  sortField = "name",
  sortType = "Asc",
  searchBy = PermissionsSearchKeys,
  searchValue = ""
) => {
  let searchValueTrim = searchValue && searchValue.trim();
  return API.graphql({
    query: getPermissionsQuery,
    variables: {
      code: code,
      limit: limit,
      offset: offset,
      searchValue: searchValueTrim,
      searchBy: searchBy,
      sortField: sortField,
      sortType: sortType,
    },
  });
};

export const createPermission = (value) => {
  return API.graphql({
    query: createPermissionQuery,
    variables: {
      PermissionInput: value,
    },
  });
};

export const updatePermission = (code, value) => {
  return API.graphql({
    query: updatePermissionQuery,
    variables: {
      code: code,
      PermissionUpdateInput: value,
    },
  });
};