export const getUserDetailsQuery = `query MyQuery{
  getCurrentUser{
    ID
    firstName
    lastName
    email
    locations
    employeeID
    Role{
      ID
      name
      permissions
      description
      isEnabled
    }
  }

}`;
