import { Box } from "@mui/material";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DateRangePickerCustom from "../../../Helpers/DateRangePicker/DateRangePickerCustom";
import { AuditLogsSlice } from "../../../_redux/AuditLogs/AuditLogsSlice";

// const Title = ({ title }) => <Box sx={{ fontWeight: 500 }}>{title}</Box>;
const Container = ({ sx, children }) => (
  <Box
    sx={{
      margin: "5px 5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "2px",
      ...sx,
    }}
  >
    {children}
  </Box>
);

const AuditLogsFilters = () => {
  const { apiActionsList, filter, startDate, endDate } = useSelector(
    (state) => ({
      apiActionsList: state.auditLogs.apiActionsList,
      filter: state.auditLogs.filter,
      startDate: state.auditLogs.startDate,
      endDate: state.auditLogs.endDate,
    }),
    shallowEqual
  );

  const label =
    startDate && endDate
      ? startDate.format("MMMM D, YYYY") +
      " - " +
      endDate.format("MMMM D, YYYY")
      : "-";

  const handleCallback = (start, end) => {
    dispatch(actions.setTimeRange({ startDate: start, endDate: end }));
  };

  const handleCancel = (event, data) => {
    data.setStartDate(moment().startOf("day"));
    data.setEndDate(moment().endOf("day"));
    dispatch(
      actions.setTimeRange({
        startDate: moment().startOf("day"),
        endDate: moment().endOf("day"),
      })
    );
  };

  const customStyles = {
    container: () => ({
      width: 400,
    }),
    // valueContainer: (provided, state) => ({
    //   ...provided,
    //   maxHeight: '100px',
    //   overflow:"auto"
    // }),
  };

  const handleChange = (val) => {
    dispatch(actions.setFilter(val ? val : []));
    dispatch(
      actions.setPageConfigData({
        type: "SET_OFFSET",
        data: 0,
      })
    );
  };

  const dispatch = useDispatch();
  const { actions } = AuditLogsSlice;
  const logTypes = [
    { value: null, label: "All Log" },
    { value: "INFO", label: "Info Log" },
    { value: "ERROR", label: "Error Log" },
  ];

  const handleLogSelect = (data) => {
    dispatch(actions.setLogLevel(data.value));
    dispatch(
      actions.setPageConfigData({
        type: "SET_OFFSET",
        data: 0,
      })
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        marginLeft: "auto",

        flexWrap: "wrap",
      }}
    >
      <Container>
        <div style={{ width: "150px" }}>
          <Select
            menuPortalTarget={document.body}
            placeholder="Log Types"
            onChange={handleLogSelect}
            options={logTypes}
            noOptionsMessage={() => "No Log Found"}
          />
        </div>
      </Container>
      <Container>
        {/* <Title title="Display Events" /> */}
        <Select
          value={filter}
          placeholder="Select Event Types"
          menuPortalTarget={document.body}
          isMulti
          styles={{ ...customStyles }}
          onChange={(value) => handleChange(value)}
          options={apiActionsList}
          noOptionsMessage={() => "No Events Found"}
        />
      </Container>
      <Container>
        {/* <Title title="Date Range" /> */}
        <DateRangePickerCustom
          startDate={startDate?.toDate()}
          endDate={endDate?.toDate()}
          handleCallback={handleCallback}
          handleCancel={handleCancel}
          cancelLabel="Reset To Today"
          cancelButtonClasses="btn-outline-danger"
          render={
            <div
              id="reportrange"
              style={{
                background: "#fff",
                cursor: "pointer",
                padding: "8px 10px",
                border: "1px solid #ccc",
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <i className="fa fa-calendar"></i>&nbsp;
              <span>{label}</span> <i className="fa fa-caret-down"></i>
            </div>
          }
        />
      </Container>
    </Box>
  );
};

export default AuditLogsFilters;
