import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   isLoading: true,
   sectionLabel: "locationID",
   locationFilterData: [],
   organizationFilterData: [],
   accountFilterData: [],
   productFilterData: [],
   page: 0,
   dataCount: 0,
   offset: 0,
   dataPerPage: 15,
   sortField: "updatedOn", //TODO
   sortType: "DESC",
   allLocationTableData: [],
   allOrderTableData: [],
   allLastTableData: {},
   orderFilterData: [],
   groupBy: "location",
   activeStatus: "",
   allUploadedPhotos: [],
   startDate: null,
   endDate: null,
   actionTrigger: false
};

export const StatusSlice = createSlice({
   name: "status",
   initialState: initialState,
   reducers: {
      setSectionLabel: (state, action) => {
         state.sectionLabel = action.payload;
      },
      setLoading: (state, action) => {
         state.isLoading = action.payload;
      },
      setLocationFilterData: (state, action) => {
         state.locationFilterData = action.payload;
      },
      setOrganizationFilterData: (state, action) => {
         state.organizationFilterData = action.payload;
      },
      setAccountFilterData: (state, action) => {
         state.accountFilterData = action.payload;
      },
      setProductFilterData: (state, action) => {
         state.productFilterData = action.payload;
      },
      setTimeRange: (state, action) => {
         state.startDate = action.payload.startDate;
         state.endDate = action.payload.endDate;
      },
      setAllLocationTableData: (state, action) => {
         state.allLocationTableData = action.payload;
      },
      setAllOrderTableData: (state, action) => {
         state.allOrderTableData = action.payload;
      },
      setAllLastTableData: (state, action) => {
         state.allLastTableData = action.payload;
      },
      setGroupBy: (state, action) => {
         state.groupBy = action.payload;
      },
      setActiveStatus: (state, action) => {
         state.activeStatus = action.payload;
      },
      setAllUploadedPhotos: (state, action) => {
         state.allUploadedPhotos = action.payload;
      },
      setOrderFilterData: (state, action) => {
         state.orderFilterData = action.payload;
      },
      setActionTrigger: (state, action) => {
         state.actionTrigger = action.payload;
      },
      setPageConfigData: (state, action) => {
         switch (action.payload.type) {
            case "SET_PAGE":
               state.page = action.payload.data;
               break;
            case "SET_DATA_COUNT":
               state.dataCount = action.payload.data;
               break;
            case "SET_IS_LOADING":
               state.isLoading = action.payload.data;
               break;
            case "SET_DATA_PER_PAGE":
               state.dataPerPage = action.payload.data;
               break;
            case "SET_OFFSET":
               state.offset = action.payload.data;
               break;
            default:
               break;
         }
      },

   },
});


