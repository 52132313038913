import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDebouncyEffect } from "use-debouncy";
import { useHorizontalScroll } from "../../Utils/utils";

const EnhancedTableToolbar = ({
  title,
  tooltipTitle,
  btnTitle,
  circularLoader,
  btnHandler,
  refreshWhenWholeFilterChange = false,
  filter,
  refreshHandler,
  extrabtn,
  renderBeforeSearch,
  showbackBtn = true,
  showAdd = true,
  showReload = true,
  showSearch = false,
  shouldGetDataOnSearchInput = true,
  setReload,
  searchConfig: { searchKeys = [], filterValue = "", setSearchConfig } = {},
  searchPlaceHolder,
  isTitleTrim = false,
  searchOnEnter = false,
  onEnter,
  isNoTender = false,
}) => {
  const history = useHistory();
  const horizontalScrollRef = useHorizontalScroll()

  useDebouncyEffect(
    () => (shouldGetDataOnSearchInput ? refreshHandler() : undefined),
    800,
    [refreshWhenWholeFilterChange ? filter : filterValue]
  );

  return (
    <Toolbar
      ref={horizontalScrollRef}
      className="custome_scroll_size"
      sx={{
        paddingLeft: (theme) => theme.spacing(2),
        paddingRight: (theme) => theme.spacing(1),
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        overflow: "auto",
      }}
    >
      {showbackBtn && (
        <Box
          onClick={() => history.goBack()}
          sx={{
            padding: "0px 10px",
            fontSize: "1.4rem",
            cursor: "pointer",
          }}
        >
          <i className="fas fa-arrow-left" style={{ color: "#000" }}></i>
        </Box>
      )}
      <Typography
        sx={{
          fontWeight: 600,
          flex: "1 1 100%",
          fontSize: "1.4rem",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
        noWrap={true}
      >
        {title}
        {circularLoader}
      </Typography>

      {renderBeforeSearch}

      {showSearch && (
        <Col sm={6} lg={4}>
          <TextField
            fullWidth={true}
            sx={{
              "& legend": {
                width: "0px",
              },
              borderWidth: "1px",
              borderColor: "#e5e5e5",
              borderStyle: "solid",
              borderRadius: "5px",
            }}
            // className={classes.input}
            // inputProps={{ style: { padding: "11px 4px" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              autoComplete: "off",
              endAdornment: filterValue ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    const dataFilter = {
                      search: {
                        keys: searchKeys,
                        keyword: "",
                      },
                    };
                    setSearchConfig(dataFilter);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                undefined
              ),
            }}
            inputProps={{
              style: {
                padding: "10px 14px",
              },
            }}
            value={filterValue}
            variant="outlined"
            onKeyDownCapture={(e) => {
              searchOnEnter && e.key === "Enter" && onEnter();
            }}
            // sx={{
            //   border:"1px #aeaeae solid",
            //   borderRadius:"4px"
            // }}
            onChange={(e) => {
              const value = e.target.value;
              const dataFilter = {
                search: {
                  keys: searchKeys,
                  keyword: isTitleTrim ? value.trim() : value,
                },
              };
              setSearchConfig(dataFilter);
            }}
            name="searchText"
            placeholder={searchPlaceHolder ? searchPlaceHolder : "Search"}
          />

          {/* <Form.Control
              type="text"
              value={filterValue}
              style={{ borderLeft: "0px solid #fff" }}
              onChange={(e) => {
                const value = e.target.value;
                const dataFilter = {
                  search: {
                    keys: searchKeys,
                    keyword: value,
                  },
                };
                setSearchConfig(dataFilter);
              }}
              name="searchText"
              placeholder="Search"
            /> */}
        </Col>
      )}

      {extrabtn}

      {showReload && (
        <Tooltip arrow title="Fetch New Data">
          <Button
            size="large"
            variant="contained"
            style={{ backgroundColor: "#000", color: "#fff", minWidth: "auto" }}
            onClick={() => {
              setReload();
              refreshHandler();
            }}
            startIcon={<CachedIcon />}
          >
            Reload
          </Button>
        </Tooltip>
      )}

      {showAdd &&
        (isNoTender ? (
          <Button
            size="large"
            color="primary"
            variant="contained"
            style={{ color: "#fff", marginLeft: "10px", minWidth: "auto" }}
            onClick={btnHandler}
            startIcon={<AddIcon />}
            disabled={true}
          >
            {/* <AddCircleOutlineIcon /> */}

            {btnTitle}
          </Button>
        ) : (
          <Tooltip arrow title={tooltipTitle}>
            <Button
              size="large"
              color="primary"
              variant="contained"
              style={{ color: "#fff", marginLeft: "10px", minWidth: "auto" }}
              onClick={btnHandler}
              startIcon={<AddIcon />}
            >
              {/* <AddCircleOutlineIcon /> */}

              {btnTitle}
            </Button>
          </Tooltip>
        ))}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
