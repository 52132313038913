import { Auth } from "aws-amplify";

export function login(credentials) {
  return Auth.signIn(credentials.username, credentials.password);
}

export function requestPassword(username) {
  try {
    return Auth.forgotPassword(username);
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function resetPassword(values) {
  try {
    return Auth.forgotPasswordSubmit(
      values.email,
      values.code,
      values.new_Password
    );
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function dashboardResetPassword(oldPassword, newPassword) {
  try {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, oldPassword, newPassword);
    });
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function changePassword(user, newPassword) {
  try {
    return Auth.completeNewPassword(user, newPassword);
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getUserByToken() {
  try {
    return Auth.currentAuthenticatedUser();
  } catch (error) {
    console.error(error);
  }
}

export function checkSession() {
  return new Promise(async (resolve, reject) => {
    try {
      const session = await Auth.currentSession();
      if (session && session.isValid()) {
        resolve(session);
      } else {
        reject(false);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function signOut(token) {
  return new Promise((resolve, reject) => {
    try {
      Auth.signOut(token);
      resolve(true);
    } catch (error) {
      reject();
    }
  });
}
