import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allTransportOrders: [],
  selectedTransportOrders: null,
  isLoading: true,
  searchValue: "",
  searchBy: [],
  page: 0,
  dataCount: 0,
  offset: 0,
  dataPerPage: 15,
  sortField: "createdOn",
  sortType: "DESC",
  businessDaysData: {},
  status: "",
  statusCountData: [],
  allLocationData: [],
  nonCoinProductsList: [],
  locationCode: "",
  itemID: "",
  productID: "",
  transportOrderID: "",
  productType: "",
  filter: [],
  // allLocationData: [],
  startDate: null,
  endDate: null,
  actionTrigger: false,
};

export const TransportOrdersSlice = createSlice({
  name: "transportOrders",
  initialState: initialState,
  reducers: {
    setTransportOrders: (state, action) => {
      state.allTransportOrders = action.payload;
    },
    transportOrderFetched: (state, action) => {
      state.selectedTransportOrders = action.payload;
    },
    removeSelectedTransportOrder: (state) => {
      state.selectedTransportOrders = null;
    },
    setAllLocationData: (state, action) => {
      state.allLocationData = action.payload;
    },
    setNonCoinProductsList: (state, action) => {
      state.nonCoinProductsList = action.payload;
    },
    setLocationCode: (state, action) => {
      state.locationCode = action.payload;
    },
    setItemID: (state, action) => {
      state.itemID = action.payload;
    },
    setProductID: (state, action) => {
      state.productID = action.payload;
    },
    setBusinessDaysData: (state, action) => {
      state.businessDaysData = action.payload;
    },
    setStatusCountData: (state, action) => {
      state.statusCountData = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = { ...state.searchValue, ...action.payload };
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSortType: (state, action) => {
      state.sortType = action.payload;
    },
    setActionTrigger: (state, action) => {
      state.actionTrigger = action.payload;
    },
    setTransportOrderID: (state, action) => {
      state.transportOrderID = action.payload;
    },
    setProductType: (state, action) => {
      state.productType = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    // setAllLocationData: (state, action) => {
    //   state.allLocationData = action.payload;
    // },
    setTimeRange: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        case "SET_OFFSET":
          state.offset = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
