/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React from "react";
import { shallowEqual, useSelector } from "react-redux";
// import { shallowEqual, useSelector } from "react-redux";
// import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
// import LogoutIcon from '@mui/icons-material/Logout';
import {
  MODEL_INNER_EMPLOYEE_LABEL,
  MODEL_INNER_INVENTORY_LABEL,
  MODEL_LABEL,
  ORDERS_LABEL,
  ROLES_AND_PERMISSIONS_LABEL
} from "../../../../../app/modules/coinWrap/Helpers/Common/CommonComponent";
import { checkIsActive } from "../../../../_helpers";
import {
  allMenuItems,
  arrayToObjectArray,
  getFilteredByLicensePermissionSearchKey,
} from "./asideMenuListConfig";

const ListItem = ({
  label,
  linkTo,
  FWIconClassName,
  getMenuItemActive,
  RolesAndPermissions,
  permissionType,
  name
}) => (
  <li
    className={`menu-item aside-item ${getMenuItemActive(linkTo, false)}`}
    aria-haspopup="true"
    hidden={
      // label === "Dashboard"
      //   ? false
      //   :
      checkMenuPermissions(permissionType, RolesAndPermissions)
    }
  >
    <NavLink className="menu-link" to={linkTo}>
      <i
        className={`${FWIconClassName} svg-icon menu-icon`}
        style={{
          fontSize:
            `${FWIconClassName}` === "far fa-circle" ? "1rem" : "1.3rem",
        }}
      ></i>
      <span className="menu-text" name={name}>{label}</span>
    </NavLink>
  </li>
);

const InnerSubMenuItemWithList = ({
  label,
  linkTo,
  FWIconClassName,
  items = [],
  getMenuItemActive,
  RolesAndPermissions,
  permissionType,
}) => (
  <li
    className={`menu-item menu-item-submenu aside-item`}
    aria-haspopup="true"
    hidden={checkItemPermission(permissionType, RolesAndPermissions)}
  >
    <NavLink className="menu-link menu-toggle" to={linkTo}>
      <i className={`${FWIconClassName} svg-icon menu-icon`}></i>
      <span className="menu-text">{label}</span>
      <i className="menu-arrow" />
    </NavLink>
    <div className="menu-submenu ">
      <i className="menu-arrow" />
      <ul className="menu-subnav">
        {items.map((d, i) => (
          <ListItem
            key={d.label}
            label={d.label}
            linkTo={d.linkTo}
            FWIconClassName={d.FWIconClassName}
            getMenuItemActive={getMenuItemActive}
            RolesAndPermissions={RolesAndPermissions}
            permissionType={d?.permissionType}
            name={d.label + i}
          />
        ))}
      </ul>
    </div>
  </li>
);

const SubMenuItemWithList = ({
  label,
  linkTo,
  FWIconClassName,
  items = [],
  getMenuItemActive,
  RolesAndPermissions,
  permissionType,
}) => (
  <li
    className={`menu-item menu-item-submenu aside-item`}
    aria-haspopup="true"
    hidden={checkItemPermission(permissionType, RolesAndPermissions)}
  >
    <NavLink className="menu-link menu-toggle" to={linkTo}>
      <i className={`${FWIconClassName} svg-icon menu-icon`}></i>
      <span className="menu-text">{label}</span>
      <i className="menu-arrow" />
    </NavLink>
    <div className="menu-submenu ">
      <i className="menu-arrow" />
      <ul className="menu-subnav">
        {items.map((d, i) => {
          return (
            <React.Fragment key={d.label}>
              {d.items ? (
                <InnerSubMenuItemWithList
                  key={d.label}
                  items={d.items}
                  label={d.label}
                  linkTo={d.linkTo}
                  FWIconClassName={d.FWIconClassName}
                  getMenuItemActive={getMenuItemActive}
                  RolesAndPermissions={RolesAndPermissions}
                  permissionType={d?.permissionType}
                />
              ) : (
                <>
                  <ListItem
                    key={d.label}
                    label={d.label}
                    linkTo={d.linkTo}
                    FWIconClassName={d.FWIconClassName}
                    getMenuItemActive={getMenuItemActive}
                    RolesAndPermissions={RolesAndPermissions}
                      permissionType={d?.permissionType}
                      name={d.label + i}
                  />
                </>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  </li>
);
const checkMenuPermissions = (label, RolesAndPermissions) => {
  if (!RolesAndPermissions[label]) {
    return true;
  } else {
    if (RolesAndPermissions[label].length) {
      return false;
    } else return true;
  }
};
const checkItemPermission = (label, RolesAndPermissions) => {
  if (label === "Employees") {
    if (
      MODEL_INNER_EMPLOYEE_LABEL.some(
        (data) => RolesAndPermissions[data]?.length
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
  if (label === "Inventory") {  
    if (
      MODEL_INNER_INVENTORY_LABEL.some(
        (data) => RolesAndPermissions[data]?.length
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
  if (label === "Models") {
    if (MODEL_LABEL.some((data) => RolesAndPermissions[data]?.length)) {
      return false;
    } else {
      return true;
    }
  }
  if (label === "Roles and Permissions") {
    if (
      ROLES_AND_PERMISSIONS_LABEL.some(
        (data) => RolesAndPermissions[data]?.length
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
  if (label === "Orders") {
    if (
      ORDERS_LABEL.some(
        (data) => RolesAndPermissions[data]?.length
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

export function AsideMenuList({ layoutProps, searchValue }) {
  const { RolesAndPermissions } = useSelector(
    (state) => ({
      RolesAndPermissions: state.rolesAndPermissions.allRolesAndPermissions,
    }),
    shallowEqual
  );
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const SectionItem = ({ label }) =>
    label !== "NO_LABEL" && (
      <li className="menu-section">
        <h4 className="menu-text" style={{ fontSize: "1.1rem" }}>
          {label}
        </h4>
        <i className="menu-icon flaticon-more-v2"></i>
      </li>
    );

  return (
    <>
      <ul
        className={`my-custom-nav menu-nav ${layoutProps.ulClasses}`}
        style={{ overflow: "auto", height: "100%" }}
      >
        {Object.values(
          arrayToObjectArray(
            getFilteredByLicensePermissionSearchKey(allMenuItems)
          )
        ).map((v) => {
          return (
            <React.Fragment key={v.label}>
              <SectionItem label={v.label} />
              {v.items.map((d, i) =>
                d.items ? (
                  <SubMenuItemWithList
                    key={d.label}
                    items={d.items}
                    label={d.label}
                    linkTo={d.linkTo}
                    FWIconClassName={d.FWIconClassName}
                    getMenuItemActive={getMenuItemActive}
                    RolesAndPermissions={RolesAndPermissions}
                    permissionType={d?.permissionType}
                  />
                ) : (
                  <ListItem
                    key={d.label}
                    label={d.label}
                    linkTo={d.linkTo}
                    FWIconClassName={d.FWIconClassName}
                    getMenuItemActive={getMenuItemActive}
                    RolesAndPermissions={RolesAndPermissions}
                      permissionType={d?.permissionType}
                      name={d.label + i}
                  />
                )
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </>
  );
}
