import React from "react";
import {
  Dashboard
} from "../../_metronic/_partials";
import { Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { MAIN_LABEL } from "../modules/coinWrap/Helpers/Common/CommonComponent";
import { ErrorPage1 } from "../modules/ErrorsExamples/ErrorPage1";

export function DashboardPage() {

  const {
    RolesAndPermissions,
  } = useSelector(
    (state) => ({
      RolesAndPermissions: state.rolesAndPermissions.allRolesAndPermissions,
    }),
    shallowEqual
  );

  return (
    <>
      {
        RolesAndPermissions[MAIN_LABEL.CARRIERS] &&
          RolesAndPermissions[MAIN_LABEL.CARRIERS].length ? (
          <>
            <Switch>
              <Route path={`/`} component={Dashboard} />
              <Route path={`/*`} component={ErrorPage1} />
            </Switch>
          </>
        ) : (
          <ErrorPage1 />
        )
      }
    </>
  );

  // return <Dashboard />;
}
