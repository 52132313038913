export const NAME = process.env.REACT_APP_NAME;

const ENV = process.env;

export const AWSConfig = {
  aws_cognito_region: ENV.REACT_APP_REGION,
  aws_user_pools_id: ENV.REACT_APP_USER_POOLID,
  aws_user_pools_web_client_id: ENV.REACT_APP_USER_WEB_CLIENTID,
  aws_cognito_identity_pool_id: ENV.REACT_APP_COGNITO_IDENTITY_POOLID,
  authentication_type: ENV.REACT_APP_AUTH_TYPE,
  aws_mandatory_sign_in: "enable",
  aws_appsync_graphqlEndpoint: ENV.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: ENV.REACT_APP_REGION,
  aws_appsync_authenticationType: ENV.REACT_APP_APPSYNC_AUTHETICATION_TYPE,
  aws_x_api_key: ENV.REACT_APP_X_API_KEY,
  Storage: {
    AWSS3: {
      bucket: ENV.REACT_APP_STORAGE_BUCKET,
      region: ENV.REACT_APP_REGION,
    },
  },
};
