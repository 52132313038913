import { Paper, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { auditLogsRoute, StatusRoute, transportOrdersRoute } from "../../../../app/modules/coinWrap/Helpers/Common/CommonComponent";
import DashboardOrderStatusCount from "./DashboardOrderStatusCount";
import "./style.css";
import TransportStatusCount from "./TransportStatusCount";


export function MixedWidget1({
  orderStatusCountData,
  timeRange,
  setTimeRange,
  totalActionLogCount,
  infoLogCount,
  transportOrderStatusCountData,
  isLoading
}) {
  const history = useHistory();

  return (
    <>
      <div className="dashboard_main_container">
        <DashboardOrderStatusCount
          startDate={timeRange?.startDate}
          endDate={timeRange?.endDate}
          setTimeRange={setTimeRange}
          orderStatusCountData={orderStatusCountData}
          isLoading={isLoading}
          onClickCard={() =>
            history.push(StatusRoute)
          }
        />
      </div>

      <TransportStatusCount
        transportOrderStatusCountData={transportOrderStatusCountData}
        onClickCard={() =>
          history.push(transportOrdersRoute)
        }
        isLoading={isLoading}
      />
      {isLoading ?
        <Paper className="mt-3 mb-5"
          sx={{
            borderRadius: "0.2rem",
            border: "none",
            width: "25.5rem",
            height: "130px",
          }}>
          <Skeleton animation="wave" height={"130px"} />
        </Paper>
        :
        <Paper
          className="mt-3 mb-5"
          sx={{
            padding: "1.5rem 1.5rem",
            borderRadius: "0.2rem",
            height: "130px",
            border: "none",
            width: "25.5rem",
            marginBottom: "125px",
            display: "flex",
            cursor: "pointer"
          }}
          onClick={() => history.push(auditLogsRoute)}
        >
          <div style={{ borderRight: "1.5px dashed #bebcbc", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                color: "#a5a5a5",
                textAlign: "center",
                fontSize: "1.1rem",
                border: "2px soild red",
              }}
            >
                Total Actions Performed
            </Typography>
            <Typography
              sx={{
                color: "rgba(80, 75, 75, 0.87)",
                textAlign: "center",
                fontSize: "2.0rem",
                fontWeight: 550,
                alignItems: "end"
              }}
            >
              {totalActionLogCount || 0}
            </Typography>


          </div>
          <div style={{ width: "50%", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                color: "green",
                textAlign: "center",
                fontSize: "1.1rem",
                border: "2px soild red"
              }}
            >
              <div>
                Total Info
              </div>
              Logs
            </Typography>
            <Typography
              sx={{
                color: "green",
                textAlign: "center",
                fontSize: "2.0rem",
                fontWeight: 550,
                alignItems: "end"
              }}
            >
              {infoLogCount || 0}
            </Typography>
          </div>
        </Paper >
      }
    </>
  );
}
