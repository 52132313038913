export const getOrdersHistoryQuery = `query getDashBoardData_mix(
 $startDate: AWSDateTime!
 $endDate: AWSDateTime!
 $inventoryOrder: String!
 $transportOrder: String!
 $filter:AWSJSON
 ) {
   transportOrders: getOrdersHistory(
      startDate: $startDate
      endDate: $endDate
      orderType: $transportOrder
   ) {
      TransportOrdersCountGroupedByDayAndStatus {
         Date
         orderStatus
         count
      }
   }

   inventoryOrders: getOrdersHistory(
      startDate: $startDate
      endDate: $endDate
      orderType: $inventoryOrder
   ) {
      InventoryOrdersCountGroupedByDayAndStatus {
         Date
         orderStatus
         count
      }
   }

   getAuditLogs(
      startDate: $startDate
      endDate: $endDate,
   ) {
      count
   }

   infoAuditlog:getAuditLogs(
      startDate: $startDate
      endDate: $endDate
       filter:$filter
   ) {
      count
   }
}`;











// $transportOrder: String!;
// getOrdersHistory(
//    startDate: $startDate
//     endDate: $endDate
//     orderType: $transportOrder
// ){
//    orderStatus;
//    count;
// }



