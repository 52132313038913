import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   isLoading: false,
   chartDateRange: [],
   transportOrderStatusDateWiseArr: []
};

export const DashboardSlice = createSlice({
   name: "dashboard",
   initialState: initialState,
   reducers: {
      setLoading: (state, action) => {
         state.isLoading = action.payload;
      },
      setChartDateRange: (state, action) => {
         state.chartDateRange = action.payload;
      },
      setTransportOrderStatusDateWiseArr: (state, action) => {
         state.transportOrderStatusDateWiseArr = action.payload;
      },
   },
});
