import React, { useMemo } from "react";
import { AsideMenuList } from "./AsideMenuList";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { Box, useMediaQuery } from "@mui/material";
import { version } from "../../../../../app/modules/coinWrap/Helpers/Common/CommonComponent";
import { useHistory } from "react-router-dom";

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);
  const history = useHistory();

  const matches = useMediaQuery("(min-width:992px)");

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu `}
        style={{ flex: 1, minHeight: "0px" }}
      // {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList layoutProps={layoutProps} />
      </div>
      <Box
        className='menu-item aside-item pl-5'
        aria-haspopup="true"
        sx={[{
          display: matches ? 'none' : 'flex',
          justifyContent: "center",
          alignItems: "flex-start",
          flexFlow: "column",
          height: "5%",
          color: "#aaa",
          backgroundColor: "#212121bf"

        }]}

      >
        <button className="btn btn-tranparent btn text-left" onClick={() => history.push("/logout")}>
          <i
            className={`fas fa-sign-out-alt svg-icon menu-icon`}
            style={{ fontSize: "1.3rem" }}
          ></i>
          <span className="menu-text" style={{ color: '#fff' }}>Sign Out</span>
        </button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexFlow: "column",
          height: "5%",
          color: "#aaa",
          backgroundColor: "#212121bf",
          fontStyle: "italic",
        }}
      >

        {version}
      </Box>
      {/* end::Menu Container */}
    </>
  );
}
