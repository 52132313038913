export const getTransportOrders = `query MyQuery($filter:TransportOrderFilter,$offset:Int,$limit:Int,$searchValue:String,$searchBy:[String],$sortField:String,$sortType:String,$transportOrderID:String,$tenderID:String) {
  getTransportOrders(filter:$filter,offset:$offset, limit:$limit, searchValue:$searchValue,searchBy:$searchBy, sortField:$sortField, sortType:$sortType,transportOrderID:$transportOrderID,tenderID:$tenderID) {
    count
    transportOrder {
      createdBy,
      createdOn,
      tenderID,
      totalOrderDimension,
      totalOrderSTC,
      totalOrderStops,
      totalOrderWeight,
      transportOrderID,
      transportOrderStatus,
      updatedBy,
      updatedOn,
      comments,
      pickupDate,
      hasBolOrGbl,
      TransportOrderStops {
        orderNumber
        shipmentDate
        shipmentTime
        stopDateTime
          stopType
          LocationTransport{
            Location {
            address
            address2
            businessDays
            createdBy
            createdOn
            isEnabled
            latitude
            locationCode
            locationName
            locationNumber
            locationTypes
            longitude
            region
            state
            timeZone
            updatedBy
            updatedOn
            zipCode
          }
        }
      },
      Carrier {
        ID
        address
        address2
        carrierID
        carrierName
        city
        createdOn
        createdBy
        zipCode
      }
    }
  }
}`;

export const checkCSV = ` query MyQuery ($fileName:String!) {
  s3FileExists(fileName:$fileName){
    status
  }
}
`;

export const getTransportOrderStopByID = `query MyQuery($tenderID:String!) {
  getTransportOrderStopsByTenderID(tenderID:$tenderID) {
    tenderID,
    pickupDate,
    endSolicitationTime,
    TransportOrderStops {
      locationCode
       LocationTransport {
        Location {
          businessDays
          createdBy
          createdOn
          isEnabled
          latitude
          locationCode
          locationName
          locationTypes
          longitude
          region
          state
          timeZone
          updatedBy
          updatedOn
          zipCode
        }
        updatedOn
        updatedBy
        unloadRequirements
        transportComments
        reviewFinalQuote
        pickupOnlyLocation
        locationCode
        equipment
        driverNotify
        dockWidth
        dockRequirements
        dockNotes
        dockLength
        dockHeight
        createdOn
        createdBy
        bidEmails
        bidApprovalEmails
      }
      createdOn
      ID
      createdBy
      TransportOrderProducts {
        productID
        productUnit
        productCategory
        Product{
          name
          totalValue
          type
          description
          weight
          weightMax
        }
      }
      shipmentDate
      shipmentTime
      stopDateTime
      stopNotes
      stopType
      updatedBy
      transportOrderID
      updatedOn
      orderNumber
    }
  }
}`;

export const getCarriersForQuery = `query MyQuery($transportOrderID:String) {
  getCarriersFor(transportOrderID:$transportOrderID) {
    count
    carrier {
      ID
      carrierID
      carrierName
      pocName
    }
  }
}`;

export const getInvitedCarriersForQuery = `query MyQuery($transportOrderID:String) {
  getInvitedCarriersFor(transportOrderID:$transportOrderID) {
    assignedCarrier {
      acknowledgeName
      quoteAmount
      quoteStatus
      cwiQuoteAmount
      acknowledgeStatus
      calcMarkupAmount
      carrierId
      createdBy
      createdOn
      inviteCode
      statusComment
      transportOrderID
      updatedBy
      updatedOn
      Carrier {
        zipCode
        updatedOn
        updatedBy
        state
        products
        pocPhone2
        pocPhone1
        pocName
        pocEmail
        pickupLocations
        isActive
        description
        dropoffLocations
        createdOn
        createdBy
        city
        carrierName
        carrierID
        address2
        address
        ID
      }
    }
    count
    tenderInvites {
      quoteStatus
      statusComment
      quoteAmount
      acknowledgeName
      acknowledgeStatus
      carrierId
      createdBy
      createdOn
      cwiQuoteAmount
      calcMarkupAmount
      CWIMarkupComment
      inviteCode
      transportOrderID
      updatedOn
      updatedBy
      Carrier {
        zipCode
        updatedOn
        updatedBy
        state
        products
        pocPhone2
        pocPhone1
        pocName
        pocEmail
        pickupLocations
        isActive
        description
        dropoffLocations
        createdOn
        createdBy
        city
        carrierName
        carrierID
        address2
        address
        ID
      }
    }
  }
}`;

export const getTenderInvitesQuery = `mutation MyMutation($carrierIds:[String]!,$transportOrderID:String!,$comment:String) {
  submitTenderQuote(carrierIds:$carrierIds, transportOrderID:$transportOrderID,comment:$comment)
  }`;

export const getInviteQuoteFromInviteCodeQuery = `query MyQuery($inviteCode:String ) {
  getInviteQuoteFromInviteCode(inviteCode:$inviteCode) {
    quoteStatus,
    minTruckRequirements
    cwiQuoteAmount 
    endSolicitationTime
    quoteAmount,
    isAlreadyResponded
      carrier {
        ID
        address
        address2
        carrierID
        carrierName
        city
        createdBy
        createdOn
        dropoffLocations
        description
        isActive
        pickupLocations
        pocName
        pocEmail
        pocPhone1
        pocPhone2
        products
        state
        updatedBy
        updatedOn
        zipCode
      }
      transportOrder {
        tenderID
        Carrier{
          ID
        }
        totalOrderDimension
        createdBy
        createdOn
        totalOrderSTC
        totalOrderStops
        totalOrderWeight
        transportOrderID
        transportOrderStatus
        updatedBy
        updatedOn
        comments
      }
      transportOrderStops {
              LocationTransport {
                  Location {
                    address
                    address2
                    businessDays
                    city
                    contactEmails
                    contactNumbers
                    createdBy
                    createdOn
                    description
                    isEnabled
                    latitude
                    locationCode
                    locationName
                    locationTypes
                    longitude
                    region
                    state
                    timeZone
                    updatedBy
                    updatedOn
                    zipCode
                  }
              updatedOn
              updatedBy
              unloadRequirements
              transportComments
              reviewFinalQuote
              pickupOnlyLocation
              locationCode
              equipment
              driverNotify
              dockWidth
              dockRequirements
              dockNotes
              dockLength
              dockHeight
              createdOn
              createdBy
              bidEmails
              bidApprovalEmails
            }
            ID
            createdBy
            createdOn
            locationCode
            TransportOrderProducts {
              productID
              productUnit
              productCategory
              Product{
                name
              }
            }
        shipmentDate
        shipmentTime
        stopDateTime
        stopNotes
        stopType
        transportOrderID
        updatedBy
        updatedOn
        orderNumber
      }
  }
  }`;

export const declineTenderQuoteQuery = `mutation MyMutation($inviteCode:String) {
  declineTenderQuote(inviteCode:$inviteCode) 
  {
   status
  }
  }`;

export const acceptTenderQuoteQuery = `mutation MyMutation($inviteCode:String,$quoteAmount:Float,$statusComment:String,$acknowledgeStatus:Boolean,$acknowledgeName:String) {
    acceptTenderQuote(inviteCode:$inviteCode,quoteAmount:$quoteAmount,statusComment:$statusComment,acknowledgeStatus:$acknowledgeStatus,acknowledgeName:$acknowledgeName) 
    {
     status
    }
    }`;

export const sendTenderQuoteToPickupMintQuery = `mutation MyMutation($carrierId:String!,$transportOrderID:String!,$CWIMarkupFees:Float,$CWIMarkupComment:String) {
  sendTenderQuoteToPickupMint(carrierId:$carrierId,transportOrderID:$transportOrderID,CWIMarkupFees:$CWIMarkupFees,CWIMarkupComment:$CWIMarkupComment) 
             {
               status
             }
          }`;

export const rejectTenderQuoteModalQuery = `mutation MyMutation($carrierId:String!,$transportOrderID:String!) {
  rejectTenderQuote(carrierId:$carrierId,transportOrderID:$transportOrderID) 
      {
       status
      }
      }`;

export const deleteTenderQuoteModalQuery = `mutation MyMutation($carrierId:String!,$transportOrderID:String!) {
  deleteTenderQuote(carrierId:$carrierId,transportOrderID:$transportOrderID) 
            {
             status
            }
            }`;

export const resendTenderQuoteModalQuery = `mutation MyMutation($carrierId:String!,$transportOrderID:String!) {
  resendQuoteInvite(carrierId:$carrierId,transportOrderID:$transportOrderID) 
            {
             status
            }
            }`;

export const driverFormsUploadedQuery = `mutation MyMutation($inviteCode:String!) {
  driverFormsUploaded(inviteCode:$inviteCode) 
              {
               status
              }
              }`;

export const updateTenderStatusModalQuery = `mutation MyMutation($comment:String,$inviteCode:String!,$status:String!,$hasBolOrGbl:Boolean,$TransportOrderFileInput:[TransportOrderFileInput]) {
  updateTenderStatus(comment:$comment,inviteCode:$inviteCode,status:$status,hasBolOrGbl:$hasBolOrGbl,TransportOrderFileInput:$TransportOrderFileInput)
             {
               status
             }
          }`;

export const updateCarrierTenderStatusQuery = `mutation MyMutation($comment:String!,$inviteCode:String!,$status:String!,$hasBolOrGbl:Boolean,$TransportOrderFileInput:[TransportOrderFileInput]) {
   updateCarrierTenderStatus(comment:$comment,inviteCode:$inviteCode,status:$status,hasBolOrGbl:$hasBolOrGbl,TransportOrderFileInput:$TransportOrderFileInput)
            {
               status
           }
             }`;

export const getOrderStatusCountsQuery = `query MyQuery($filter:TransportOrderFilter,$searchValue:String,$searchBy:[String],$transportOrderStatus:String) {
              getOrderStatusCounts(searchValue:$searchValue,searchBy:$searchBy,transportOrderStatus:$transportOrderStatus,filter:$filter) {
                count
                transportOrderStatus
              }
            }`;

export const cwiQuoteAmountQuery = `mutation MyMutation($inviteCode:String!,$newQuoteAmount:Float!) {
  cwiQuoteAmount(inviteCode:$inviteCode,newQuoteAmount:$newQuoteAmount) 
    {
     status
    }
    }`;

export const acceptCWIQuoteAmountQuery = `mutation MyMutation($inviteCode:String!) {
  acceptCWIQuoteAmount(inviteCode:$inviteCode) 
              {
               status
              }
              }`;

export const rejectCWIQuoteAmountQuery = `mutation MyMutation($inviteCode:String!,$reason:String!) {
  rejectCWIQuoteAmount(inviteCode:$inviteCode,reason:$reason) 
              {
               status
              }
              }`;

export const getAuditLogofTenderIDQuery = `query MyQuery ($offset:Int,$limit:Int,$filter:AWSJSON){
  getAuditLogs(offset:$offset, limit:$limit,filter:$filter
    ) {
      count
      AuditLogs {
        ID
        action
        createdBy
        createdOn
        device
        filter1
        filter3
        filter2
        logLevel
        request
        response
        textDesc 
    }
  }
}`;

export const getMintLocationsDropDownQuery = `query MyQuery($limit:Int) {
  getMintLocations(limit:$limit) {
    mintLocation {
      mintCode
      isMintLocation
      isEnabled
      mintName
    }
  }
}`;

export const getAllLocationDropDownQuery = `query MyQuery($limit: Int,$locationCode:String, $locationTypes: [String], $transportOrderID:String,$filter: AWSJSON) {
  getLocations(limit: $limit, locationCode:$locationCode,locationTypes: $locationTypes,transportOrderID:$transportOrderID,filter: $filter) {
    Location {
      locationCode
      locationName
      locationNumber
      locationTypes
      description
      address
      address2
      city
      state
      zipCode
      timeZone
      isEnabled
      region
      businessDays
      LocationInventory{
            Organizations{
               ID
               name
              isEnabled
            }
            Customers{
               ID
               customerName
            }
          }
      LocationTransport{
         dockRequirements
         dockHeight
         dockLength
         dockWidth
         dockNotes
         equipment
         unloadRequirements
         transportComments
         driverNotify
         pickupOnlyLocation
         bidEmails
         bidApprovalEmails
         reviewFinalQuote
      }
    }
  }
}`;

export const getCoinTypeProductsDropDownQuery = `query MyQuery($limit:Int) {
  getCoinTypeProducts(limit:$limit) {
    coinTypeProduct {
      productID
      isActive
    }
  }
}`;

export const createTransportOrderQuery = `mutation MyMutation ($TransportOrderInput:TransportOrderInput!) {
  createTransportOrder(TransportOrderInput:$TransportOrderInput) {
    tenderID
    transportOrderID
  }
}`;
export const getCurrentTransportOrderIDQuery = `query MyQuery {
      getCurrentTransportOrderID {
    transportOrderID
  }
}`;

export const updateTransportOrderQuery = `mutation MyMutation($transportOrderID:String!,$TransportOrderInput:TransportOrderUpdateInput!,$TransportOrderFileInput:[TransportOrderFileInput])
{
  updateTransportOrder(transportOrderID: $transportOrderID, TransportOrderInput:$TransportOrderInput,TransportOrderFileInput:$TransportOrderFileInput)
    {
      transportOrderStatus
      pickupDate
      hasBolOrGbl
    }
}`;

export const createS3UploaderQuery = `mutation MyMutation ($fileNames:[String]){
  createS3UploadUrl(fileNames: $fileNames) {
    key
    uploadUrl
  }
}`;

export const getMintLocationsQuery = `query MyQuery($offset:Int,$limit:Int,$searchValue:String,$searchBy:[String],$sortField:String,$sortType:String,$mintCode:String) {
  getMintLocations(offset:$offset, limit:$limit, searchValue:$searchValue,searchBy:$searchBy, sortField:$sortField,sortType:$sortType,mintCode:$mintCode) {
  count
  minTruckRequirements
  mintLocation {
    address,
    address2,
    city,
    createdBy,
    createdOn,
    isEnabled,
    mintCode,
    mintName,
    state,
    updatedBy,
    updatedOn,
    zipCode
    driverNotify
    unloadRequirements
    dockNotes
    businessDays
    equipment
    dockRequirements
    dockHeight
    dockLength
    dockWidth
    transportComments
    clearanceEmails
    bidEmails
    isMintLocation
    reviewFinalQuote
  }
}
}`;

//

export const resendTenderQuoteToPickupMintQuery = `mutation MyMutation($transportOrderID:String!)
{
  resendTenderQuoteToPickupMint(transportOrderID: $transportOrderID){
    status
    }
}`;

export const restoreTenderQuoteToInviteQuery = `mutation MyMutation($transportOrderID:String!,$stopsDate:[TransportOrderResetDateInput])
{
  resetTransportOrder(transportOrderID: $transportOrderID,stopsDate:$stopsDate){
    status
    }
}`;

export const resendEmailOnCarrierQuery = `mutation MyMutation($carrierId:String,$transportOrderID:String!) {
  resendEmailOnCarrier(carrierId:$carrierId, transportOrderID: $transportOrderID) {
    status
  }
}`;

export const updateTransportOrderStopsQuery = `mutation MyMutation($transportOrderID: String!,$deliveryStops:[DeliveryStops]!,$pickupStop:PickupStop!,$TenderInviteChange:TenderInviteChange){
  updateTransportOrderStops(transportOrderID: $transportOrderID,deliveryStops:$deliveryStops,pickupStop:$pickupStop,tenderInviteChange:$TenderInviteChange){
    status
  }
}`;
export const processFileQuery = `mutation MyMutation($fileName: String){
  processFile(fileName: $fileName){
    status
  }
}`;
export const getCommentsByInvitedIdsQuery = `query MyQuery ($inviteCode:String!){
  getTenderInviteComments(inviteCode:$inviteCode) {
    ID
    inviteCode
    comment
    quoteAmount
    cwiQuoteAmount
    user
    User{
        ID
        firstName
        lastName
      }
    createdOn
  }
}`;

export const getCommentsByCarrierInvitedIdQuery = `query MyQuery ($inviteCode:String!){
  getTenderInviteComments(inviteCode:$inviteCode) {
    inviteCode
    comment
    quoteAmount
    cwiQuoteAmount
    user
    createdOn
  }
}`;

export const createCommentsByInvitedIdsQuery = `mutation MyMutation($inviteCode:String!,$comment:String!) {
  createTenderInviteComment(inviteCode:$inviteCode, comment: $comment) {
    ID
    inviteCode
    comment
    quoteAmount
    cwiQuoteAmount
    user
    User{
        ID
        firstName
        lastName
      }
    createdOn
  }
}`;

export const exportTransportOrdersCsvQuery = `query MyQuery (
    $searchBy: [String]
    $searchValue: String
    $sortField: String
    $sortType: String
    $filter: TransportOrderFilter
){
  exportTransportOrdersCsv(searchBy:$searchBy,searchValue:$searchValue,sortField:$sortField,sortType:$sortType,filter:$filter)
}`;

export const updateTenderInviteAmountQuery = `mutation MyMutation(
    $inviteCode: String!
    $quoteAmount: Float!
    $bidAmount: Float!
){
  updateTenderInviteAmount(inviteCode:$inviteCode,quoteAmount:$quoteAmount,bidAmount:$bidAmount){
    status
  }
}`;

export const updateAllTransportOrderStopsQuery = `mutation Mymutation(
  $transportOrderID: String!
  $TransportOrderStops: [TransportOrderStopInput!]!
){
  updateTransportOrderStops(transportOrderID:$transportOrderID,TransportOrderStops:$TransportOrderStops){
    status
  }
}`;
