import { Dialog, DialogActions, DialogContent, useTheme } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { dashboardResetPassword } from "../../../../../app/modules/Auth/_redux/authCrud";
import { errorMessage } from "../../../../../app/modules/coinWrap/Helpers/Alert/messages";
import DialogCloseTitle from "../../../../../app/modules/coinWrap/Helpers/Dialog/DialogTitle";
import BootstrapButton from "../../../../../app/modules/coinWrap/Helpers/UI/Button/BootstrapButton";
import { generalSlice } from "../../../../../app/modules/coinWrap/_redux/general/generalSlice";

const schema = yup.object({
  oldPassword: yup.string().required("Enter Old Password"),
  password: yup
    .string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .trim()
    .strict(true)
    .required("Enter New Password"),
  passwordConfirmation: yup
    .string()
    .required("Enter Confirm Password")
    .trim()
    .strict(true)
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const UserChangePassword = ({
  setIsOpenModal,
  isOpenModal,
  setIsDropdownOpen,
}) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const { actions: generalActions } = generalSlice;
  const history = useHistory();
  const [showOldPass, setShowOldPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  const init = {
    oldPassword: "",
    password: "",
    passwordConfirmation: "",
  };

  // savePwd(values.oldPassword, values.password)
  // .then(() => {
  // closeModal({
  //   onHide,
  //   resetForm,
  // })();
  // })
  // .finally(() => {
  //   setSubmitting(false);
  // });

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={init}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          dashboardResetPassword(values.oldPassword, values.password)
            .then((data) => {
              dispatch(
                generalActions.pushNewAlert({
                  show: true,
                  heading: "Success",
                  message: "Your password has been reset!",
                  type: "success",
                })
              );
              setIsOpenModal(false);
              history.push("/logout");
            })
            .catch((error) => {
              dispatch(
                generalActions.pushNewAlert({
                  show: true,
                  heading: "Error",
                  errMessage: error.message,
                  message: errorMessage,
                  type: "danger",
                })
              );
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          isValid,
          isInitialValid,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Dialog
            open={isOpenModal}
            scroll={"paper"}
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
              style: { maxHeight: "500px" },
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <DialogCloseTitle
                onClose={() => {
                  setIsOpenModal(false);
                  setIsDropdownOpen(false);
                }}
                disableTypography={true}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                isCloseButtonDisabled={isSubmitting}
              >
                <div
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                  }}
                >
                  Change Password
                </div>
              </DialogCloseTitle>
              <DialogContent dividers={true}>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="loginForm.password">
                      <Form.Label style={{ fontWeight: 600 }}>
                        Old Password
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showOldPass ? "text" : "password"}
                          name="oldPassword"
                          value={values.oldPassword}
                          onChange={handleChange}
                          autoComplete="new-password"
                          onBlur={handleBlur}
                          isInvalid={touched.oldPassword && errors.oldPassword}
                        />

                        <InputGroup.Append>
                          <InputGroup.Text
                            onClick={() => setShowOldPass((prev) => !prev)}
                          >
                            <i
                              className={
                                showOldPass ? "fas fa-eye-slash" : "fas fa-eye"
                              }
                            ></i>
                          </InputGroup.Text>
                        </InputGroup.Append>

                        <Form.Control.Feedback type="invalid">
                          {errors.oldPassword}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="loginForm.password">
                      <Form.Label style={{ fontWeight: 600 }}>
                        New Password
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showPass ? "text" : "password"}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          autoComplete="new-password"
                          onBlur={handleBlur}
                          isInvalid={touched.password && errors.password}
                        />

                        <InputGroup.Append>
                          <InputGroup.Text
                            onClick={() => setShowPass((prev) => !prev)}
                          >
                            <i
                              className={
                                showPass ? "fas fa-eye-slash" : "fas fa-eye"
                              }
                            ></i>
                          </InputGroup.Text>
                        </InputGroup.Append>

                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group controlId="loginForm.passwordConfirmation">
                      <Form.Label style={{ fontWeight: 600 }}>
                        Confirm Password
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showPassConfirm ? "text" : "password"}
                          name="passwordConfirmation"
                          value={values.passwordConfirmation}
                          onChange={handleChange}
                          autoComplete="new-password"
                          onBlur={handleBlur}
                          isInvalid={
                            touched.passwordConfirmation &&
                            errors.passwordConfirmation
                          }
                        />

                        <InputGroup.Append>
                          <InputGroup.Text
                            onClick={() => setShowPassConfirm((prev) => !prev)}
                          >
                            <i
                              className={
                                showPassConfirm
                                  ? "fas fa-eye-slash"
                                  : "fas fa-eye"
                              }
                            ></i>
                          </InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">
                          {errors.passwordConfirmation}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsOpenModal(false);
                    setIsDropdownOpen(false);
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>

                <BootstrapButton
                  variant="success"
                  type="submit"
                  label="Change Password"
                  labelWhenSubmitting="Changing"
                  isSubmitting={isSubmitting}
                  onClick={handleSubmit}
                />
              </DialogActions>
            </Form>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default UserChangePassword;
