import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAlerts: [],
  key: "",
};

export const generalSlice = createSlice({
  name: "general",
  initialState: initialState,
  reducers: {
    setallAlerts: (state, action) => {
      state.allAlerts = action.payload;
    },
    pushNewAlert: (state, action) => {
      state.allAlerts = [
        ...state.allAlerts,
        { ...action.payload, id: new Date().getTime() },
      ];
    },
    deleteAlert: (state, { payload }) => {
      const newAlerts = state.allAlerts.filter((a) => {
        return a.id !== payload;
      });
      state.allAlerts = newAlerts;
    },
    setSettingTabKey: (state, { payload }) => {
      state.key = payload;
    },
  },
});
