import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword, resetPassword } from "../_redux/authCrud";
import CustomAlert from "../../../modules/coinWrap/Helpers/Alert/CustomAlert";
import { Backdrop, CircularProgress } from "@mui/material";
import { InputGroup } from "react-bootstrap";

const initialValues = {
  email: "",
};

const newinitialValues = {
  email: "",
  code: "",
  new_Password: "",
  confirm_password: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [msg, setAlertMsg] = useState(false);
  const [heading, setHeading] = useState(false);
  const [type, setType] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const resetEmail = params.get("email");
  const resetCode = params.get("code");
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
  const NewPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    code: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    new_Password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      // .matches(/[A-Z]/, 'At least one uppercase letter is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number"
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirm_password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .matches(/[A-Z]/, 'At least one uppercase letter is required')
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .oneOf([Yup.ref("new_Password"), null], "Passwords must match"),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  useEffect(() => {
    if (resetEmail && resetCode) {
      setIsRequested(true);
      newFormik.setFieldValue("email", decodeURIComponent(resetEmail));
      newFormik.setFieldValue("code", resetCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetEmail, resetCode]);
  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      requestPassword(values.email).then(
        (res) => {
          setLoading(false);
          if (res) {
            newFormik.resetForm();
            newFormik.setFieldValue("email", values.email);
            setIsRequested(true);
            setSubmitting(false);
            showAlert("Please check your email address.", "success", "Success");
          }
        },
        (error) => {
          setSubmitting(false);
          setLoading(false);
          showAlert(error.message, "danger", "Error!");
        }
      );
    },
  });

  const showAlert = (msg, type, heading) => {
    setAlert(true);
    setAlertMsg(msg);
    setType(type);
    setHeading(heading);
  };

  const newFormik = useFormik({
    initialValues: newinitialValues,
    validationSchema: NewPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      resetPassword(values).then(
        async (res) => {
          await setLoading(false);
          if (res) {
            setSubmitting(false);
            showAlert("Your password has been reset!", "success", "Success");
            setTimeout(() => {
              history.replace("/auth");
            }, 2000);
          }
        },
        (error) => {
          setSubmitting(false);
          setIsRequested(false);
          setLoading(false);
          showAlert(error.message, "danger", "Error!");
        }
      );
    },
  });

  const NewPasswordForm = () => {
    return (
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Reset Password !</h3>
          {/* <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div> */}
        </div>
        <form
          onSubmit={newFormik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          autoComplete="off"
        >
          {newFormik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {newFormik.status}
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...newFormik.getFieldProps("email")}
              placeholder="Email"
              disabled
              hidden={resetEmail}
            />
            {newFormik.touched.email && newFormik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{newFormik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="code"
              {...newFormik.getFieldProps("code")}
              placeholder="Confirmation Code"
              disabled={resetCode}
              hidden={resetCode}
            />
            {newFormik.touched.code && newFormik.errors.code ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{newFormik.errors.code}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <InputGroup>
              <input
                type={showNewPass ? "text" : "password"}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "new_Password"
                )}`}
                name="new_Password"
                {...newFormik.getFieldProps("new_Password")}
                placeholder="New Password"
                autoComplete="new-password"
              />
              <InputGroup.Append>
                <InputGroup.Text
                  onClick={() => setShowNewPass((prev) => !prev)}
                  style={{ backgroundColor: "#f3f6f9" }}
                >
                  <i
                    className={showNewPass ? "fas fa-eye-slash" : "fas fa-eye"}
                  ></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {newFormik.touched.new_Password && newFormik.errors.new_Password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {newFormik.errors.new_Password}
                </div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <InputGroup>
              <input
                type={showConfirmPass ? "text" : "password"}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirm_password"
                )}`}
                name="confirm_password"
                {...newFormik.getFieldProps("confirm_password")}
                placeholder="Confirm Password"
                autoComplete="new-password"
              />
              <InputGroup.Append>
                <InputGroup.Text
                  onClick={() => setShowConfirmPass((prev) => !prev)}
                  style={{ backgroundColor: "#f3f6f9" }}
                >
                  <i
                    className={
                      showConfirmPass ? "fas fa-eye-slash" : "fas fa-eye"
                    }
                  ></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {newFormik.touched.confirm_password &&
              newFormik.errors.confirm_password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {newFormik.errors.confirm_password}
                </div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={newFormik.isSubmitting}
            >
              <span>Submit</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    );
  };
  return (
    <>
      {alert && (
        <CustomAlert
          show={alert}
          setShow={(id) => {
            setAlert(false);
          }}
          id={1}
          type={type}
          message={msg}
          heading={heading}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <>
        {isRequested && <>{NewPasswordForm()}</>}
        {!isRequested && (
          <div className="login-form login-forgot" style={{ display: "block" }}>
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">Forgotten Password ?</h3>
              <div className="text-muted font-weight-bold">
                Enter your email to reset your password
              </div>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              autoComplete="off"
            >
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}
              <div className="form-group fv-plugins-icon-container">
                <input
                  type="email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                  placeholder="Email"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  disabled={formik.isSubmitting}
                >
                  Submit
                </button>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        )}
      </>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
