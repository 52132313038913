import ReplayIcon from "@mui/icons-material/Replay";
import { Fab, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDashBoardData } from "../../../app/modules/coinWrap/_redux/Dashboard/DashboardCrud";
import { auditLogsSearchKeys, inventoryOrderStatusType, transportOrderStatusObj } from "../../../app/modules/coinWrap/Helpers/Common/CommonComponent";
import { MixedWidget1 } from "../widgets/mixed/MixedWidget1";
import moment from "moment";
import Pending_approval from "../../../assets/Pending_approval.svg";
import Cancelled from "../../../assets/Cancelled.svg";
import Completed from "../../../assets/Completed.svg";
import Pending_Release from "../../../assets/Pending_Release.svg";
import Pending_Receive from "../../../assets/Pending_Receive.svg"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DashboardSlice } from "../../../app/modules/coinWrap/_redux/Dashboard/DashboardSlice";
import { groupBy } from "lodash";

const orderStatus = [
  {
    status: "Pending Approval",
    count: 0,
    icon: Pending_approval
  },
  {
    status: "Pending Release",
    count: 0,
    icon: Pending_Release
  },
  {
    status: "Pending Receive",
    count: 0,
    icon: Pending_Receive
  },
  {
    status: "Completed",
    count: 0,
    icon: Completed
  },
  {
    status: "Cancelled",
    count: 0,
    icon: Cancelled
  },
];

const transportOrderStatus = [
  {
    status: "Pending Invite",
    count: 0,
    icon: ""
  },
  {
    status: "Pending Quote",
    count: 0,
    icon: ""
  },
  {
    status: "Pending Award",
    count: 0,
    icon: ""
  },
  {
    status: "Pending Approval",
    count: 0,
    icon: ""
  },
  {
    status: "Confirmed",
    count: 0,
    icon: ""
  },
  {
    status: "Order In Process",
    count: 0,
    icon: ""
  },
  {
    status: "Order Completed",
    count: 0,
    icon: ""
  },
  {
    status: "Order Cancelled",
    count: 0,
    icon: ""
  },
];

export function Dashboard() {
  const dispatch = useDispatch();
  const { actions } = DashboardSlice;
  const {
    isLoading
  } = useSelector(
    (state) => ({
      isLoading: state.dashboard.isLoading,
    }),
    shallowEqual
  );
  moment.updateLocale('en', { week: { dow: 1 } })
  const [timeRange, setTimeRange] = useState({
    startDate: moment().startOf("week"),
    endDate: moment().endOf("day"),
  });
  const [orderStatusCountData, setOrderStatusCountData] = useState(orderStatus);
  const [transportOrderStatusCountData, setTransportOrderStatusData] = useState(transportOrderStatus);
  const [totalActionLogCount, setTotalActionLogCount] = useState(0);
  const [infoLogCount, setInfoLogCount] = useState(0);
  useEffect(() => {
    setOrderStatusCountData(orderStatus);
    setTransportOrderStatusData(transportOrderStatus)
    getOrderStatusData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange]);


  const getOrderStatusData = async () => {
    dispatch(actions.setLoading(true));
    try {
      const res = await getDashBoardData({
        transportOrder: "TRANSPORT_ORDER",
        inventoryOrder: "INVENTORY_ORDER",
        searchBy: auditLogsSearchKeys,
        startDate: timeRange?.startDate?.toDate(),
        endDate: timeRange?.endDate?.toDate(),
        filter: JSON.stringify({ logLevel: ["INFO"] }),
      });
      const totalActionData = res.data.getAuditLogs?.count || 0;
      const infoLogActionData = res.data.infoAuditlog?.count || 0;
      const orderStatusData = res.data.inventoryOrders?.InventoryOrdersCountGroupedByDayAndStatus || [];
      const transportOrderStatusData = res.data.transportOrders?.TransportOrdersCountGroupedByDayAndStatus || [];

      setTotalActionLogCount(totalActionData);
      setInfoLogCount(infoLogActionData);


      //make statusObj that contain all key of orderStatus value of sum of that key count //because we get date wise data   
      const ordersAllData = {};
      for (let i = 0; i < orderStatusData?.length; i++) {
        const key = orderStatusData[i]?.orderStatus;
        if (ordersAllData[key]) {
          ordersAllData[key] += orderStatusData[i]?.count;
        } else {
          ordersAllData[key] = orderStatusData[i]?.count;
        }
      }

      const orderStatusDataMergeCount = Object.entries(ordersAllData).map(([key, value]) => ({ orderStatus: key, count: value }));
      if (orderStatusDataMergeCount?.length) {
        orderStatusDataMergeCount.forEach((item) => {
          setOrderStatusCountData((prev) => {
            const temp = JSON.parse(JSON.stringify(prev));
            const avaliableStatusArr = temp?.map((v) => v?.status);
            if (avaliableStatusArr?.includes(inventoryOrderStatusType[item.orderStatus])) {
              temp.forEach((data) => {
                if (inventoryOrderStatusType[item.orderStatus] === data.status) {
                  data.count = item.count;
                }
              });
            } else {
              temp.forEach((data) => {
                if (!avaliableStatusArr?.includes(inventoryOrderStatusType[item.orderStatus]) && data?.count > 0) {
                  data.count = 0;
                }
              });
            }
            return temp;
          });
        });
      } else {
        setOrderStatusCountData(orderStatus);
      }


      const reansportOrdersStatusAllData = {};
      for (let i = 0; i < transportOrderStatusData?.length; i++) {
        const key = transportOrderStatusData[i]?.orderStatus;
        if (reansportOrdersStatusAllData[key]) {
          reansportOrdersStatusAllData[key] += transportOrderStatusData[i]?.count;
        } else {
          reansportOrdersStatusAllData[key] = transportOrderStatusData[i]?.count;
        }
      }

      const transportOrderStatusDataMergeCount = Object.entries(reansportOrdersStatusAllData).map(([key, value]) => ({ orderStatus: key, count: value }));
      if (transportOrderStatusDataMergeCount?.length) {
        transportOrderStatusDataMergeCount.forEach((item) => {
          setTransportOrderStatusData((prev) => {
            const temp = JSON.parse(JSON.stringify(prev));
            temp.forEach((data) => {
              if (transportOrderStatusObj[item.orderStatus] === data.status) {
                data.count = item.count;
              }
              // if (data.count > 0 && !temp?.find((v) => transportOrderStatusObj[item.orderStatus] === v.status)) {
              //   data.count = 0;
              // }
            });
            return temp;
          });
        });
      } else {
        setTransportOrderStatusData(transportOrderStatus);
      }
      //adteWise data
      handleTransportOrderDataDateWise(transportOrderStatusData);
      dispatch(actions.setLoading(false));
    } catch (error) {
      console.warn(error);
      dispatch(actions.setLoading(false));
    }
  };

  const getDatesBetween = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      dates.push(moment(currentDate).format("MM-DD"));
      currentDate.setDate(currentDate.getDate() + 1); 
    }
    dispatch(actions.setChartDateRange(dates));
    return dates;
  };


  const handleTransportOrderDataDateWise = (transportOrderStatusData) => {
    const startDate = new Date(timeRange?.startDate);
    const endDate = new Date(timeRange?.endDate);

    const dateArr = getDatesBetween(startDate, endDate);
    const data = transportOrderStatusData?.map((v) => {
      return { ...v, Date: moment(v?.Date).format("MM-DD") };
    });

    const transportOrderDataGroupByDate = groupBy(data, "Date");

    const dateWiseTrasportOrderStatusArr = [];
    for (let i = 0; i < transportOrderStatus?.length; i++) {
      const singleStatusData = [];
      for (let j = 0; j < dateArr?.length; j++) {
        const data = transportOrderDataGroupByDate[dateArr[j]]?.find((v) => transportOrderStatusObj[v?.orderStatus] === transportOrderStatus[i]?.status)?.count || 0;
        singleStatusData.push(data);
      }
      dateWiseTrasportOrderStatusArr.push(singleStatusData);
    }

    dispatch(actions.setTransportOrderStatusDateWiseArr(dateWiseTrasportOrderStatusArr));
  }

  return (
    <div style={{ padding: "15px 15px 15px 15px" }}>
      <MixedWidget1
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        orderStatusCountData={orderStatusCountData}
        totalActionLogCount={totalActionLogCount}
        infoLogCount={infoLogCount}
        transportOrderStatusCountData={transportOrderStatusCountData}
        isLoading={isLoading}
      />
      <Tooltip title="Reload Dashboard" placement="left">
        <Fab
          aria-label="add"
          style={{
            position: "absolute",
            bottom: "2%",
            right: "1%",
            zIndex: 999,
            backgroundColor: "black",
            color: "white",
          }}
          onClick={getOrderStatusData}
        >
          <ReplayIcon />
        </Fab>
      </Tooltip>
    </div >
  );
}
