import { Amplify, API } from "aws-amplify";
import { AWSConfig } from "../../../../aws-exports";
import {
  acceptCWIQuoteAmountQuery,
  acceptTenderQuoteQuery,
  checkCSV,
  createCommentsByInvitedIdsQuery,
  createS3UploaderQuery,
  createTransportOrderQuery,
  cwiQuoteAmountQuery,
  declineTenderQuoteQuery,
  deleteTenderQuoteModalQuery,
  driverFormsUploadedQuery,
  exportTransportOrdersCsvQuery,
  getAllLocationDropDownQuery,
  getAuditLogofTenderIDQuery,
  getCarriersForQuery,
  getCoinTypeProductsDropDownQuery,
  getCommentsByCarrierInvitedIdQuery,
  getCommentsByInvitedIdsQuery,
  getCurrentTransportOrderIDQuery,
  getInvitedCarriersForQuery,
  getInviteQuoteFromInviteCodeQuery,
  getMintLocationsDropDownQuery,
  getOrderStatusCountsQuery,
  getTenderInvitesQuery,
  getTransportOrders,
  getTransportOrderStopByID,
  processFileQuery,
  rejectCWIQuoteAmountQuery,
  rejectTenderQuoteModalQuery,
  resendEmailOnCarrierQuery,
  resendTenderQuoteModalQuery,
  resendTenderQuoteToPickupMintQuery,
  restoreTenderQuoteToInviteQuery,
  sendTenderQuoteToPickupMintQuery,
  updateAllTransportOrderStopsQuery,
  updateCarrierTenderStatusQuery,
  updateTenderInviteAmountQuery,
  updateTenderStatusModalQuery,
  updateTransportOrderQuery,
  updateTransportOrderStopsQuery
} from "../../../../Queries/transportOrdersQuery";

export const getTransportOrdersList = (
  status,
  page,
  dataPerPage,
  searchValue,
  searchBy,
  sortField,
  sortType,
  transportOrderID,
  tenderID
) => {
  let variables = {
    offset: page,
    limit: dataPerPage,
    searchBy: searchBy,
    sortField: sortField,
    sortType: sortType,
    transportOrderID: transportOrderID,
    tenderID: tenderID
  };
  if (status) {
    variables.filter = status;
  }
  if (searchValue) {
    variables.searchValue = searchValue.trim();
  }
  // console.log(veriables);
  return API.graphql({
    query: getTransportOrders,
    variables: variables,
    // variables: {
    //   filter: status,
    //   offset: page,
    //   limit: dataPerPage,
    //   searchValue: searchValue,
    //   searchBy: searchBy,
    //   sortField: sortField,
    //   sortType: sortType,
    //   transportOrderID: transportOrderID,
    // },
  });
};

export const checkCSVInS3 = (uploadedFileName) => {
  return API.graphql({
    query: checkCSV,
    variables: { fileName: uploadedFileName },
  });
};

export const getTransportStopByID = (tenderID) => {
  return API.graphql({
    query: getTransportOrderStopByID,
    variables: {
      tenderID: tenderID,
    },
  });
};

export const getCarriersForList = (transportOrderID) => {
  return API.graphql({
    query: getCarriersForQuery,
    variables: {
      transportOrderID: transportOrderID,
    },
  });
};

export const getInvitedCarriersForList = (transportOrderID) => {
  return API.graphql({
    query: getInvitedCarriersForQuery,
    variables: {
      transportOrderID: transportOrderID,
    },
  });
};

export const getTenderInvites = (carrierIds, transportOrderID, comment) => {
  return API.graphql({
    query: getTenderInvitesQuery,
    variables: {
      carrierIds: carrierIds,
      transportOrderID: transportOrderID,
      comment
    },
  });
};

export const getInviteQuoteFromInviteCode = (inviteCode, isInProcess) => {
  try {
    setIdTokenAsHeader();
    return API.graphql({
      query: getInviteQuoteFromInviteCodeQuery,
      variables: {
        inviteCode: inviteCode,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const driverFormsUploadedCrud = (inviteCode, isCwiUser) => {
  try {
    if (!isCwiUser) {
      setIdTokenAsHeader();
    }
    return API.graphql({
      query: driverFormsUploadedQuery,
      variables: {
        inviteCode: inviteCode,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

function setIdTokenAsHeader() {
  try {
    Amplify.configure({
      API: {
        graphql_endpoint: AWSConfig.aws_appsync_graphqlEndpoint,
        graphql_headers: async () => ({
          "x-api-key": AWSConfig.aws_x_api_key,
        }),
      },
    });
    return true;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export const declineTenderQuote = (inviteCode) => {
  return API.graphql({
    query: declineTenderQuoteQuery,
    variables: {
      inviteCode: inviteCode,
    },
  });
};

export const acceptTenderQuote = (data) => {
  return API.graphql({
    query: acceptTenderQuoteQuery,
    variables: {
      inviteCode: data.inviteCode,
      quoteAmount: data.quoteAmount,
      statusComment: data.statusComment,
      acknowledgeStatus: data.acknowledgeStatus,
      acknowledgeName: data.acknowledgeName,
    },
  });
};

export const sendTenderQuoteToPickupMintModal = (
  carrierId,
  transportOrderID,
  CWIMarkupFees,
  CWIMarkupComment
) => {
  return API.graphql({
    query: sendTenderQuoteToPickupMintQuery,
    variables: {
      carrierId: carrierId,
      transportOrderID: transportOrderID,
      CWIMarkupFees: CWIMarkupFees,
      CWIMarkupComment: CWIMarkupComment,
    },
  });
};

export const rejectTenderQuoteModal = (carrierId, transportOrderID) => {
  return API.graphql({
    query: rejectTenderQuoteModalQuery,
    variables: {
      carrierId: carrierId,
      transportOrderID: transportOrderID,
    },
  });
};

export const deleteTenderQuoteModal = (carrierId, transportOrderID) => {
  return API.graphql({
    query: deleteTenderQuoteModalQuery,
    variables: {
      carrierId: carrierId,
      transportOrderID: transportOrderID,
    },
  });
};

export const resendTenderQuoteModal = (carrierId, transportOrderID) => {
  return API.graphql({
    query: resendTenderQuoteModalQuery,
    variables: {
      carrierId: carrierId,
      transportOrderID: transportOrderID,
    },
  });
};

export const updateTenderStatusModal = (data, hasBolOrGbl) => {
  return API.graphql({
    query: updateTenderStatusModalQuery,
    variables: {
      comment: data.comment,
      inviteCode: data.inviteCode,
      status: data.status,
      hasBolOrGbl,
      TransportOrderFileInput: data.TransportOrderFileInput
    },
  });
};

export const updateCarrierTenderStatusCrud = (data, hasBolOrGbl) => {
  return API.graphql({
    query: updateCarrierTenderStatusQuery,
    variables: {
      comment: data.comment,
      inviteCode: data.inviteCode,
      status: data.status,
      hasBolOrGbl,
      TransportOrderFileInput: data.TransportOrderFileInput
    },
  });
};

export const getOrderStatusCountsCrud = (
  filter,
  searchValue,
  searchBy,
  transportOrderStatus
) => {
  let trimSearchValue = searchValue && searchValue.trim();

  return API.graphql({
    query: getOrderStatusCountsQuery,
    variables: {
      filter: filter,
      searchValue: trimSearchValue,
      searchBy: searchBy,
      transportOrderStatus: transportOrderStatus,
    },
  });
};

export const cwiQuoteAmountCrud = (data) => {
  return API.graphql({
    query: cwiQuoteAmountQuery,
    variables: {
      inviteCode: data.inviteCode,
      newQuoteAmount: data.newQuoteAmount,
    },
  });
};

export const acceptCWIQuoteAmountCrud = (inviteCode) => {
  try {
    setIdTokenAsHeader();
    return API.graphql({
      query: acceptCWIQuoteAmountQuery,
      variables: {
        inviteCode: inviteCode,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const rejectCWIQuoteAmountCrud = (data) => {
  try {
    setIdTokenAsHeader();
    return API.graphql({
      query: rejectCWIQuoteAmountQuery,
      variables: {
        inviteCode: data.inviteCode,
        reason: data.comment,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getAuditLogofTenderID = (page, dataPerPage, filter) => {
  return API.graphql({
    query: getAuditLogofTenderIDQuery,
    variables: {
      offset: page,
      limit: Number(dataPerPage),
      filter: filter,
    },
  });
};

export const getMintLocationsDropDownCrud = () => { //Delete--
  return API.graphql({
    query: getMintLocationsDropDownQuery,
    variables: {
      limit: 5000,
    },
  });
};

export const getAllLocationDropDownCrud = (limit, code, type, transportOrderID, filter) => {
  return API.graphql({
    query: getAllLocationDropDownQuery,
    variables: {
      limit: limit,
      locationCode: code,
      locationTypes: type,
      transportOrderID,
      filter
    },
  });
};

export const getCoinTypeProductsDropDownCrud = () => {
  return API.graphql({
    query: getCoinTypeProductsDropDownQuery,
    variables: {
      limit: 5000,
    },
  });
};

export const createTransportOrderCrud = (
  TransportOrderInput
) => {
  return API.graphql({
    query: createTransportOrderQuery,
    variables: {
      TransportOrderInput: TransportOrderInput,
    },
  });
};

export const getCurrentTransportOrderIDCrud = () => {
  return API.graphql({
    query: getCurrentTransportOrderIDQuery,
  });
};

export const updateTenderStatusCrud = (id, value, data, TransportOrderFileInput) => {
  let TransportOrderInput = {
    ...data,
  };
  if (value) {
    TransportOrderInput.transportOrderStatus = value.transportOrderStatus;
  }
  return API.graphql({
    query: updateTransportOrderQuery,
    variables: {
      transportOrderID: id,
      TransportOrderInput: TransportOrderInput,
      TransportOrderFileInput: TransportOrderFileInput
    },
  });
};

export const createS3UploadUrlCrud = (fileNames) => {
  return API.graphql({
    query: createS3UploaderQuery,
    variables: {
      fileNames: fileNames,
    },
  });
};

// export const getMintLocationsListByCodeCrud = ({ //##Delete---
//   page,
//   dataPerPage,
//   searchValue,
//   searchBy,
//   sortField,
//   sortType,
//   mintCode,
// }) => {
//   return API.graphql({
//     query: getMintLocationsQuery,
//     variables: {
//       offset: page,
//       limit: dataPerPage,
//       searchValue: searchValue,
//       searchBy: searchBy,
//       sortField: sortField,
//       sortType: sortType,
//       mintCode: mintCode,
//     },
//   });
// };

//
export const resendTenderQuoteToPickupMintCrud = (transportOrderID) => {
  return API.graphql({
    query: resendTenderQuoteToPickupMintQuery,
    variables: {
      transportOrderID: transportOrderID,
    },
  });
};

export const resetTransportOrderCrud = (transportOrderID, TransportOrderResetDateInput) => {
  return API.graphql({
    query: restoreTenderQuoteToInviteQuery,
    variables: {
      transportOrderID: transportOrderID,
      stopsDate: TransportOrderResetDateInput,
    },
  });
};
export const resendEmailOnCarrierCrud = (carrierId, transportOrderID) => {
  return API.graphql({
    query: resendEmailOnCarrierQuery,
    variables: {
      transportOrderID,
      carrierId,
    },
  });
};

export const updateTransportOrderStops = (data) => {
  return API.graphql({
    query: updateTransportOrderStopsQuery,
    variables: {
      transportOrderID: data.transportOrderID,
      deliveryStops: data.deliveryStops,
      pickupStop: data.pickupStop,
      TenderInviteChange: data.TenderInviteChange,
    },
  });
};
export const processFileCheck = (fileName) => {
  return API.graphql({
    query: processFileQuery,
    variables: {
      fileName: fileName,
    },
  });
};
export const getCommentsByInvitedIds = (inviteCode) => {
  return API.graphql({
    query: getCommentsByInvitedIdsQuery,
    variables: {
      inviteCode: inviteCode,
    },
  });
};

export const getCommentsByCarrierInvitedId = (inviteCode) => {
  return API.graphql({
    query: getCommentsByCarrierInvitedIdQuery,
    variables: {
      inviteCode: inviteCode,
    },
  });
};

export const createCommentsByInvitedIds = (inviteCode, comment) => {
  return API.graphql({
    query: createCommentsByInvitedIdsQuery,
    variables: {
      inviteCode: inviteCode,
      comment: comment
    },
  });
};

export const exportTransportOrdersCsv = ({ searchBy, searchValue, sortField, sortType, filter }) => {
  return API.graphql({
    query: exportTransportOrdersCsvQuery,
    variables: {
      searchBy: searchBy,
      searchValue: searchValue,
      sortField: sortField,
      sortType: sortType,
      filter: filter
    },
  });
};

export const updateTenderInviteAmount = (value) => {
  return API.graphql({
    query: updateTenderInviteAmountQuery,
    variables: {
      inviteCode: value.inviteCode,
      quoteAmount: value.quoteAmount,
      bidAmount: value.bidAmount
    }
  });
};

export const updateAllTransportOrderStops = (data) => {
  return API.graphql({
    query: updateAllTransportOrderStopsQuery,
    variables: {
      transportOrderID: data.transportOrderID,
      TransportOrderStops: data.TransportOrderStops
    }
  });
};
