import { API } from "aws-amplify";
import {
  getAuditLog,
  getAuditLogEventListQuery,
} from "../../../../Queries/auditLogsQuery";

export const getAuditLogsList = (
  page,
  dataPerPage,
  searchValue,
  searchBy,
  filter,
  startDate,
  endDate
) => {
  let searchValueTrim = searchValue && searchValue.trim();
  return API.graphql({
    query: getAuditLog,
    variables: {
      offset: page,
      limit: Number(dataPerPage),
      searchValue: searchValueTrim,
      searchBy: searchBy,
      filter: filter,
      startDate: startDate,
      endDate: endDate,
    },
  });
};

export const getAuditLogEventList = () => {
  return API.graphql({
    query: getAuditLogEventListQuery,
    variables: {},
  });
};
