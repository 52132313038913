import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import noResult from "../../../../../../assets/noResult.svg";
import TablePaginationActions from "../../../Helpers/TablePagination/TablePaginationActions";
import { AuditLogsSlice } from "../../../_redux/AuditLogs/AuditLogsSlice";

const excludeKeys = [
  // "device",
  "ID",
  "action",
  "createdBy",
  "createdOn",
  "filter1",
  "filter2",
  "filter3",
  "logLevel",
];

const AuditLogsTable = ({
  allAuditLogs,
  page,
  dataCount,
  dataPerPage,
  offset,
  state,
  setState
}) => {
  const dispatch = useDispatch();
  const { actions } = AuditLogsSlice;

  const [data, setData] = useState([]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < allAuditLogs.length; i++) {
      arr.push({
        ...allAuditLogs[i],
        request: JSON.parse(allAuditLogs[i]?.request),
        response: JSON.parse(allAuditLogs[i]?.response),
      });
    }
    setData(arr);
  }, [allAuditLogs]);

  const handleSetPage = (newPage) => {
    dispatch(actions.setPageConfigData({ type: "SET_IS_LOADING", data: true }));
    dispatch(
      actions.setPageConfigData({
        type: "SET_OFFSET",
        data: newPage * dataPerPage,
      })
    );
  };

  const handleNoOfRowsPerPage = (value) => {
    dispatch(actions.setPageConfigData({ type: "SET_IS_LOADING", data: true }));
    dispatch(
      actions.setPageConfigData({
        type: "SET_DATA_PER_PAGE",
        data: parseInt(value, 10),
      })
    );
    dispatch(actions.setPageConfigData({ type: "SET_OFFSET", data: 0 }));
  };

  const accordianHandler = (index) => () => {
    const { expanded } = state;
    const isExpanded = expanded.includes(index);
    setState({
      expanded: isExpanded
        ? expanded.filter((item) => item !== index)
        : [...expanded, index],
    });
  };
  const { expanded } = state;
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 500,
          }}
          aria-label="custom pagination table"
          size="small"
          stickyHeader={true}
        >
          <TableBody>
            {data?.length !== 0 ? (
              data?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    sx={{
                      padding: "2px !important",
                      border: "none",
                    }}
                  >
                    <Accordion
                      sx={{
                        border: "1px solid rgba(0, 0, 0, .125)",
                        boxShadow: "none !important",
                        "&:not(:last-child)": {
                          borderBottom: 0,
                        },
                        "&:before": {
                          display: "none",
                        },
                        "& $expanded": {
                          margin: "auto",
                        },
                      }}
                      expanded={expanded.includes(i)}
                      onChange={accordianHandler(i)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          "& div": {
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          },
                          fontSize: "1.1rem",
                        }}
                      >
                        <Box sx={{ display: "flex", flex: 1 }}>
                          {row?.logLevel === "INFO" ? (
                            <InfoIcon style={{ color: "#17c191" }} />
                          ) : (
                            <ErrorIcon color="error" />
                          )}
                          <Box
                            component="span"
                            sx={{
                              fontWeight: 600,
                              marginLeft: "5px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: {
                                md: "500px",
                                lg: "700px",
                                xl: "1200px",
                              },
                            }}
                          >
                            {row?.action}
                          </Box>
                        </Box>
                        <div>
                          {moment(new Date(row?.createdOn)).format(
                            "MM/DD/YYYY HH:mm:ss"
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <Grid container columns={{ md: 12, sm: 12 }}>
                          <Grid item md={6} sm={12} sx={{
                            display: 'flex',
                            gridTemplateColumns: "auto 1fr",
                            width: "100%",
                            gap: "1px",
                            fontSize: "1rem",
                            border: "1px solid rgba(0, 0, 0, .125)",
                          }}>
                            <Box
                              sx={{
                                textTransform: "uppercase",
                                fontWeight: 600,
                                backgroundColor: "#fff",
                                padding: "5px",
                                borderRight: "1px solid rgba(0, 0, 0, .125)",
                                paddingRight: "1.3vw"
                              }}
                            >
                              device
                            </Box>
                            <Box
                              sx={{
                                backgroundColor: "#fff",
                                padding: "5px",
                                overflow: "auto",
                              }}
                            >
                              {row.device}
                            </Box>
                          </Grid>
                          <Grid item md={6} sm={12} sx={{
                            display: 'flex',
                            gridTemplateColumns: "auto 1fr",
                            width: "100%",
                            gap: "1px",
                            fontSize: "1rem",
                            border: "1px solid rgba(0, 0, 0, .125)",
                          }}>
                            <Box
                              sx={{
                                textTransform: "uppercase",
                                fontWeight: 600,
                                backgroundColor: "#fff",
                                padding: "5px",
                                borderRight: "1px solid rgba(0, 0, 0, .125)",
                                paddingRight: "1vw"
                              }}
                            >
                              Created By
                            </Box>
                            <Box
                              sx={{
                                backgroundColor: "#fff",
                                padding: "5px",
                                overflow: "auto",
                              }}
                            >
                              {row.createdBy}
                            </Box>
                          </Grid>
                        </Grid> */}
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "auto 1fr",
                            width: "100%",
                            gap: "1px",
                            fontSize: "1rem",
                            backgroundColor: "rgba(0, 0, 0, .125)",
                            border: "1px solid rgba(0, 0, 0, .125)",
                          }}
                        >
                          {Object.entries(row).map(([key, value]) => {
                            return (
                              <React.Fragment key={key + " " + value}>
                                {!excludeKeys.includes(key) && value !== "" && value !== null && (
                                  <React.Fragment key={value?.timestamp}>
                                    <>
                                      <Box
                                        sx={{
                                          textTransform: "uppercase",
                                          fontWeight: 600,
                                          backgroundColor: "#fff",
                                          padding: "5px",
                                        }}
                                      >
                                        {key}
                                      </Box>
                                      <Box
                                        sx={{
                                          backgroundColor: "#fff",
                                          padding: "5px",
                                          overflow: "auto",
                                        }}
                                      >
                                        {typeof value === "object" &&
                                          value !== null ? (
                                          <Box
                                            component="pre"
                                            sx={{
                                              fontSize: "1rem",
                                              wordWrap: "break-word",
                                              whiteSpace: "pre-wrap",
                                              maxHeight: "400px",
                                            }}
                                          >
                                            {JSON.stringify(value, null, 2)}
                                          </Box>
                                        ) : // ) : typeof value === "string" &&
                                          //   value !== "" ? (
                                          //   value
                                          // ) : typeof value === "boolean" &&
                                          //   value !== "" ? (
                                          //   value === true ? (
                                          //     "true"
                                          //   ) : (
                                          //     "false"
                                          //   )
                                          key === "textDesc" ? (
                                            value?.concat(
                                              moment(row["createdOn"]).format(
                                                "MM/DD/YY, HH:mm:ss"
                                              )
                                            )
                                          ) : key !== "device" ? (
                                            value && value.toString()
                                          ) : (<span style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                            <span>{value && value.toString()}
                                            </span> {row.createdBy}</span>)
                                        }
                                      </Box>
                                    </>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "1.1rem",
                    textAlign: "center",
                    height: "100px",
                    padding: "6px",
                  }}
                >
                  <Box
                    sx={{
                      border: "2px solid #444444",
                      borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                      height: "250px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 500,
                      color: "#444444",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                        flexDirection: "column",
                      }}
                    >
                      <Box>
                        <img
                          src={noResult}
                          alt="noResult"
                          width="50"
                          height="50"
                        />
                      </Box>
                      <Box
                        sx={{
                          fontSize: "20px",
                          fontWeight: 600,
                          lineHeight: "12px",
                        }}
                      >
                        No Audit Logs Available
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ width: "100%" }}>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            15,
            25,
            { label: "All", value: dataCount },
          ]}
          component="div"
          count={dataCount}
          page={offset / parseInt(dataPerPage)}
          style={{
            borderTop: "1px solid #e0e0e0",
            borderBottom: "1px solid #e0e0e0",
          }}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={(event, newPage) => {
            handleSetPage(newPage);
          }}
          rowsPerPage={parseInt(dataPerPage)}
          onRowsPerPageChange={(event) => {
            const { value } = event.target;
            handleNoOfRowsPerPage(value);
          }}
          ActionsComponent={TablePaginationActions}
        />
      </div>
    </>
  );
};

export default AuditLogsTable;
