export const getPermissionsQuery = `query MyQuery ($code:String,$offset:Int,$limit:Int,$searchValue:String,$searchBy:[String],$sortField:String,$sortType:String){
  getPermissions(code:$code,offset:$offset, limit:$limit searchValue:$searchValue,searchBy:$searchBy, sortField:$sortField,sortType:$sortType) {
    count
    Permissions {
      code
      name
      type
      description
      createdOn
      updatedBy
      updatedOn
    }
  }
}`;

export const createPermissionQuery = `mutation MyMutation ($PermissionInput:PermissionInput!) {
  addPermission(PermissionInput:$PermissionInput) {
    code
      name
      type
      description
      createdOn
      updatedBy
      updatedOn
  }
}`;

export const updatePermissionQuery = `mutation MyMutation ($code:String!,$PermissionUpdateInput:PermissionUpdateInput!) {
  updatePermission(code:$code, PermissionUpdateInput:$PermissionUpdateInput) {
      code
      name
      type
      description
      createdOn
      updatedBy
      updatedOn
  }
}`;


