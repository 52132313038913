import { Dialog, DialogActions, DialogContent, useTheme } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import DialogCloseTitle from "../../../Helpers/Dialog/DialogTitle";
import BootstrapButton from "../../../Helpers/UI/Button/BootstrapButton";
import { generalSlice } from "../../../_redux/general/generalSlice";
import { rejectCWIQuoteAmountCrud } from "../../../_redux/TransportOrders/TransportOrdersCrud";

const schema = yup.object({
  comment: yup.string().trim(),
});

const RejectCWIQuoteAmountModal = ({
  setShowRejectModal,
  showRejectModal,
  getData,
  inviteCode,
}) => {
  const { actions: generalActions } = generalSlice;

  const dispatch = useDispatch();
  const theme = useTheme();

  const init = {
    comment: "",
  };

  const handleClose = () => {
    setShowRejectModal(false);
  };

  const rejectCWIQuoteAmountData = (data) => {
    return rejectCWIQuoteAmountCrud(data)
      .then((res) => {
        if (res?.data?.rejectCWIQuoteAmount?.status === false) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't Decline CWI Quote Amount",
              type: "danger",
            })
          );
        } else {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Success",
              message: "CWI Quote Amount Declined Successfully",
              type: "success",
            })
          );
          setShowRejectModal(false);
          getData();
        }
      })
      .catch((error) => {
        console.error(error);
        if (error) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't Decline CWI Quote Amount",
              type: "danger",
            })
          );
        }
      });
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={init}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        rejectCWIQuoteAmountData({
          inviteCode: inviteCode,
          comment: values.comment,
        })
          .then(() => {
            handleClose(resetForm);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        isSubmitting,
        resetForm,
        setFieldValue,
        isValid,
        errors,
        touched,
      }) => (
        <Dialog
          open={showRejectModal}
          scroll={"paper"}
          maxWidth="sm"
          fullWidth={true}
        >
          <Form onSubmit={handleSubmit} noValidate>
            <DialogCloseTitle
              onClose={() => {
                handleClose(resetForm);
              }}
              isCloseButtonDisabled={isSubmitting}
            >
              <div
                style={{
                  fontSize: "1.5rem",
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              >
                Decline CWI Quote Amount
              </div>
            </DialogCloseTitle>
            <DialogContent dividers>
              <Form.Row>
                <Col sm={12} md={12}>
                  <Form.Group className="required">
                    <Form.Label style={{ fontWeight: 600 }}>Reason</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      type="text"
                      name="comment"
                      value={values.comment || ""}
                      onChange={(e) => {
                        let value = e.target.value.trimStart();
                        setFieldValue("comment", value);
                      }}
                      disabled={isSubmitting}
                      isInvalid={errors.comment}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.comment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
            </DialogContent>
            <DialogActions>
              <Button
                variant="secondary"
                onClick={() => {
                  handleClose(resetForm);
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>

              <BootstrapButton
                variant="success"
                type="submit"
                label="Update"
                labelWhenSubmitting="Updating"
                isSubmitting={isSubmitting}
                onClick={handleSubmit}
                disabled={values.comment === ""}
              />
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default RejectCWIQuoteAmountModal;
