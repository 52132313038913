import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   allControlIdData: [],
   selectedControlID: null,
   isLoading: true,
   searchValue: "",
   searchBy: [],
   page: 0,
   dataCount: 0,
   offset: 0,
   dataPerPage: 15,
   sortField: "createdOn",
   sortType: "DESC",
   actionTrigger: false,
   startDate: null,
   endDate: null,
   locationFilterData: [],
   organizationFilterData: [],
   accountFilterData: [],
   productFilterData: [],
   controlIdFilterData: [],
   eventFilterData: [],
};

export const ControlIdActionSlice = createSlice({
   name: "controlIdAction",
   initialState: initialState,
   reducers: {
      setControlIdActionData: (state, action) => {
         state.allControlIdData = action.payload;
      },
      controlIDFetched: (state, action) => {
         state.selectedControlID = action.payload;
      },
      removeSelectedControlID: (state) => {
         state.selectedControlID = null;
      },
      setSearchValue: (state, action) => {
         state.searchValue = { ...state.searchValue, ...action.payload };
      },
      setSearchBy: (state, action) => {
         state.searchBy = action.payload;
      },
      setLoading: (state, action) => {
         state.isLoading = action.payload;
      },
      setSortField: (state, action) => {
         state.sortField = action.payload;
      },
      setSortType: (state, action) => {
         state.sortType = action.payload;
      },
      setActionTrigger: (state, action) => {
         state.actionTrigger = action.payload;
      },
      setTimeRange: (state, action) => {
         state.startDate = action.payload.startDate;
         state.endDate = action.payload.endDate;
      },
      setLocationFilterData: (state, action) => {
         state.locationFilterData = action.payload;
      },
      setOrganizationFilterData: (state, action) => {
         state.organizationFilterData = action.payload;
      },
      setAccountFilterData: (state, action) => {
         state.accountFilterData = action.payload;
      },
      setProductFilterData: (state, action) => {
         state.productFilterData = action.payload;
      },
      setControlIdFilterData: (state, action) => {
         state.controlIdFilterData = action.payload;
      },
      setEventFilterData: (state, action) => {
         state.eventFilterData = action.payload;
      },
      setPageConfigData: (state, action) => {
         switch (action.payload.type) {
            case "SET_PAGE":
               state.page = action.payload.data;
               break;
            case "SET_DATA_COUNT":
               state.dataCount = action.payload.data;
               break;
            case "SET_IS_LOADING":
               state.isLoading = action.payload.data;
               break;
            case "SET_DATA_PER_PAGE":
               state.dataPerPage = action.payload.data;
               break;
            case "SET_OFFSET":
               state.offset = action.payload.data;
               break;
            default:
               break;
         }
      },
   },
});
