import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import CustomDraggableDialog from "../../../Helpers/CustomDraggableDialog/CustomDraggableDialog";
import DialogCloseTitle from "../../../Helpers/Dialog/DialogTitle";
import BootstrapButton from "../../../Helpers/UI/Button/BootstrapButton";
import { generalSlice } from "../../../_redux/general/generalSlice";
import { acceptTenderQuote } from "../../../_redux/TransportOrders/TransportOrdersCrud";

const init = {
  quoteAmount: "",
  statusComment: "",
  acknowledgeStatus: false,
  acknowledgeName: "",
};

const schema = yup.object({
  quoteAmount: yup
    .number()
    .min(1, "Proposed Quote must be greater than 0")
    .required("Proposed Quote is required"),
  statusComment: yup.string().trim(),
  acknowledgeStatus: yup.boolean().required(),
  acknowledgeName: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z\s]*$/, "Only text characters are allowed"),
});

const TenderQuoteModal = ({ setShowModal, showModal, inviteCode, getData }) => {
  const { actions: generalActions } = generalSlice;
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClose = () => {
    setShowModal(false);
  };

  const acceptTenderData = (data) => {
    return acceptTenderQuote(data)
      .then((res) => {
        if (res?.data?.acceptTenderQuote?.status === false) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't accept Tender Quote",
              type: "danger",
            })
          );
        } else {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Success",
              message: "Tender Quote Submitted Successfully",
              type: "success",
            })
          );
          getData();
        }
      })
      .catch((error) => {
        console.error(error);
        if (error) {
          dispatch(
            generalActions.pushNewAlert({
              show: true,
              heading: "Error",
              message: "Can't accept Tender Quote",
              type: "danger",
            })
          );
        }
      });
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      validationSchema={schema}
      initialValues={init}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        acceptTenderData({
          inviteCode: inviteCode,
          quoteAmount: parseFloat(values.quoteAmount),
          statusComment: values.statusComment,
          acknowledgeStatus: values.acknowledgeStatus,
          acknowledgeName: values.acknowledgeName,
        })
          .then(() => {
            handleClose();
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        isSubmitting,
        resetForm,
        setFieldValue,
        isValid,
        errors,
        touched,
      }) => (
        <CustomDraggableDialog
          show={showModal}
          onHide={handleClose}
          draggableChildren={
            <Dialog
              open={showModal}
              scroll={"paper"}
              maxWidth="sm"
              fullWidth={true}
              hideBackdrop={true}
            >
              <Form onSubmit={handleSubmit} noValidate>
                <DialogCloseTitle
                  onClose={handleClose}
                  isCloseButtonDisabled={isSubmitting}
                >
                  <div
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Submit Quote
                  </div>
                </DialogCloseTitle>
                <DialogContent dividers>
                  <Form.Row>
                    <Col sm={12} md={6}>
                      <Form.Group className="required">
                        <Form.Label style={{ fontWeight: 600 }}>
                          Proposed Quote
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <i className={"fas fa-regular fa-dollar-sign"}></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                          <Form.Control
                            type="number"
                            name="quoteAmount"
                            value={values.quoteAmount ? values.quoteAmount : ""}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.quoteAmount && errors.quoteAmount}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.quoteAmount}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col sm={12} md={12}>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 600 }}>
                          Quote Comments/Instructions
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          type="text"
                          name="statusComment"
                          value={values.statusComment}
                          disabled={isSubmitting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.statusComment && errors.statusComment}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.statusComment}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={2} sm={1} md={1} style={{ marginTop: "9px" }}>
                      <Checkbox
                        name="acknowledgeStatus"
                        checked={values.acknowledgeStatus}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        style={{
                          padding: 0,
                          transform: "scale(1.5)",
                        }}
                      />
                    </Col>
                    <Col xs={10} sm={11} md={11}>
                      <Form.Group>
                        <Form.Control
                          placeholder="Name"
                          type="text"
                          name="acknowledgeName"
                          value={values.acknowledgeName}
                          disabled={isSubmitting}
                          onChange={(e) => {
                            if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                              setFieldValue(
                                "acknowledgeName",
                                e.target.value.trimStart()
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.acknowledgeName && errors.acknowledgeName
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.acknowledgeName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col sm={12} md={12}>
                      <Form.Label
                        style={{
                          fontSize: "12px",
                          fontWeight: 600,
                        }}
                        name="acknowledgeStatus"
                      >
                        I acknowledge the delivery times, quantities weights, values
                        and requirements from this request for proposal.
                      </Form.Label>
                    </Col>
                  </Form.Row>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="secondary"
                    onClick={() => handleClose()}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>

                  <BootstrapButton
                    variant="success"
                    type="submit"
                    label="Submit Quote"
                    labelWhenSubmitting="Submitting"
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit}
                    disabled={
                      isSubmitting ||
                      values.acknowledgeName === "" ||
                      values.acknowledgeStatus === false ||
                      values.quoteAmount === 0
                    }
                  />
                </DialogActions>
              </Form>
            </Dialog>
          }
        />
      )}
    </Formik>
  );
};

export default TenderQuoteModal;
