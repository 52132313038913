import { Checkbox } from "@mui/material";

import React from "react";
import { InputGroup } from "react-bootstrap";
import { getTimeFromMins } from "../../../../Utils/utils";

const daysConfig = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const LocationBusiDays = ({
  businessArr,
  isDisabled = false,
  handleDays,
  pertinentDay,
}) => {
  return (
    <>
      {businessArr &&
        businessArr.length > 0 &&
        businessArr.map((day, i) => (
          <InputGroup
            key={i}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              border: daysConfig[i] === pertinentDay && "2px solid #90EE90",
            }}
          >
            <Checkbox
              value={day.enabled}
              checked={day.enabled}
              name="enabled"
              disabled={isDisabled}
              onChange={(e) => handleDays(e.target.checked, i, "enabled")}
              style={{ flex: "1" }}
            />
            <span style={{ flex: "3", textAlign: "center" }}>
              {daysConfig[i]}
            </span>
            <div
              style={{
                display: "flex",
                flex: "50",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "1", textAlign: "end" }}>
                {getTimeFromMins(day.min)}
              </div>

              <div style={{ flex: "1" }}>{getTimeFromMins(day.max)}</div>
            </div>
          </InputGroup>
        ))}
    </>
  );
};

export default LocationBusiDays;
