import objectPath from "object-path";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Brand } from "../brand/Brand";
import { AsideMenu } from "./aside-menu/AsideMenu";
import mintBg from "../../../../assets/aside-bg.png";

export function Aside() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      disableScroll:
        objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
        false,
      asideClassesFromConfig: uiService.getClasses("aside", true),
      disableAsideSelfDisplay:
        objectPath.get(uiService.config, "aside.self.display") === false,
    };
  }, [uiService]);

  const getHeaderLogo = () => {
    let result = "coinwrap_logo.png";
    if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
      result = "coinwrap_logo.png";
    }
    return toAbsoluteUrl(`/media/logos/${result}`);
  };

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}
      >
        <Brand />

        {/* begin::Aside Menu */}
        <div
          id="kt_aside_menu_wrapper"
          className="aside-menu-wrapper flex-column-fluid"
          style={{
            backgroundImage: `url(${mintBg})`,
            backgroundPosition: "left",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            flex: 1,
            minHeight: "0px",
            display: "flex",
            flexDirection: "column",
            // backgroundColor: "#787878",
          }}
        >
          {layoutProps.disableAsideSelfDisplay && (
            <>
              {/* begin::Header Logo */}
              <div className="header-logo">
                <Link to="">
                  <img alt="coinwraplogo" src={getHeaderLogo()} />
                </Link>
              </div>
              {/* end::Header Logo */}
            </>
          )}
          <AsideMenu disableScroll={layoutProps.disableScroll} />
        </div>
        {/* end::Aside Menu */}
      </div>
      {/* end::Aside */}
    </>
  );
}
