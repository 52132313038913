import React from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapButton from "../UI/Button/BootstrapButton";

const ConfirmDialog = ({
  title,
  customTitle,
  cancelHandler,
  cancelLable,
  actionHandler,
  buttonLabel,
  buttonLabelWhenSubmitting,
  customMessage,
  show,
  extraNote,
  btnVariant,
  headerColor,
  setIsSubmitting,
  isSubmitting,
}) => {
  const handleActions = () => {
    const prom = actionHandler();

    if (prom) {
      setIsSubmitting(true);
      prom.finally(() => {
        cancelHandler();
        setIsSubmitting(false);
      });
    }
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
      style={{ zIndex: 9999 }}
    >
      <Modal.Header closeButton style={{ backgroundColor: headerColor }}>
        <Modal.Title
          id="example-modal-sizes-title-lg"
          style={{ color: "#fff" }}
        >
          {customTitle ? customTitle : title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span style={{ fontSize: "1.2rem" }}>
          {customMessage}

          {extraNote}
        </span>
      </Modal.Body>
      <Modal.Footer style={{ padding: "10px" }}>
        <Button
          variant="secondary"
          onClick={cancelHandler}
          style={{ marginLeft: "10px" }}
          disabled={isSubmitting}
        >
          {cancelLable ? cancelLable : "Cancel"}
        </Button>

        <BootstrapButton
          variant={btnVariant}
          type="submit"
          label={buttonLabel}
          labelWhenSubmitting={buttonLabelWhenSubmitting}
          isSubmitting={isSubmitting}
          onClick={handleActions}
          style={{ marginLeft: "10px" }}
          disabled={isSubmitting}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
