import { lazy } from "react";
import { auditLogsRoute, consolidateRoutes, controlIDActionRoute, InventoryRoute, ledgerRoute, productionRoute, shipmentFilesRoute, transportOrdersRoute } from "./modules/coinWrap/Helpers/Common/CommonComponent";
import AuditLogsPage from "./pages/AuditLogsPage";
import { DashboardPage } from "./pages/DashboardPage";

const TransportOrders = lazy(() => import("./pages/TransportOrdersPage"));
const Models = lazy(() => import("./pages/ModelsPage"));
const Settings = lazy(() => import("./pages/SettingsPage"));
const ShipmentFiles = lazy(() => import("./pages/ShipmentFilesPage"));
const Documents = lazy(() => import("./pages/DocumentsPage"));
const Inventory = lazy(() => import("./pages/InventoryPage"));
const Ledger = lazy(() => import("./pages/LedgerPage"));
const Orders = lazy(() => import("./pages/OrdersPage"));
const Consolidate = lazy(() => import("./pages/CosolidatePage"));
const Production = lazy(() => import("./pages/ProductionPage"));
const RolesAndPermissions = lazy(() =>
  import("./pages/RolesAndPermissionsPage")
);
const ControlIDAction = lazy(() => import("./pages/ControlIDActionsPage"));


export const customRoutes = () => [
  {
    path: "/dashboard",
    Component: DashboardPage,
    isActive: true,
  },
  {
    path: transportOrdersRoute,
    Component: TransportOrders,
    isActive: true,
  },
  {
    path: "/models",
    Component: Models,
    isActive: true,
  },
  {
    path: auditLogsRoute,
    Component: AuditLogsPage,
    isActive: true,
  },
  {
    path: "/settings",
    Component: Settings,
    isActive: true,
  },
  {
    path: shipmentFilesRoute,
    Component: ShipmentFiles,
    isActive: true,
  },
  {
    path: "/documents",
    Component: Documents,
    isActive: true,
  },
  {
    path: InventoryRoute,
    Component: Inventory,
    isActive: true,
  },
  {
    path: ledgerRoute,
    Component: Ledger,
    isActive: true,
  },
  {
    path: "/orders",
    Component: Orders,
    isActive: true,
  },
  {
    path: productionRoute,
    Component: Production,
    isActive: true,
  },
  {
    path: consolidateRoutes,
    Component: Consolidate,
    isActive: true,
  },
  {
    path: "/roles_permissions",
    Component: RolesAndPermissions,
    isActive: true,
  },
  {
    path: controlIDActionRoute,
    Component: ControlIDAction,
    isActive: true,
  },
];
